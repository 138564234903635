import { createSelector, createSlice } from "@reduxjs/toolkit";
import { defaultPatientSummary } from "../../data";
import {
  getFormattedMaxVafPatientSummaryItem,
  getFormattedMissingGenesPatientSummaryItem,
  getFormattedMutatedGenesPatientSummaryItem,
  getFormattedPatientSummaryItem,
  getFormattedRadioPatientSummaryItem,
  getFormattedTp53lohPatientSummaryItem,
} from "../../utils";

const initialState = defaultPatientSummary;

const formatterMap = {
  TP53loh: getFormattedTp53lohPatientSummaryItem,
  TP53mut: getFormattedRadioPatientSummaryItem,
  TP53maxvaf: getFormattedMaxVafPatientSummaryItem,
  missingGenes: getFormattedMissingGenesPatientSummaryItem,
  mutatedGenes: getFormattedMutatedGenesPatientSummaryItem,
  CYTO_IPSSR: getFormattedRadioPatientSummaryItem,
  default: getFormattedPatientSummaryItem,
};

export const patientSummarySlice = createSlice({
  name: "patientSummaryState",
  initialState,
  reducers: {
    patientSummaryUpdate: (state, action) => {
      const { formState, units } = action.payload;
      Object.keys(state).map((key) => {
        const formatter = formatterMap[key] || formatterMap.default;
        state[key] = {
          ...state[key],
          ...formatter({ formState, units, key }),
        };
      });
    },
    patientSummaryReset: () => initialState,
  },
});

export const { patientSummaryReset, patientSummaryUpdate } =
  patientSummarySlice.actions;

export const selectPatientSummaryItem = createSelector(
  [
    // Usual first input - extract value from `state`
    (state) => state.patientSummary,
    // Take the second arg, `category`, and forward to the output selector
    (_, category) => category,
  ],
  // Output selector gets (`items, category)` as args
  (patientSummary, category) => {
    return patientSummary[category];
  }
);
