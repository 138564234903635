import Analytics from "analytics";
import segmentPlugin from "@analytics/segment";

const analytics = Analytics({
  plugins: [
    segmentPlugin({
      writeKey: "fDEoJCXTyHIGqTHKPJrVOUZzg6IWDKMy",
    }),
  ],
});

export const analyticsClient = {
  track: analytics.track,
  screen: (screen) => analytics.page({ name: screen }),
};
