import { processFontFamily } from "expo-font";
import { G, Line, Text } from "react-native-svg";
import { Y_TICKS } from "../../constants";

export const LinearYAxis = ({ yAxis, graphWidth, GRAPH_HEIGHT }) => {
  return (
    <>
      <Line stroke="grey" strokeWidth={1} y1={0} y2={GRAPH_HEIGHT} />
      <Line
        stroke="grey"
        strokeWidth={1}
        x1={graphWidth}
        x2={graphWidth}
        y1={0}
        y2={GRAPH_HEIGHT}
      />
      {Y_TICKS.map((tick) => (
        <G key={tick} y={yAxis(tick)}>
          <Line stroke="grey" strokeWidth={1} x1={-6} x2={0} />
          <Text
            fill="grey"
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily={processFontFamily("Barlow_700Bold")}
            fontSize={14}
            y="0"
            x="-18"
          >
            {tick}
          </Text>
        </G>
      ))}
      <Text
        fill="black"
        dominantBaseline="middle"
        textAnchor="middle"
        fontFamily={processFontFamily("Barlow_700Bold")}
        fontSize={16}
        transform="rotate(-90)"
        x={-GRAPH_HEIGHT / 2}
        y={-40}
      >
        Density
      </Text>
    </>
  );
};
