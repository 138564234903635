import { Box } from "native-base";

export const ViewCalculatorMainContent = ({ children }) => (
  <Box
    px={{ base: 3, lg: 6 }}
    py={{ base: 5, lg: 6 }}
    w="full"
    maxW="1500"
    mx="auto"
  >
    {children}
  </Box>
);
