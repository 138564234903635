export const references = [
  { name: "Elsa Bernard, Ph.D.", number: "1" },
  { name: "Heinz Tuechler, Peter L. Greenberg, M.D.", number: "2" },
  { name: "Robert P. Hasserjian, M.D.", number: "3" },
  { name: "Juan E. Arango Ossa, M.S.", number: "1" },
  { name: "Yasuhito Nannya, M.D., Ph.D.", number: "4,5" },
  { name: "Sean M. Devlin, Ph.D.", number: "1" },
  { name: "Maria Creignou, M.D.", number: "6" },
  { name: "Philippe Pinel, M.S.", number: "1" },
  { name: "Lily Monnier, M.S.", number: "1" },
  { name: "Gunes Gundem, Ph.D.", number: "1" },
  { name: "Juan S. Medina-Martinez, M.S.", number: "1" },
  { name: "Dylan Domenico, B.S.", number: "1" },
  { name: "Martin Jädersten, M.D., Ph.D.", number: "6" },
  { name: "Ulrich Germing, M.D.", number: "7" },
  { name: "Guillermo Sanz, M.D., Ph.D.", number: "8,9,10" },
  { name: "Arjan A. van de Loosdrecht, M.D., Ph.D.", number: "11" },
  { name: "Olivier Kosmider, M.D., Ph.D.", number: "12" },
  { name: "Matilde Y. Follo, Ph.D.", number: "13" },
  { name: "Felicitas Thol, M.D.", number: "14" },
  { name: "Lurdes Zamora, Ph.D.", number: "15" },
  { name: "Ronald F. Pinheiro, Ph.D.", number: "16" },
  { name: "Andrea Pellagatti, Ph.D.", number: "17" },
  { name: "Harold K. Elias, M.D.", number: "18" },
  { name: "Detlef Haase, M.D., Ph.D.", number: "19" },
  { name: "Christina Ganster, Ph.D.", number: "19" },
  { name: "Lionel Ades, M.D., Ph.D.", number: "20" },
  { name: "Magnus Tobiasson, M.D., Ph.D.", number: "6" },
  { name: "Laura Palomo, Ph.D.", number: "21" },
  { name: "Matteo Giovanni Della Porta, M.D.", number: "22" },
  { name: "Akifumi Takaori-Kondo, M.D., Ph.D.", number: "23" },
  { name: "Takayuki Ishikawa, M.D., Ph.D.", number: "24" },
  { name: "Shigeru Chiba, M.D., Ph.D.", number: "25" },
  { name: "Senji Kasahara, M.D., Ph.D.", number: "26" },
  { name: "Yasushi Miyazaki, M.D., Ph.D.", number: "27" },
  { name: "Agnes Viale, Ph.D.", number: "28" },
  { name: "Kety Huberman, B.S.", number: "28" },
  { name: "Pierre Fenaux, M.D., Ph.D.", number: "20" },
  { name: "Monika Belickova, Ph.D.", number: "29" },
  { name: "Michael R. Savona, M.D.", number: "30" },
  { name: "Virginia M. Klimek, M.D.", number: "18" },
  { name: "Fabio P. S. Santos, M.D., Ph.D.", number: "31" },
  { name: "Jacqueline Boultwood, Ph.D.", number: "17" },
  { name: "Ioannis Kotsianidis, M.D., Ph.D.", number: "32" },
  { name: "Valeria Santini, M.D.", number: "33" },
  { name: "Francesc Solé, Ph.D.", number: "21" },
  { name: "Uwe Platzbecker, M.D.", number: "34" },
  { name: "Michael Heuser, M.D.", number: "14" },
  { name: "Peter Valent, M.D.", number: "35,36" },
  { name: "Kazuma Ohyashiki, M.D., Ph.D.", number: "37" },
  { name: "Carlo Finelli, M.D.", number: "38" },
  { name: "Maria Teresa Voso, M.D.", number: "39" },
  { name: "Lee-Yung Shih, M.S.", number: "40" },
  { name: "Michaela Fontenay, M.D., Ph.D.", number: "12" },
  { name: "Joop H. Jansen, Ph.D.", number: "41" },
  { name: "José Cervera, M.D., Ph.D.", number: "42" },
  { name: "Norbert Gattermann, M.D.", number: "7" },
  { name: "Benjamin L. Ebert, M.D., Ph.D.", number: "43" },
  { name: "Rafael Bejar, M.D., Ph.D.", number: "44" },
  { name: "Luca Malcovati, M.D.", number: "45" },
  { name: "Mario Cazzola, M.D.", number: "45" },
  { name: "Seishi Ogawa, M.D., Ph.D.", number: "4,46,47" },
  { name: "Eva Hellström-Lindberg, M.D., Ph.D.", number: "6" },
  { name: "and Elli Papaemmanuil, Ph.D.", number: "1" },
];
