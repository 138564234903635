import { convertCommaSeparatedNumberToDecimals } from "../formUtils/formUtils";

export const isRequired =
  (message = "This field is required") =>
  ({ value }) => {
    if (value || value === 0) return null;
    return { message, type: "required" };
  };

export const isMin =
  (minVal, message = `Can't be over ${minVal}`) =>
  ({ value }) => {
    if (!value) return null;
    const n = convertCommaSeparatedNumberToDecimals(value)
    if (n < minVal) return { message, type: "min", minVal };
  };

export const isMax =
  (maxVal, message = `Can't be over ${maxVal}`) =>
  ({ value }) => {
    if (!value) return null;
    const n = convertCommaSeparatedNumberToDecimals(value)
    if (n > maxVal) return { message, type: "max", maxVal };
  };

export const isTp53maxvafCustomRequired =
  (message = "Required because of TP53 mutation count") =>
  ({ value, TP53mut }) => {
    if (TP53mut?.value < 1 || !TP53mut?.value) return null;
    if (value || value === 0) return null;
    return { message, type: "custom" };
  };

