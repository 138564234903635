import { processFontFamily } from "expo-font";
import { Line, G, Text } from "react-native-svg";
import {  SCORE_TICKS } from "../../constants";

export const LinearXAxis = ({ xAxis, graphWidth, GRAPH_HEIGHT }) => {
  const color = "grey";
  return (
    <>
      <Line
        stroke={color}
        strokeWidth={1}
        x1={0}
        x2={graphWidth}
        y1={GRAPH_HEIGHT}
        y2={GRAPH_HEIGHT}
      />
      <Line
        stroke={color}
        strokeWidth={1}
        x1={0}
        x2={graphWidth}
        y1={0}
        y2={0}
      />
      {SCORE_TICKS.map((tick, i) => {
        const x = xAxis(tick);
        return (
          <G key={i} x={x} y={0}>
            <Line
              stroke={color}
              strokeWidth={1}
              y1={GRAPH_HEIGHT}
              y2={GRAPH_HEIGHT + 6}
            />
            <Text
              fill={color}
              dominantBaseline="middle"
              textAnchor="middle"
              fontFamily={processFontFamily("Barlow_700Bold")}
              fontSize={14}
              y={GRAPH_HEIGHT + 18}
            >
              {tick}
            </Text>
          </G>
        );
      })}
       {SCORE_TICKS.map((tick, i) => {
        const x = xAxis(tick);
        return (
          <G key={i} x={x} y={0}>
            <Line
              stroke={color}
              strokeWidth={1}
              y1={0}
              y2={-6}
            />
            <Text
              fill={color}
              dominantBaseline="middle"
              textAnchor="middle"
              fontFamily={processFontFamily("Barlow_700Bold")}
              fontSize={14}
              y={-18}
            >
              {2 ** tick}
            </Text>
          </G>
        );
      })}
      <Text
        fill="black"
        dominantBaseline="middle"
        textAnchor="middle"
        fontFamily={processFontFamily("Barlow_700Bold")}
        fontSize={14}
        y={GRAPH_HEIGHT + 40}
        x={graphWidth / 2}
      >
        IPSS-M Score
      </Text>
      <G x={graphWidth / 2} y={-60}>
        <Text
          dominantBaseline="middle"
          textAnchor="middle"
          fontFamily={processFontFamily("Barlow_700Bold")}
          fill="black"
          fontSize={14}
        >
          Hazard Ratio
        </Text>
        <Text
          dominantBaseline="middle"
          textAnchor="middle"
          fontFamily={processFontFamily("Barlow_700Bold")}
          fill="gray"
          fontSize={12}
          y='16'
        >
          (from average patient)
        </Text>
      </G>
    </>
  );
};
