import { Stack } from "native-base";
import { usePatientSummary } from "../../../../../../hooks";
import { CardValueBox, ValueDisplay } from "../../../../../../ui";

export const PatientSummaryOverview = () => {
  const { patientSummary } = usePatientSummary();
  const {
    BM_BLAST,
    PLT,
    HB,
    ANC,
    AGE,
    CYTO_IPSSR,
    TP53mut,
    TP53maxvaf,
    TP53loh,
    mutatedGenes,
    missingGenes,
  } = patientSummary;

  return (
    <Stack flexWrap="wrap" flexDir="row" mt="-1px" ml="-1px">
      <CardValueBox flexBasis={{ base: "50%", md: "33.3%", xl: `${100 / 5}%` }}>
        <ValueDisplay {...BM_BLAST} />
      </CardValueBox>

      <CardValueBox flexBasis={{ base: "50%", md: "33.3%", xl: `${100 / 5}%` }}>
        <ValueDisplay {...HB} />
      </CardValueBox>

      <CardValueBox flexBasis={{ base: "50%", md: "33.3%", xl: `${100 / 5}%` }}>
        <ValueDisplay {...PLT} />
      </CardValueBox>

      <CardValueBox flexBasis={{ base: "50%", md: "33.3%", xl: `${100 / 5}%` }}>
        <ValueDisplay {...ANC} />
      </CardValueBox>

      <CardValueBox flexBasis={{ base: "50%", md: "33.3%", xl: `${100 / 5}%` }}>
        <ValueDisplay {...AGE} />
      </CardValueBox>

      <CardValueBox flexBasis={{ base: "50%", md: "33.3%", xl: `${100 / 5}%` }}>
        <ValueDisplay {...CYTO_IPSSR} />
      </CardValueBox>

      <CardValueBox
        flexBasis={{ base: "100%", md: "33.3%", xl: `${100 / 5}%` }}
      >
        <ValueDisplay {...TP53mut} />
      </CardValueBox>

      <CardValueBox
        flexBasis={{ base: "100%", md: "33.3%", xl: `${100 / 5}%` }}
      >
        <ValueDisplay {...TP53maxvaf} />
      </CardValueBox>

      <CardValueBox
        flexBasis={{ base: "100%", md: "33.3%", xl: `${100 / 5}%` }}
      >
        <ValueDisplay {...TP53loh} />
      </CardValueBox>

      <CardValueBox
        flexBasis={{ base: "100%", md: "33.3%", xl: `${100 / 2}%` }}
      >
        <ValueDisplay {...mutatedGenes} />
      </CardValueBox>

      <CardValueBox
        flexBasis={{ base: "100%", md: "33.3%", xl: `${100 / 2}%` }}
      >
        <ValueDisplay {...missingGenes} />
      </CardValueBox>
    </Stack>
  );
};
