import { View } from "native-base";

export const ViewCalculatorWrapper = ({ children }) => (
  <View
    display="flex"
    // flex="1"
    h='full'
    bg="graysecondary.200"
    flexDir={{ base: "column", lg: "row" }}
  >
    {children}
  </View>
);
