import {
  Collapse,
  CollapseBody,
  CollapseHeader,
} from "accordion-collapse-react-native";
import { useEffect, useState } from "react";
import { AccordionHeader } from "../AccordionHeader/AccordionHeader";

export const Accordion = ({ title, children, defaultIsExpanded }) => {
  const [isExpanded, setIsExpanded] = useState();

  useEffect(() => {
    setIsExpanded(defaultIsExpanded);
  }, [defaultIsExpanded]);

  return (
    <Collapse
      isExpanded={isExpanded}
      onToggle={() => setIsExpanded(!isExpanded)}
    >
      <CollapseHeader>
        <AccordionHeader
          {...{ title }}
          isExpanded={isExpanded}
        />
      </CollapseHeader>
      <CollapseBody>{children}</CollapseBody>
    </Collapse>
  );
};
