import { Stack, Text, VStack } from "native-base";
import { useSelector } from "react-redux";
import { Accordion, Card, CardValueBox, ValueDisplay } from "../../../../ui";
import {
  getFormattedAMLTransformationEndpoint,
  getFormattedLeukemiaFreeEndpoint,
  getFormattedOverallSurvivalEndpoint,
} from "../../../../utils";

export const CalculatorEndpoints = () => {
  const { result } = useSelector((state) => state?.ipssm);

  const items = [
    getFormattedLeukemiaFreeEndpoint(result),
    getFormattedOverallSurvivalEndpoint(result),
    getFormattedAMLTransformationEndpoint(result),
  ];

  return (
    <Card>
      <Accordion
        title="Endpoints"
        defaultIsExpanded={isNaN(result?.means?.riskScore) ? false : true}
      >
        <Stack flexWrap="wrap" flexDir="row" mt="-1px" ml="-1px">
          {items.map(({ rangeMessage, ...props }) => (
            <CardValueBox
              key={props.label}
              flexBasis={{ base: "100%", md: "33.3%" }}
            >
              <VStack>
                <ValueDisplay {...props} />
                {result && <Text>{rangeMessage}</Text>}
              </VStack>
            </CardValueBox>
          ))}
        </Stack>
      </Accordion>
    </Card>
  );
};
