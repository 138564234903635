import { useCalculatorFormItem } from "../../../../../../hooks";
import { FormItemSelector } from "../../../../../../ui";

export const CalculatorFormItem = ({
  name,
  validation,
  ...props
}) => {
  const form = useCalculatorFormItem(name, validation);
  return (
    <FormItemSelector
      {...props}
      onChange={(v, rest) => form.onChange({ value: v, ...rest })}
      {...form.formItem}
    />
  );
};
