import { getAcronym, getCategoryLabel, isRange } from "../getFunctions";

export const createResults = (result, colors) => {
  const intro = `<div><h2 style="margin:0; margin-bottom:1rem; font-size:14px">Results</h2>`;
  const boxIntro = `<div style="border:solid 1px; border-radius:0.2rem; padding:1rem;">`;
  const boxOutro = `</div>`;
  const outro = `</div>`;

  const ippsm = createMainResult(result, colors);

  const ipssr = createResult(
    "IPSS-R Score:",
    result?.IPSSR_SCORE,
    result?.IPSSR,
    colors,
  );

  const ipssra = createResult(
    "IPSS-R Score (Age-adjusted):",
    result?.IPSSRA_SCORE,
    result?.IPSSRA,
    colors,
  );

  const html = [intro, boxIntro, ippsm, ipssr, ipssra, boxOutro, outro];

  return html.join("");
};

const createResult = (label, value, category, colors) => {
  const intro = `<p style="margin:0; margin-bottom:0.5rem; font-size:10px">`;
  const htmlLabel = `<strong>${label}</strong> `;
  const outro = `</p>`;

  const htmlValue = isNaN(value)
    ? `<span style="color:red;">Not calculated</span>`
    : `<span>${value.toFixed(2)}</span> ${createBadge(
        getCategoryLabel(category),
        colors[getAcronym(category)]?.base,
      )}`;

  const html = [intro, htmlLabel, htmlValue, outro];

  return html.join("");
};

const createMainResult = (result, colors) => {
  const intro = `<div style="margin:0; display:flex; gap:12px; font-size:10px">`;
  const outro = `</div>`;
  const means = createResult(
    "IPSS-M Score:",
    result?.means?.riskScore,
    result?.means?.riskCat,
    colors
  );

  if (!isRange(result)) return [intro, means, outro].join("");

  const rangeIntro = `<div style="display:flex;">(<span style="display:flex; gap:4px;">`;
  const rangeOutro = `</span>)</div>`;
  const best = createResult(
    "Best:",
    result?.best?.riskScore,
    result?.best?.riskCat,
    colors,
  );

  const middle = `<span>–</span>`;

  const worst = createResult(
    "Worst:",
    result?.worst?.riskScore,
    result?.worst?.riskCat,
    colors,
  );

  return [
    intro,
    means,
    rangeIntro,
    best,
    middle,
    worst,
    rangeOutro,
    outro,
  ].join("");
};

const createBadge = (label, color) => {
  let padding = "0.05rem 0.3rem 0.2rem 0.3rem";
  return `<span style="background-color:${color}; color:white; font-size:8px; border:solid 1px ${color}; padding: ${padding}; border-radius:0.3rem; font-weight:bold;">
      ${label.toUpperCase()}
      </span>`;
};
