import { createSlice } from "@reduxjs/toolkit";

export const gdlOptions = {
  gdl: {
    name: "gdl",
    suffix: "g/dL",
    conversion: 1,
  },
  mmol: {
    name: "mmol",
    suffix: "mmol/L",
    conversion: 0.6206,
  },
};

const initialState = {
  gdl: gdlOptions.gdl,
};

export const unitSlice = createSlice({
  name: "unitSlice",
  initialState: initialState,
  reducers: {
    changeUnit: (state, action) => {
      return {
        ...state,
        [action.payload.name]: action.payload.chosen,
      };
    },
  },
});

export const { changeUnit } = unitSlice.actions;
