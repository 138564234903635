import { Text } from "native-base";

export const GraphViolinStackTitle = () => (
  <Text textAlign="center" fontFamily="body" style={{ fontFamily: "Barlow_600SemiBold" }} fontSize="lg">
    Cohort Clinical Distributions{" "}
    <Text color="gray.1400" fontSize="sm">
      (n=2957)
    </Text>
  </Text>
);
