import { Box, HStack, Pressable, Text } from "native-base";
import { useState } from "react";
import { useWindowDimensions } from "react-native";
import { TabView, SceneMap } from "react-native-tab-view";

export const TabsCarousel = ({ routes }) => {
  const layout = useWindowDimensions();

  const [index, setIndex] = useState(0);

  const scene = routes?.reduce(function (acc, cur, i) {
    acc[cur.key] = cur.component;
    return acc;
  }, {});

  const renderTabBar = (props) => {
    return (
      <HStack justifyContent="center" mt={5} space={3}>
        {props.navigationState.routes.map((route, i) => {
          return (
            <Pressable
              onPress={() => {
                setIndex(i);
              }}
              key={i}
            >
              <Box
                alignItems="center"
                w="3"
                h="3"
                rounded="full"
                cursor="pointer"
                bg={index === i ? "primary.base" : "gray.500"}
              >
              </Box>
            </Pressable>
          );
        })}
      </HStack>
    );
  };

  return (
    <TabView
      swipeEnabled={true}
      navigationState={{ index, routes }}
      renderScene={SceneMap(scene)}
      renderTabBar={renderTabBar}
      onIndexChange={setIndex}
      initialLayout={{ width: layout.width }}
      tabBarPosition="bottom"
    />
  );
};
