import { Box, Pressable, Text, useColorModeValue } from "native-base";
import { useState } from "react";
import { useWindowDimensions } from "react-native";
import { TabView, SceneMap } from "react-native-tab-view";

export const TabsPrimary = ({ routes }) => {
  const layout = useWindowDimensions();

  const [index, setIndex] = useState(0);

  const scene = routes?.reduce(function (acc, cur, i) {
    acc[cur.key] = cur.component;
    return acc;
  }, {});

  const renderTabBar = (props) => {
    return (
      <Box flexDirection="row" flexShrink={0} overflowX="auto">
        {props.navigationState.routes.map((route, i) => {
          return (
            <Pressable
              key={i}
              onPress={() => setIndex(i)}
              flexGrow={1}
              flexShrink={0}
            >
              <Box
                borderBottomWidth="3"
                borderColor={
                  index === i ? "primary.base" : useColorModeValue("gray.200")
                }
                flexGrow={1}
                flexShrink={0}
                alignItems="center"
                p="3"
                cursor="pointer"
              >
                <Text
                  style={{ fontFamily: "Barlow_600SemiBold" }}
                  fontSize="md"
                  color={
                    index === i
                      ? "primary.base"
                      : useColorModeValue("gray.1600")
                  }
                >
                  {route.title}
                </Text>
              </Box>
            </Pressable>
          );
        })}
      </Box>
    );
  };

  return (
    <TabView
      swipeEnabled={false}
      navigationState={{ index, routes }}
      renderScene={SceneMap(scene)}
      renderTabBar={renderTabBar}
      onIndexChange={setIndex}
      initialLayout={{ width: layout.width }}
    />
  );
};
