export const departments = [
  "Department of Epidemiology and Biostatistics, Memorial Sloan Kettering Cancer Center, New York",
  "Stanford University Cancer Institute, Stanford, CA",
  "Department of Pathology, Massachusetts General Hospital, Boston",
  "Department of Pathology and Tumor Biology, Kyoto University, Kyoto, Japan",
  "Division of Hematopoietic Disease Control, Institute of Medical Science, University of Tokyo, Tokyo",
  "Department of Medicine Huddinge, Center for Hematology and Regenerative Medicine, Karolinska Institutet, Karolinska University Hospital, Stockholm",
  "Department of Hematology, Oncology and Clinical Immunology, Heinrich Heine University, Düsseldorf, Germany",
  "Department of Hematology, Hospital Universitario y Politécnico La Fe, Valencia, Spain",
  "CIBERONC, Instituto de Salud Carlos III, Madrid",
  "Health Research Institute La Fe, Valencia, Spain",
  "Department of Hematology, Amsterdam University Medical Center, Vrije University Medical Center, Amsterdam",
  "Department of Hematology, Assistance Publique-Hôpitaux de Paris, Hôpital Cochin and Université de Paris, Université Paris Descartes, Paris",
  "Department of Biomedical and Neuromotor Sciences, University of Bologna, Bologna, Italy",
  "Department of Hematology, Hemostasis, Oncology and Stem Cell Transplantation, Hannover Medical School, Hannover, Germany",
  "Hematology Department, Hospital Germans Trias i Pujol, Institut Català d’Oncologia, Josep Carreras Leukaemia Research Institute, Barcelona",
  "Drug Research and Development Center, Federal University of Ceara, Ceara, Brazil",
  "Radcliffe Department of Medicine, Oxford BRC Haematology Theme, University of Oxford, Oxford, United Kingdom",
  "Department of Medicine, Memorial Sloan Kettering Cancer Center, New York",
  "Clinics of Hematology and Medical Oncology, University Medical Center, Göttingen, Germany",
  "Department of Hematology, Hôpital St Louis, and Paris University, Paris",
  "Myelodysplastic Syndromes Group, Institut de Recerca Contra la Leucèmia Josep Carreras, Barcelona",
  "Cancer Center, Humanitas Research Hospital & Humanitas University, Milan",
  "Department of Hematology and Oncology, Graduate School of Medicine, Kyoto University, Kyoto, Japan",
  "Department of Hematology, Kobe City Medical Center General Hospital, Kobe, Japan",
  "Department of Hematology, Faculty of Medicine, University of Tsukuba, Tsukuba, Japan",
  "Department of Hematology, Gifu Municipal Hospital, Gifu, Japan",
  "Department of Hematology, Atomic Bomb Disease Institute, Nagasaki University, Nagasaki, Japan",
  "Integrated Genomics Operation, Memorial Sloan Kettering Cancer Center, New York; 29Department of Genomics, Institute of Hematology and Blood Transfusion, Prague, Czech Republic",
  "Department of Genomics, Institute of Hematology and Blood Transfusion, Prague, Czech Republic",
  "Department of Medicine, Vanderbilt-Ingram Cancer Center, Vanderbilt University School of Medicine, Nashville",
  "Oncology-Hematology Center, Hospital Israelita Albert Einstein, São Paulo",
  "Department of Hematology, Democritus University of Thrace Medical School, Alexandroupolis, Greece",
  "Myelodysplastic syndromes Unit, Department of Experimental and Clinical Medicine, Hematology, Azienda Ospedaliero Universitaria Careggi, University of Florence, Florence, Italy",
  "Medical Clinic and Policlinic 1, Hematology and Cellular Therapy, University of Leipzig, Leipzig, Germany",
  "Division of Hematology and Hemostaseology, Department of Internal Medicine I, Medical University of Vienna, Vienna",
  "Ludwig Boltzmann Institute for Hematology and Oncology, Medical University of Vienna, Vienna",
  "Tokyo Medical University, Tokyo",
  "Institute of Hematology “Seràgnoli,” Istituti di Ricovero e Cura a Carattere Scientifico Azienda Ospedaliero-Universitaria di Bologna, Bologna, Italy",
  "Myelodysplastic syndromes Cooperative Group Gruppo Laziale Mielodisplasie (GROM-L), Department of Biomedicine and Prevention, Tor Vergata University, Rome",
  "Chang Gung Memorial Hospital at Linkou, Chang Gung University, Taiwan",
  "Laboratory of Hematology, Department of Laboratory Medicine, Radboud University Medical Centre, Nijmegen, The Netherlands",
  "Department of Hematology and Genetics Unit, University Hospital La Fe, Valencia, Spain",
  "Department of Medical Oncology, Howard Hughes Medical Institute, Dana-Farber Cancer Center, Boston",
  "University of California San Diego Moores Cancer Center, La Jolla, CA",
  "Fondazione Istituto di Ricovero e Cura a Carattere Scientifico Policlinico San Matteo and University of Pavia, Pavia, Italy",
  "Institute for the Advanced Study of Human Biology, Kyoto University, Kyoto, Japan",
  "Department of Medicine, Center for Hematology and Regenerative Medicine, Karolinska Institute, Stockholm",
];
