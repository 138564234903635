import PropTypes from "prop-types";
import { Box, Text } from "native-base";

export const FormSectionBreakHeading = ({ title }) => (
  <Box d="flex" flexDirection="row" alignItems="center">
    <Rule />
    <Text
      px="2"
      fontSize="15px"
      fontFamily="Barlow_700Bold"
      textTransform="uppercase"
    >
      {title}
    </Text>
    <Rule />
  </Box>
);

const Rule = () => <Box h="1px" flexGrow="1" bgColor="gray.800" />;

FormSectionBreakHeading.propTypes = {
  title: PropTypes.string.isRequired,
};
