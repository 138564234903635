const generateRowCells = (index) => {
  return [
    { category: "vl", values: veryLow.cells[index] },
    { category: "l", values: low.cells[index] },
    { category: "ml", values: moderateLow.cells[index] },
    { category: "mh", values: moderateHigh.cells[index] },
    { category: "h", values: high.cells[index] },
    { category: "vh", values: veryHigh.cells[index] },
  ];
};

const veryLow = {
  longName: "Very Low",
  shortName: "VL",
  cells: [
    ["14%", "(381)"],
    ["≤ –1.5"],
    ["0.51", "(0.39 – 0.67)"],
    ["9.7", "5.0 – 17.4"],
    ["10.6", "5.1 – 17.4"],
    ["0.0", "1.2", "2.8"],
    ["2.2", "7.0", "15.9"],
  ],
  color: "vl",
};

const low = {
  longName: "Low",
  shortName: "L",
  cells: [
    ["33%", "(889)"],
    ["> –1.5 to –0.5"],
    ["1.0", "reference"],
    ["5.9", "2.6 – 12.0"],
    ["6.0", "3.0 – 12.8"],
    ["1.7", "3.4", "5.1"],
    ["8.5", "16.2", "29.5"],
  ],
  color: "l",
};

const moderateLow = {
  longName: "Moderate Low",
  shortName: "ML",
  cells: [
    ["11%", "(302)"],
    ["> –0.5 to 0"],
    ["1.5", "(1.2 – 1.8)"],
    ["4.5", "1.6 – 6.9"],
    ["4.6", "2.0 – 7.4"],
    ["4.9", "8.8", "11.4"],
    ["12.0", "19.8", "33.6"],
  ],
  color: "ml",
};

const moderateHigh = {
  longName: "Moderate High",
  shortName: "MH",
  cells: [
    ["11%", "(281)"],
    ["> 0 to 0.5"],
    ["2.5", "(2.1 – 3.1)"],
    ["2.3", "0.91 – 4.7"],
    ["2.8", "1.2 – 5.5"],
    ["9.5", "14.0", "18.9"],
    ["18.0", "31.1", "51.1"],
  ],
  color: "mh",
};

const high = {
  longName: "High",
  shortName: "H",
  cells: [
    ["14%", "(379)"],
    ["> 0.5 to 1.5"],
    ["3.7", "(3.1 – 4.4)"],
    ["1.5", "0.80 – 2.8"],
    ["1.7", "1.0 – 3.4"],
    ["14.3", "21.2", "29.2"],
    ["19.3", "39.8", "54.2"],
  ],
  color: "h",
};

const veryHigh = {
  longName: "Very High",
  shortName: "VH",
  cells: [
    ["17%", "(469)"],
    ["> 1.5"],
    ["7.1", "(6.0 – 8.3)"],
    ["0.76", "0.33 – 1.5"],
    ["1.0", "0.5 – 1.8"],
    ["28.2", "38.6", "42.8"],
    ["30.6", "45.6", "51.3"],
  ],
  color: "vh",
};

const patients = {
  labels: ["Patients, %", "(n=2,701)"],
  cells: generateRowCells(0),
};

const riskScore = {
  labels: ["Risk score"],
  cells: generateRowCells(1),
};

const hazardRatio = {
  labels: ["Hazard ratio*", "(95% CI)"],
  cells: generateRowCells(2),
};

const medianLfs = {
  labels: ["Median LFS, yrs", "25-75% LFS range, yrs"],
  cells: generateRowCells(3),
};

const medianOs = {
  labels: ["Median OS, yrs", "25-75% OS range, yrs"],
  cells: generateRowCells(4),
};

const amlT = {
  labels: ["AML-t by 1 yr, %", "2 yrs, %", "4 yrs, %"],
  cells: generateRowCells(5),
};

const deathWoAml = {
  labels: ["Death w/o AML by 1 yr, %", "2 yrs, %", "4 yrs, %"],
  cells: generateRowCells(6),
};

export const tableRiskStratData = {
  veryLow,
  low,
  moderateLow,
  moderateHigh,
  high,
  veryHigh,
  patients,
  riskScore,
  hazardRatio,
  medianLfs,
  medianOs,
  amlT,
  deathWoAml,
};
