import { rangeIsGoodForResult } from "../getFunctions";

export const createHtmlRangeWarning = (result) => {
  // return if no result
  if (!result) return "";

  // return if range is good
  const ipssmRangeIsGood = rangeIsGoodForResult(result);
  if (ipssmRangeIsGood) return "";

  // return if range is bad
  const intro = `<div style="margin-bottom:1rem; border:solid 1px red; padding:16px;">`;
  const message = `<p style="margin:0; color:red; font-weight:bold; font-size:10px;">IPSS-M results less confident due to missing mutation data</p>`;
  const outro = `</div>`;
  let html = [intro, message, outro];

  return html.join("");
};
