import { useTheme } from "native-base";
import { G, Line, Rect } from "react-native-svg";
import { X_LIMIT } from "../../constants";

export const ScoreRange = ({ patientScoreRange, xAxis, GRAPH_HEIGHT }) => {
  if (!patientScoreRange) return null;
  const rangePointRight =
    patientScoreRange[1] > X_LIMIT[1] ? X_LIMIT[1] : patientScoreRange[1];
  const rangePointLeft =
    patientScoreRange[0] < X_LIMIT[0] ? X_LIMIT[0] : patientScoreRange[0];

  const w = X_LIMIT[0] - (rangePointLeft - rangePointRight);
  if (!rangePointLeft || !rangePointRight) return null;
  if (rangePointRight - rangePointLeft <= 0) return null;

  const { colors } = useTheme();

  return (
    <G>
      <Line
        stroke={colors.gray[400]}
        strokeDasharray="4"
        x1={xAxis(rangePointLeft)}
        x2={xAxis(rangePointLeft)}
        y2={GRAPH_HEIGHT}
      />
      <Line
        stroke={colors.gray[400]}
        strokeDasharray="4"
        x1={xAxis(rangePointRight)}
        x2={xAxis(rangePointRight)}
        y2={GRAPH_HEIGHT}
      />
      <Rect
        stroke="grey"
        fill="grey"
        opacity={0.1}
        height={GRAPH_HEIGHT}
        width={xAxis(w)}
        x={xAxis(rangePointLeft)}
      />
    </G>
  );
};
