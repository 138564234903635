import { HStack, Stack, useBreakpointValue } from "native-base";
import {
  CalculatorButtonBarError,
  CalculatorButtonBarToggleAutoUpdate,
  CalculatorButtonBarWrapper,
  CalculatorButtonCalculate,
  CalculatorButtonReset,
} from "./ui";

export const CalculatorButtonBar = ({
  handleCalculateClick,
  handleResetClick,
  isLiveUpdating,
  isDirty,
  handleIsLiveToggle,
  errors,
}) => {
  const breakpoint = useBreakpointValue({ lg: "lg" });

  return (
    <>
      {breakpoint === "lg" && <CalculatorButtonBarError isError={errors} />}
      <CalculatorButtonBarWrapper>
        <Stack space={2}>
          <CalculatorButtonCalculate
            {...{ handleCalculateClick, isLiveUpdating, isDirty }}
          />
          <HStack space={6}>
            {breakpoint === "lg" && (
              <CalculatorButtonBarToggleAutoUpdate
                {...{ isLiveUpdating, handleIsLiveToggle }}
              />
            )}
            <CalculatorButtonReset {...{ handleResetClick }} />
          </HStack>
        </Stack>
      </CalculatorButtonBarWrapper>
    </>
  );
};
