import { HStack, Pressable, Stack, Text } from "native-base";
import { FormErrorList } from "../Form/FormErrorList/FormErrorList";
import { IconChevronRight } from "../../1_atoms/Icons/IconChevronRight";

export const ButtonOpenCalculator = ({ onPress, message }) => (
  <Pressable
    p={4}
    bg="primary.base"
    borderRadius="sm"
    _pressed={{ bg: "primary.1500" }}
    {...{ onPress }}
  >
    <HStack alignItems="center" justifyContent="space-between">
      <Stack flexShrink="1" space={1}>
        <Text
          color="white"
          textTransform="uppercase"
          style={{ fontFamily: "Barlow_600SemiBold" }}
          fontSize={18}
        >
          Edit patient data
        </Text>
        {message && <FormErrorList textColor="white" {...{ message }} />}
      </Stack>
      <IconChevronRight mr="-2" size={8} color="white" />
    </HStack>
  </Pressable>
);
