import { HStack, Pressable, Text } from "native-base";
import { FormRadioTabsLabel } from "./ui";

export const FormRadioTabs = ({
  label,
  labelWidth = 55,
  value,
  options,
  onChange,
  isInvalid,
}) => (
  <HStack alignItems="center" space={4}>
    <FormRadioTabsLabel {...{ labelWidth, label }} />
    <HStack
      borderWidth={1}
      borderColor={isInvalid ? "danger.700" : "gray.400"}
      borderRadius="sm"
      overflow="hidden"
      flex="1"
    >
      {options?.map((option, i, arr) => {
        const isActive = value === option.value;
        return (
          <Pressable
            flex="1"
            py={2}
            onPress={() => onChange(option.value)}
            bgColor={isActive ? "primary.base" : "white"}
            borderRightWidth={i < arr.length - 1 ? 1 : 0}
            borderRightColor={isActive ? "primary.base" : "gray.400"}
            key={option.value}
          >
            <Text
              fontSize={15}
              fontFamily="condensed"
              fontWeight={500}
              letterSpacing="sm"
              textAlign="center"
              color={isActive ? "white" : "gray.1600"}
            >
              {option.label}
            </Text>
          </Pressable>
        );
      })}
    </HStack>
  </HStack>
);
