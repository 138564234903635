import { View } from "native-base";
import { TabsSecondary } from "../../../../../../ui";
import { OutcomesByIpssm, OutcomesByIpssr } from "./components";

const routes = [
  {
    key: "ipssm",
    title: "By IPSS-M",
    component: OutcomesByIpssm,
  },
  {
    key: "ipssr",
    title: "By IPSS-R",
    component: OutcomesByIpssr,
  },
];

export const ClinicalOutcomes = () => (
  <View flex={1} pt={6}>
    <TabsSecondary {...{ routes }} />
  </View>
);
