export const en = {
  Age: "Age",
  BoneMarrowBlasts: "Bone Marrow Blasts",
  verygood: {
    message: "Very Good",
  },
  good: {
    message: "Good",
  },
  cytogeneticsCategory: {
    message: "Cytogenetics Category",
  },
};
