import { ascending } from "d3";
import { df_restrict } from "../../../../../../data";
import { rangeArray } from "../../../../../../utils";

const extractValueFromDfRestrict = (prop) => {
  return df_restrict
    .filter((item) => item[prop])
    .map((item) => +item[prop])
    .sort(ascending);
};

const violinBmb = {
  title:'BoneMarrowBlasts',
  name: "BM_BLAST",
  values: extractValueFromDfRestrict("BM_BLAST"),
  yConfig: { limit: [0, 20], ticks: rangeArray(0, 20, 5), unit: "%" },
};

const violinAge = {
  title:'Age',
  name: "AGE",
  values: extractValueFromDfRestrict("AGE"),
  yConfig: { limit: [20, 120], ticks: rangeArray(20, 120, 10), unit: "y" },
};

const violinAnc = {
  title:'Neutrophil Count',
  name: "ANC",
  values: extractValueFromDfRestrict("ANC"),
  yConfig: { limit: [0, 15], ticks: rangeArray(0, 15, 3), unit: "1e9/L" },
};

const violinHemoglobin = {
  title:'Hemoglobin',
  name: "HB",
  values: extractValueFromDfRestrict("HB"),
  yConfig: { limit: [4, 20], ticks: rangeArray(4, 20, 2), unit: "gdl" },
};

const violinPlateletCount = {
  title:'Platelet Count',
  name: "PLT",
  values: extractValueFromDfRestrict("PLT"),
  yConfig: { limit: [0, 600], ticks: rangeArray(0, 600, 100), unit: "1e9/L" },
};

export const violinGraphs = [
  violinBmb,
  violinHemoglobin,
  violinPlateletCount,
  violinAnc,
  violinAge,
];
