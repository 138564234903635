export const fr = {
  Age: "Âge",
  BoneMarrowBlasts: "Blastes Médullaires",
  verygood: {
    message: "Très Bon",
  },
  good: {
    message: "Bon",
  },
  cytogeneticsCategory: {
    message: "Catégorie de Cytogénétique",
  },
};
