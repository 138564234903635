import { createSlice } from "@reduxjs/toolkit";

export const ipssmSlice = createSlice({
  name: "ipssmState",
  initialState: {},
  reducers: {
    ipssmUpdateValues: (_, action) => {
      return action.payload;
    },
    ipssmResetValues: () => {
      return {};
    },
  },
});

export const { ipssmUpdateValues, ipssmResetValues } = ipssmSlice.actions;
