import { Box, Stack } from "native-base";
import { FormSectionHeading } from "../../../../../ui";
import { CalculatorFormStackWrapper } from "../ui";
import { formSchemaStackThree, genesMain, genesRes } from "../../../../../data";
import { CalculatorFormItem } from "../components";
import { SectionList } from "react-native";

const first = [formSchemaStackThree.TP53mut];
const second = [formSchemaStackThree.TP53maxvaf];
const third = [formSchemaStackThree.TP53loh];
const fourth = [formSchemaStackThree.MLL_PTD, formSchemaStackThree.FLT3all];
const fifth = [
  ...Object.entries(formSchemaStackThree)
    .filter(([gene, _]) => genesMain.includes(gene))
    .map(([_, field]) => field),
];
const sixth = [
  ...Object.entries(formSchemaStackThree)
    .filter(([gene, _]) => genesRes.includes(gene))
    .map(([_, field]) => field),
];

const sections = [
  { id: 0, title: "*Number of TP53 mutations", data: first },
  { id: 1, data: second },
  {
    id: 2,
    title: `*Loss of heterozygosity at TP53 locus ${"\n"} (if known)`,
    data: third,
  },
  { id: 3, title: "*MLL (KMT2A) and FLT3 Mutations", data: fourth },
  { id: 4, title: "*Genes (individual weights)", data: fifth },
  { id: 5, title: "*Genes (number of residual mutations)", data: sixth },
];

export const CalculatorFormStackThree = () => {
  return (
    <CalculatorFormStackWrapper>
      <Stack space={8}>
        <SectionList
          sections={sections}
          renderSectionHeader={({ section: { title, id }, index }) => {
            if (!title) return null;
            return (
              <FormSectionHeading
                mt={id !== 0 ? 10 : 0}
                mb={3}
                {...{ title }}
              />
            );
          }}
          keyExtractor={(item, index) => item + index}
          renderItem={({ item }) => <CalculatorFormItem {...item} />}
          ItemSeparatorComponent={() => <Box h={2} />}
        />
      </Stack>
    </CalculatorFormStackWrapper>
  );
};
