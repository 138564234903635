import { processFontFamily } from "expo-font";
import { useTheme } from "native-base";
import { Circle, G, Text } from "react-native-svg";

export const ViolinScore = ({ patientValue, GRAPH_WIDTH, yAxis, limit }) => {
  if (!patientValue?.value) return null;
  const { colors } = useTheme();

  const valueIsUnderMin = patientValue?.value < limit[0];
  const valueIsOverMax = patientValue?.value > limit[1];

  const dotColor =
    valueIsUnderMin || valueIsOverMax ? colors.danger[600] : colors.secondary.base;

  const value = valueIsUnderMin
    ? limit[0]
    : valueIsOverMax
    ? limit[1]
    : patientValue?.value;

  return (
    <G x={GRAPH_WIDTH / 2} y={yAxis(value)}>
      <Circle cx="0" cy="0" r="11" style={{ fill: "black" }} opacity={0.1} />
      <Circle
        cx="0"
        cy="0"
        r="7"
        stroke="white"
        strokeWidth={3}
        style={{ fill: dotColor }}
      />
      <Text
        fill="#171717"
        fontSize={13}
        y="1"
        x="14"
        fontFamily={processFontFamily("Barlow_700Bold")}
        dominantBaseline="middle"
      >
        {patientValue?.label}
      </Text>
    </G>
  );
};
