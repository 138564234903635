import { Box, HStack, Stack, Text } from "native-base";
import { TouchableHighlight } from "react-native";

export const FormRadioButtons = ({
  labelWidth = "120px",
  value,
  options = [],
  onChange = () => {},
  error,
}) => (
  <Stack space={2}>
    <Stack space="2">
      {options.map((option, i, arr) => (
        <FormRadioButton
          key={option?.value}
          chosen={value === option?.value}
          onPress={() => onChange(option?.value)}
          lastOption={i === arr.length - 1}
          labelWidth={labelWidth}
          {...option}
        />
      ))}
      <Text
        flexShrink="1"
        fontFamily="body"
        style={{ fontFamily: "Barlow_600SemiBold" }}
        color="danger.700"
        lineHeight="sm"
        fontSize="sm"
      >
        {error?.message}
      </Text>
    </Stack>
  </Stack>
);

const FormRadioButton = ({
  label,
  value,
  description,
  chosen,
  lastOption,
  labelWidth,
  disabled,
  ...props
}) => (
  <TouchableHighlight
    borderRadius="sm"
    underlayColor="transparent"
    {...{ disabled }}
    {...props}
  >
    <Box
      borderRadius="sm"
      overflow="hidden"
      borderWidth="1"
      borderColor={chosen ? "primary.base" : "gray.600"}
      bg="white"
      opacity={disabled ? 0.4 : 1}
      {...{ value }}
    >
      <HStack space="3" width="full" flexGrow="1">
        <HStack space="2" alignItems="center" w={labelWidth} px="2" py="3">
          <Box
            w="28px"
            h="28px"
            borderRadius="full"
            borderWidth="1"
            borderColor={chosen ? "primary.base" : "gray.600"}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {chosen && (
              <Box w="14px" h="14px" bg="primary.base" borderRadius="full" />
            )}
          </Box>
          <Text
            color="gray.1900"
            fontSize="14"
            fontFamily="body"
            style={{ fontFamily: "Barlow_600SemiBold" }}
            letterSpacing="sm"
          >
            {label}
          </Text>
        </HStack>
        <Box
          borderLeftWidth="1"
          borderColor="gray.600"
          flexGrow="1"
          flexShrink="1"
          display="flex"
          justifyContent="center"
          p="2"
        >
          <Text
            color="gray.1600"
            fontSize="14"
            lineHeight="sm"
            fontWeight={400}
          >
            {description}
          </Text>
        </Box>
      </HStack>
    </Box>
  </TouchableHighlight>
);
