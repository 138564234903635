import { formSchema } from "../formSchema";

const init = [
  formSchema.BM_BLAST,
  formSchema.HB,
  formSchema.PLT,
  formSchema.ANC,
  formSchema.AGE,
  formSchema.CYTO_IPSSR,
  formSchema.TP53mut,
  formSchema.TP53maxvaf,
  formSchema.TP53loh,
]
  .map((item) => ({
    [item.name]: {
      error: "Missing value",
      label: item.labelSummary || item.label,
    },
  }))
  .reduce((acc, item) => ({ ...acc, ...item }), {});

export const defaultPatientSummary = {
  ...init,
  mutatedGenes: {
    error: "Missing value",
    label: "Mutated Genes",
  },
  missingGenes: {
    error: "Missing value",
    label: "Missing Genes",
  },
};
