import { Stack } from "native-base";
import { GraphLegendItem } from "../../../../../../../../../../ui";

export const RiskStratificationGraphLegend = ({ legend }) => (
  <Stack
    direction={{ base: "row", xl: "column" }}
    space={{ base: 2, sm: 10, xl: 2 }}
    py={4}
    borderTopWidth={1}
    borderBottomWidth={1}
    borderTopColor="gray.500"
    borderBottomColor="gray.500"
    justifyContent="center"
  >
    <LegendColumn>
      {legend?.slice(0, 3)?.map((item) => (
        <GraphLegendItem key={item.label} {...item} />
      ))}
    </LegendColumn>
    <LegendColumn>
      {legend?.slice(3, 6)?.map((item) => (
        <GraphLegendItem key={item.label} {...item} />
      ))}
    </LegendColumn>
  </Stack>
);

const LegendColumn = ({ children }) => (
  <Stack space={{ base: 2 }} direction={{ base: "column" }}>
    {children}
  </Stack>
);
