import { Fragment, memo, useMemo } from "react";
import { Defs, LinearGradient, Path, Stop } from "react-native-svg";
import {
  drawDensityAreaPath,
  drawDensityLinePath,
} from "../../../../../../utils";
import { CATEGORY_CUTOFFS, X_LIMIT } from "../../constants";

export const CategoryDensityPlots = memo(
  ({ legend, xAxis, yAxis, density }) => {
    const categories = useMemo(
      () =>
        [...CATEGORY_CUTOFFS, X_LIMIT[1]].map((d, i) => {
          return density
            .filter(([xd, _]) => xd < d)
            .filter(([xd, _]) => (i > 0 ? xd > CATEGORY_CUTOFFS[i - 1] : true));
        }),
      [density]
    );

    const categoryDensities = useMemo(
      () =>
        categories.map((item) => ({
          line: drawDensityLinePath(item, yAxis, xAxis),
          area: drawDensityAreaPath(item, yAxis, xAxis),
        })),
      [xAxis, yAxis]
    );

    return <GraphItems {...{ categoryDensities, xAxis, legend }} />;
  }
);
const GraphItems = memo(({ categoryDensities, legend }) => {
  return categoryDensities?.map(({ line, area }, i) => {
    return (
      <Fragment key={i}>
        <Defs>
          <LinearGradient id={legend[i]?.color} x1="0" x2="0" y1="0" y2="1">
            <Stop stopColor={legend[i]?.colors.base} offset="30%" />
            <Stop stopColor={legend[i]?.colors[200]} offset="120%" />
          </LinearGradient>
        </Defs>
        <Path fill={`url(#${legend[i]?.color})`} opacity={0.5} d={area} />
        <Path
          stroke={legend[i]?.colors.base}
          strokeWidth={4}
          fill="none"
          d={line}
        />
      </Fragment>
    );
  });
});
