import { Box } from "native-base";

export const ViewCalculatorSideContent = ({ children }) => (
  <Box
    w={{ base: "full", lg: "350", xl: "400" }}
    borderRightWidth="1"
    borderRightColor="gray.600"
  >
    {children}
  </Box>
);
