export const fonts = {
  fontConfig: {
    Barlow: {
      400: {
        normal: "Barlow_400Regular",
      },
      600: {
        normal: "Barlow_600SemiBold",
      },
      700: { normal: "Barlow_700Bold" },
    },
    BarlowCondensed: {
      500: {
        normal: "BarlowCondensed_500Medium",
      },
    },
  },

  // Make sure values below matches any of the keys in `fontConfig`
  fonts: {
    heading: "Barlow",
    body: "Barlow",
    condensed: "BarlowCondensed",
  },
};
