import { processFontFamily } from "expo-font";
import { useTheme } from "native-base";
import { Text } from "react-native-svg";

export const LineTitle = ({ GRAPH_WIDTH, title }) => {
  const { colors } = useTheme();
  return (
    <Text
      x={GRAPH_WIDTH / 2}
      y={-20}
      fill={colors.gray[1600]}
      dominantBaseline="middle"
      textAnchor="middle"
      fontSize={14}
      fontFamily={processFontFamily("Barlow_700Bold")}
    >
      {title}
    </Text>
  );
};
