import { Box, HStack, Menu, Pressable, Stack, Text } from "native-base";
import { FormInputNumber } from "../../../1_atoms";

export const FormInputNumberWithUnitChange = ({
  labelLeft,
  labelLeftWidth = "80px",
  labelRightWidth = "80px",
  labelRight,
  error,
  handleUnitChange,
  unitOptions,
  unit,
  ...props
}) => (
  <Stack space={1}>
    <HStack alignItems="center" space={4}>
      <HStack
        width={labelLeftWidth}
        flexShrink={0}
        space={1}
        alignItems="center"
      >
        <Text fontSize={15} fontFamily="condensed" fontWeight={500}>
          {labelLeft}
        </Text>
        <Box h={4} w="1px" bg="gray.800" />
        <Menu
          defaultIsOpen={false}
          w="40"
          trigger={(triggerProps) => {
            return (
              <Pressable
                accessibilityLabel="More options menu"
                {...triggerProps}
              >
                <Text
                  fontSize={15}
                  fontFamily="condensed"
                  style={{ fontFamily: "BarlowCondensed_500Medium" }}
                  color="primary.base"
                  underline
                >
                  Change
                </Text>
              </Pressable>
            );
          }}
        >
          <Menu.OptionGroup
            defaultValue={unit.name}
            title="Units"
            type="radio"
          >
            {Object.keys(unitOptions).map((option, index) => (
              <Menu.ItemOption
                key={index}
                value={unitOptions[option].name}
                onPress={() => {
                  handleUnitChange(unitOptions[option]);
                  if (props.value) props.onChange();
                }}
              >
                {unitOptions[option].suffix}
              </Menu.ItemOption>
            ))}
          </Menu.OptionGroup>
        </Menu>
      </HStack>
      <Box flex={1}>
        <FormInputNumber {...props} />
      </Box>
      <Text
        fontSize={15}
        fontFamily="condensed"
        fontWeight={500}
        width={labelRightWidth}
        flexShrink={0}
      >
        {labelRight}
      </Text>
    </HStack>
    <Text
      flexShrink="1"
      fontFamily="body"
      style={{ fontFamily: "Barlow_600SemiBold" }}
      color="danger.700"
      lineHeight="sm"
      fontSize="sm"
    >
      {error?.message}
    </Text>
  </Stack>
);
