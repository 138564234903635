import { processFontFamily } from "expo-font";
import { G, Line, Text } from "react-native-svg";

export const GraphLeftYAxis = ({ axis, ticks, height }) => (
  <G>
    <Line stroke="grey" strokeWidth={1} y1={0} y2={height} />
    {ticks?.map(({value, label}, index) => (
      <G key={index} y={axis(value)}>
        <Text
          fill="grey"
          dominantBaseline="middle"
          textAnchor="end"
          fontFamily={processFontFamily("Barlow_700Bold")}
          fontSize={12}
          y="0"
          x="-7"
        >
          {label}
        </Text>
        <Line stroke="grey" strokeWidth={1} x1={-5} x2={0} />
      </G>
    ))}
  </G>
);
