import { View } from "native-base";
import { GraphIpssm } from "../GraphIpssm/GraphIpssm";
import { graphsIpssm } from "../../ipssmGraphData";
import { GraphIpssmLegend } from "../GraphIpssmLegend/GraphIpssmLegend";
import { TabsCarousel } from "../../../../../../../../../../ui/2_molecules/Tabs/TabsCarousel/TabsCarousel";

const routes = graphsIpssm.map((graph) => {
  return {
    key: graph.title,
    title: graph.title,
    component: () => (
      <View h="full" flex={1} px={6}>
        <GraphIpssm {...graph} />
      </View>
    ),
  };
});

export const ByIpssmMobile = () => (
  <View flex={1}>
    <View flex={1} py={4}>
      <TabsCarousel {...{ routes }} />
    </View>
    <GraphIpssmLegend />
  </View>
);
