import { useState } from "react";
import { Card, IconChevronDown, IconChevronUp, LayoutPageMain } from "../../ui";
import { references } from "./data/references";
import { departments } from "./data/departments";
import { HStack, Stack, Text, View, Button, FlatList, Link } from "native-base";

const LIMIT = 8;

export const PagePublications = () => {
  const [more, setMore] = useState();
  return (
    <LayoutPageMain>
      <Card maxW={850} w="full" mx="auto" p={6}>
        <Stack space={6}>
          <Text fontSize="2xl" style={{ fontFamily: "Barlow_600SemiBold" }}>
            The Molecular International Prognosis Scoring System (IPSS-M) for
            risk stratification in myelodysplastic syndromes.
          </Text>
          <Text fontSize="md">
            <Link href="https://doi.org/10.1056/EVIDoa2200008" isExternal>
              https://doi.org/10.1056/EVIDoa2200008
            </Link>{" "}
          </Text>
          <Stack space={4}>
            <HStack space={1} flexWrap="wrap" alignItems="flex-start">
              {references.map((item, index, arr) => {
                if (index > LIMIT && !more) return null;
                return (
                  <NameAndNumber
                    key={index}
                    {...item}
                    isLast={index === arr.length - 1}
                  />
                );
              })}
              {!more && <Text>...</Text>}
            </HStack>
            {more && (
              <FlatList
                data={departments}
                renderItem={({ item, index }) => (
                  <View
                    py={0.5}
                    style={{ flexDirection: "row", alignItems: "flex-start" }}
                  >
                    <Text lineHeight={12} fontSize="xs">
                      {index + 1}
                    </Text>
                    <Text style={{ flex: 1, paddingLeft: 2 }}>{item}</Text>
                  </View>
                )}
              />
            )}
            <Button
              alignSelf="flex-start"
              pl={0}
              _text={{ color: "primary.base", fontFamily: "Barlow_600SemiBold" }}
              bg="none"
              _hover={{ bg: "none" }}
              _pressed={{ bg: "none" }}
              onPress={() => setMore(!more)}
              rightIcon={
                more ? (
                  <IconChevronUp color="primary.base" size={6} />
                ) : (
                  <IconChevronDown color="primary.base" size={6} />
                )
              }
            >
              {more ? "Show Less" : "Show More"}
            </Button>
            <Text fontSize="xl" fontFamily='Barlow_700Bold'>
              ABSTRACT
            </Text>
            <Text fontSize="lg" style={{ fontFamily: "Barlow_600SemiBold" }}>
              Background
            </Text>
            <Text fontSize="md">
              Risk stratification and therapeutic decision-making for
              myelodysplastic syndromes (MDS) are based on the International
              Prognostic Scoring System–Revised (IPSS-R), which considers
              hematologic parameters and cytogenetic abnormalities. Somatic gene
              mutations are not yet used in the risk stratification of patients
              with MDS.
            </Text>

            <Text fontSize="lg" style={{ fontFamily: "Barlow_600SemiBold" }}>
              Methods
            </Text>
            <Text fontSize="md">
              To develop a clinical-molecular prognostic model (IPSS-Molecular
              [IPSS-M]), pretreatment diagnostic or peridiagnostic samples from
              2957 patients with MDS were profiled for mutations in 152 genes.
              Clinical and molecular variables were evaluated for associations
              with leukemia-free survival, leukemic transformation, and overall
              survival. Feature selection was applied to determine the set of
              independent IPSS-M prognostic variables. The relative weights of
              the selected variables were estimated using a robust Cox
              multivariable model adjusted for confounders. The IPSS-M was
              validated in an external cohort of 754 Japanese patients with MDS.
            </Text>

            <Text fontSize="lg" style={{ fontFamily: "Barlow_600SemiBold" }}>
              Results
            </Text>
            <Text fontSize="md">
              We mapped at least one oncogenic genomic alteration in 94% of
              patients with MDS. Multivariable analysis identified TP53multihit,
              FLT3 mutations, and MLLPTD as top genetic predictors of adverse
              outcomes. Conversely, SF3B1 mutations were associated with
              favorable outcomes, but this was modulated by patterns of
              comutation. Using hematologic parameters, cytogenetic
              abnormalities, and somatic mutations of 31 genes, the IPSS-M
              resulted in a unique risk score for individual patients. We
              further derived six IPSS-M risk categories with prognostic
              differences. Compared with the IPSS-R, the IPSS-M improved
              prognostic discrimination across all clinical end points and
              restratified 46% of patients. The IPSS-M was applicable in primary
              and secondary/therapy-related MDS. To simplify clinical use of the
              IPSS-M, we developed an open-access Web calculator that accounts
              for missing values.
            </Text>

            <Text fontSize="lg" style={{ fontFamily: "Barlow_600SemiBold" }}>
              Conclusions
            </Text>
            <Text fontSize="md">
              Combining genomic profiling with hematologic and cytogenetic
              parameters, the IPSS-M improves the risk stratification of
              patients with MDS and represents a valuable tool for clinical
              decision-making.
            </Text>
            <Text fontSize="xs">
              Bernard E, Tuechler H, Greenberg PL, et al, The Molecular
              International Prognosis Scoring System (IPSS-M) for risk
              stratification in myelodysplastic syndromes. New Eng J Med
              Evidence.
              https://evidence.nejm.org/doi/full/10.1056/EVIDoa2200008. Study
              supported by the MDS Foundation.
            </Text>
          </Stack>
        </Stack>
      </Card>
    </LayoutPageMain>
  );
};

const NameAndNumber = ({ name, number, isLast }) => (
  <View alignItems="flex-start" flexWrap="wrap" flexDirection="row">
    <Text fontSize="md">{name}</Text>
    <Text lineHeight={12} fontSize="xs">
      {number}
    </Text>
    {!isLast && <Text>,</Text>}
  </View>
);
