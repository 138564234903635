import { LegendItem, LegendKey, LegendKeyLabel, LegendLabel } from "./ui";

export const GraphLegendItem = ({ label, color, abbreviation, value }) => (
  <LegendItem>
    <LegendKey {...{ color }}>
      {/* <LegendKeyLabel {...{ abbreviation }} /> */}
    </LegendKey>
    <LegendLabel {...{ label, value }} />
  </LegendItem>
);
