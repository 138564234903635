import { Button } from "native-base";
import { IconChevronRight } from "../../../../1_atoms";

export const NavigationDrawerButton = ({ children, onPress }) => (
  <Button
    justifyContent="flex-start"
    px={0}
    mx={6}
    py={5}
    rounded="none"
    borderBottomColor="gray.500"
    borderBottomWidth={1}
    rightIcon={<IconChevronRight color="gray.1900" size={6} />}
    textAlign="left"
    _text={{
      justifyContent: "flex-start",
      textAlign: "left",
      color: "gray.1900",
      fontFamily: "Barlow_600SemiBold",
      fontSize: 16,
    }}
    bg="none"
    _hover={{ bg: "none" }}
    _pressed={{ bg: "none" }}
    onPress={onPress}
  >
    {children}
  </Button>
);
