import { Box, HStack, Text } from "native-base";
import { IconError } from "../../../1_atoms";

export const FormErrorList = ({ message, textColor = "danger.700" }) => {
  return (
    <HStack alignItems="center" space={1}>
      <Box bg="white" borderRadius="full">
        <IconError color="danger.700" size={5} solid />
      </Box>
      <Text
        flexShrink="1"
        fontFamily="body"
        style={{ fontFamily: "Barlow_600SemiBold" }}
        color={textColor}
        lineHeight="sm"
        fontSize="sm"
      >
        {message}
      </Text>
    </HStack>
  );
};
