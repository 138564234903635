import { Stack, View } from "native-base";
import { GraphIpssrLegend } from "../GraphIpssrLegend/GraphIpssrLegend";
import { graphsIpssr } from "../../ipssrGraphData";
import { GraphIpssr } from "../GraphIpssr/GraphIpssr";

export const ByIpssrDesktop = () => (
  <Stack flex={1}>
    <Stack direction="row" flex={1} space={3} p={4} alignItems="center">
      {graphsIpssr?.map((graph) => (
        <Wrapper key={graph.title}>
          <GraphIpssr {...graph} />
        </Wrapper>
      ))}
    </Stack>
    <GraphIpssrLegend />
  </Stack>
);

const Wrapper = ({ children }) => (
  <View flex={1} h="100%" maxH={420}>
    {children}
  </View>
);
