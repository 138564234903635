import { extendTheme } from "native-base";
import { fonts } from "./fonts";
import { colors } from "./colors";

export const theme = extendTheme({
  ...fonts,
  colors,
  components: {
    MenuItem: {
      baseStyle: {
        color: "primary.base",
        _focus: {
          bg: "primary.200",
        },
        _hover: {
          bg: "primary.100",
        },
        _pressed: {
          bg: "primary.300",
        },
      },
    },
    Menu: {
      baseStyle: {
        bg: "white",
      },
    },
  },
});
