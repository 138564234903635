import { HStack } from "native-base";

export const ValueDisplayStackValueBadge = ({ children, size }) => {
  const sizeVariants = { sm: { space: 1 }, md: { space: 2 }, lg: { space: 4 } };
  return (
    <HStack alignItems="center" flexWrap="wrap" flexShrink={1} {...sizeVariants[size]}>
      {children}
    </HStack>
  );
};
