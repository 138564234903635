import { createDrawerNavigator } from "@react-navigation/drawer";
import { useNavigation } from "@react-navigation/native";
import {
  NavigationDrawer,
  NavigationDrawerTitle,
  NavigationMenuButton,
} from "../../ui";

import {
  PageCalculator,
  PageIwgm,
  PageMdsFoundation,
  PagePrivacy,
  PagePublications,
  PageSupport,
  PageWebAPI,
} from "../../pages";

export const DrawerNavigatorMain = () => {
  const Drawer = createDrawerNavigator();

  return (
    <Drawer.Navigator
      screenOptions={{
        headerShown: true,
        headerTitle: NavigationDrawerTitle,
        headerTitleAlign: "center",
        headerLeft: MenuButton,
      }}
      drawerContent={(props) => <NavigationDrawer {...props} />}
    >
      <Drawer.Screen name="Calculator" component={PageCalculator} />
      <Drawer.Screen name="Support" component={PageSupport} />
      <Drawer.Screen name="Publications" component={PagePublications} />
      <Drawer.Screen name="IWG-PM" component={PageIwgm} />
      <Drawer.Screen name="MDS Foundation" component={PageMdsFoundation} />
      <Drawer.Screen name="Privacy Policy" component={PagePrivacy} />
      {/* <Drawer.Screen name="Web API" component={PageWebAPI}/> */}
    </Drawer.Navigator>
  );
};

const MenuButton = () => {
  const navigation = useNavigation();
  return <NavigationMenuButton onPress={() => navigation.toggleDrawer()} />;
};
