import { area, curveBasis, mean, line } from "d3";

export const generateDensity = (vals, min, max, xAxis, ticks = 1000) => {
  const kde = generateKde(min, max, xAxis, ticks);
  return kde(vals);
};

export const generateKde = (min, max, xAxis, ticks) => {
  return kernelDensityEstimator(
    kernelEpanechnikov((max - min) / 20),
    xAxis.ticks(ticks)
  );
};

export const drawDensityAreaPath = (density, yAxis, xAxis) => {
  const densityAreaPath = area()
    .x((d) => xAxis(d[0]))
    .y0(() => yAxis(0))
    .y1((d) => yAxis(d[1]))
    .curve(curveBasis);

  return densityAreaPath(density);
};

export const drawDensityLinePath = (density, yAxis, xAxis) => {
  const densityAreaPath = line()
    .curve(curveBasis)
    .x(function (d) {
      return xAxis(d[0]);
    })
    .y(function (d) {
      return yAxis(d[1]);
    });

  return densityAreaPath(density);
};

export const kernelDensityEstimator = (kernel, X) => {
  return (V) => X.map((x) => [x, mean(V, (v) => kernel(x - v))]);
};

export const kernelEpanechnikov = (k) => {
  return (v) => (Math.abs((v /= k)) <= 1 ? (0.75 * (1 - v * v)) / k : 0);
};

export const closestDensity = (num, arr) => {
  let curr = arr[0][1];
  let diff = Math.abs(num - arr[0][0]);
  for (let val = 0; val < arr.length; val++) {
    let newdiff = Math.abs(num - arr[val][0]);
    if (newdiff < diff) {
      diff = newdiff;
      curr = arr[val][1];
    }
  }
  return curr;
};

export const rangeArray = (start, end, step = 1) => {
  let output = [];
  if (typeof end === "undefined") {
    end = start;
    start = 0;
  }
  for (let i = start; i <= end; i += step) {
    output.push(i);
  }
  return output;
};
