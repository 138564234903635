import { HStack, Pressable, Text } from "native-base";
import { IconClose } from "../../1_atoms";

export const ButtonCloseCalculator = ({ onPress }) => (
  <Pressable
    bg="primary.base"
    _pressed={{ bg: "primary.1500" }}
    {...{ onPress }}
  >
    <HStack px={5} py={4} alignItems="center" justifyContent="space-between">
      <Text
        color="white"
        textTransform="uppercase"
        style={{ fontFamily: "Barlow_600SemiBold" }}
        fontSize={18}
      >
        Edit patient data
      </Text>
      <HStack space={1}>
        <Text style={{ fontFamily: "Barlow_600SemiBold" }} color="white">
          Close
        </Text>
        <IconClose size={6} color="white" />
      </HStack>
    </HStack>
  </Pressable>
);
