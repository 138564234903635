import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formSchema } from "../data";
import {
  formStateResetValues,
  formStateUpdateItem,
} from "../redux/slices/formSlice";
import { updateFormValidation } from "../utils";

export const useCalculatorForm = () => {
  const formState = useSelector((state) => state.formState);

  const dispatch = useDispatch();

  const [isValidated, setIsValidated] = useState();

  const checkFormIsValid = (formState) => {
    let formIsValid = true;
    const itemsMap = Object.keys(formState);
    for (let i = 0; i < itemsMap.length; i++) {
      if (formState[itemsMap[i]].isInvalid) {
        formIsValid = false;
        break;
      }
    }
    return formIsValid;
  };

  const updateValidation = (formState) => {
    setIsValidated(true);
    return updateFormValidation(formState, formSchema, (props) => {
      dispatch(formStateUpdateItem(props));
    });
  };

  const reset = () => {
    setIsValidated(false);
    dispatch(formStateResetValues());
  };

  return {
    reset,
    formState,
    checkFormIsValid,
    updateValidation,
    isValidated,
  };
};
