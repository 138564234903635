import { HStack } from "native-base";
import { GraphViolinWithData } from "./components";
import { GraphViolinStackTitle, GraphViolinStackWrapper } from "./ui";
import { violinGraphs } from "./violinGraphData";

export const CalculatorGraphViolinStack = () => (
  <GraphViolinStackWrapper>
    <GraphViolinStackTitle />
    <HStack flexWrap="wrap">
      {violinGraphs.map((graph) => (
        <GraphViolinWithData key={graph.name} {...graph} />
      ))}
    </HStack>
  </GraphViolinStackWrapper>
);
