import { HStack, Text } from "native-base";

export const ValueDisplayValue = ({ value, size, valueSuffix }) => {
  const sizeVariants = {
    sm: { fontSize: 20, lineHeight: 25 },
    md: { fontSize: { base: 22, md: 28 }, lineHeight: { base: 32, md: 36 } },
    lg: { fontSize: 46, lineHeight: 50 },
  };
  const suffixSizeVariants = {
    sm: { fontSize: 15, lineHeight: 23 },
    md: { fontSize: 16, lineHeight: 28 },
    lg: { fontSize: 22, lineHeight: 32 },
  };
  return (
    <HStack alignItems="flex-end" flexShrink={1} space={1} flexWrap="wrap">
      <Text
        fontFamily="body"
        style={{ fontFamily: "Barlow_700Bold" }}
        {...sizeVariants[size]}
      >
        {value}
      </Text>
      {valueSuffix && (
        <Text
          fontFamily="body"
          style={{ fontFamily: "Barlow_600SemiBold" }}
          {...suffixSizeVariants[size]}
        >
          {valueSuffix}
        </Text>
      )}
    </HStack>
  );
};
