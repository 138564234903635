import { Stack, View } from "native-base";
import { GraphIpssmLegend } from "../GraphIpssmLegend/GraphIpssmLegend";
import { graphsIpssm } from "../../ipssmGraphData";
import { GraphIpssm } from "../GraphIpssm/GraphIpssm";

export const ByIpssmDesktop = () => (
  <Stack flex={1}>
    <Stack direction="row" flex={1} space={3} p={4} alignItems="center">
      {graphsIpssm?.map((graph) => (
        <Wrapper key={graph.title}>
          <GraphIpssm {...graph} />
        </Wrapper>
      ))}
    </Stack>
    <GraphIpssmLegend />
  </Stack>
);

const Wrapper = ({ children }) => (
  <View flex={1} h="100%" maxH={420}>
    {children}
  </View>
);
