import { Box } from "native-base";

export const Card = ({ children, ...props }) => (
  <Box
    borderWidth={1}
    borderColor="gray.600"
    borderRadius="sm"
    bg="white"
    overflow='hidden'
    {...props}
  >
    {children}
  </Box>
);
