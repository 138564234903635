import { View } from "native-base";
import { Card, TabsPrimary } from "../../../../ui";
import { ClinicalOutcomes, RiskStratification } from "./components";

const routes = [
  {
    key: "risk1",
    title: "Risk Stratification",
    component: RiskStratification,
  },
  {
    key: "outcomes2",
    title: "Clinical Outcomes",
    component: ClinicalOutcomes,
  },
];

export const CalculatorMainGraphs = () => (
  <Card>
    <View h={720}>{routes && <TabsPrimary {...{ routes }} />}</View>
  </Card>
);
