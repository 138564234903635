export const createPatientSummary = (patientSummary = {}) => {
  const intro = `<div><h2 style="margin:0; margin-bottom:1rem; font-size:14px;">Patient Summary</h2>`;
  const boxIntro = `<div style="display:flex; border:solid 1px; border-radius:0.2rem; padding:1rem; flex-wrap:wrap;">`;
  const htmlPatientSummary = Object.keys(patientSummary)
    .map((key) => createPatientSummaryItem(patientSummary[key]))
    .join("");
  const boxOutro = `</div>`;
  const outro = `</div>`;

  const html = [intro, boxIntro, htmlPatientSummary, boxOutro, outro];

  return html.join("");
};

const createPatientSummaryItem = (patientSummaryItem) => {
  const intro = `<p style="width:50%; margin:0; margin-bottom:0.5rem; font-size:10px;">`;
  const label = `<strong>${patientSummaryItem.label}: </strong>`;
  const value = patientSummaryItem.error
    ? `<span style="color:red;">${patientSummaryItem.error}</span>`
    : `${patientSummaryItem.value} ${
        patientSummaryItem?.valueSuffix ? patientSummaryItem.valueSuffix : ""
      }`;
  const outro = `</p>`;

  const html = [intro, label, value, outro];

  return html.join("");
};
