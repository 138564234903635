export const round = (value, digits = 4) =>
  Math.round(value * 10 ** digits) / 10 ** digits;

export const range = (start, stop, step = 1) => {
  return [...Array(stop - start).keys()]
    .filter((i) => !(i % Math.round(step)))
    .map((v) => start + v);
};

// Utility to find value between intervals, and map interval number to value
export const cutBreak = (value, breaks, mapping, right = true) => {
  value = Number(value);
  for (let i = 1; i < breaks.length; i++) {
    if (right) {
      // Intervals are closed to the right, and open to the left
      if (value > breaks[i - 1] && value <= breaks[i]) {
        return mapping ? mapping[i - 1] : i - 1;
      }
    } else {
      // Intervals are open to the right, and closed to the left
      if (value >= breaks[i - 1] && value < breaks[i]) {
        return mapping ? mapping[i - 1] : i - 1;
      }
    }
  }
};
