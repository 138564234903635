import {
  amlFreeSurvivalIpssm,
  amlTransformationIpssm,
  overallSurvivalIpssm,
} from "../../../../../../../../data";
import { rangeArray } from "../../../../../../../../utils";
import { mGroupCategories } from "../../../groupSettings";

const graphIpsmmOverall = {
  title: "Overall Survival",
  data: mGroupCategories.map((cat) => ({
    label: cat.label,
    color: cat.group.toLowerCase(),
    abbreviation: cat.group.toLowerCase(),
    points: overallSurvivalIpssm
      .filter((d) => d.strata === cat.group)
      .map((d) =>
        [d["time"], d["surv"], d["n.censor"], d["lower"], d["upper"]].map(
          Number
        )
      ),
  })),
  yConfig: {
    limit: [0, 1],
    ticks: rangeArray(0, 1, 0.2).map((x) => x.toFixed(1)),
  },
  xConfig: {
    limit: [0, 6],
    ticks: rangeArray(0, 6, 1),
  },
};

const graphIpsmmLeukemiaFree = {
  title: "Leukemia-Free Survival",
  data: mGroupCategories.map((cat) => ({
    label: cat.label,
    color: cat.group.toLowerCase(),
    abbreviation: cat.group.toLowerCase(),
    points: amlFreeSurvivalIpssm
      .filter((d) => d.strata === cat.group)
      .map((d) =>
        [d["time"], d["surv"], d["n.censor"], d["lower"], d["upper"]].map(
          Number
        )
      ),
  })),
  yConfig: {
    limit: [0, 1],
    ticks: rangeArray(0, 1, 0.2).map((x) => x.toFixed(1)),
  },
  xConfig: {
    limit: [0, 6],
    ticks: rangeArray(0, 6, 1),
  },
};

const graphIpsmmTransformation = {
  title: "AML-Transformation",
  data: mGroupCategories.map((cat) => ({
    label: cat.label,
    color: cat.group.toLowerCase(),
    abbreviation: cat.group.toLowerCase(),
    points: amlTransformationIpssm
      .filter((d) => d.strata === cat.group)
      .map((d) =>
        [d["time"], d["est"], d["n.censor"], d["lower"], d["upper"]].map(Number)
      ),
  })),
  yConfig: {
    limit: [0, 0.5],
    ticks: rangeArray(0, 0.5, 0.1).map((x) => x.toFixed(1)),
  },
  xConfig: { limit: [0, 6], ticks: rangeArray(0, 6, 1) },
};

export const graphsIpssm = [
  graphIpsmmOverall,
  graphIpsmmLeukemiaFree,
  graphIpsmmTransformation,
];
