import { Link, Stack, Text } from "native-base";
import { Card, LayoutPageMain } from "../../ui";
import { Linking, Platform } from "react-native";

export const PageSupport = () => (
  <LayoutPageMain>
    <Card p={6}>
      <Stack space={6}>
        <Text fontSize="xl" style={{ fontFamily: "Barlow_600SemiBold" }}>
          Support
        </Text>
        <Text fontSize="md">
          If you have any questions or need support with the IPSS-M Calculator,
          please <MailLink />
        </Text>
      </Stack>
    </Card>
  </LayoutPageMain>
);

function MailLink() {
  const link = "mailto:bernardelsa@gmail.com,papaemme@mskcc.org?cc=arangooj@mskcc.org&subject=%5BContact%5D%20IPSS-M%20MDS%20Calculator";
  if (Platform.OS === "web")
    return (
      <Link
        _text={{ color: "primary.1000" }}
        style={{ fontFamily: "Barlow_600SemiBold" }}
        href={link}
        isExternal={true}
      >
        contact us.
      </Link>
    );

  return (
    <Link
      _text={{ color: "primary.1000" }}
      style={{ fontFamily: "Barlow_600SemiBold" }}
      onPress={() => Linking.openURL(link)}
    >
      contact us.
    </Link>
  );
}
