import { Box, HStack, Text } from "native-base";
import { LogoMdsBadge, ImageWithRatio } from "../../../1_atoms";


export const NavigationDrawerTitle = () => (
  <HStack space={{ base: 1, sm: 2, md: 3 }} alignItems="center">
    <Box w={{ base: "22px", sm: "26px", md: "30px" }}>
      <ImageWithRatio
        source={require("./assets/mdsfoundation_logo.png")}
        alt="MDS foundation logo"
        ratio={1}
      />
    </Box>
    <Text
      fontSize={{ base: 16, sm: 18, md: 22 }}
      style={{ fontFamily: "Barlow_600SemiBold" }}
    >
      IPSS-M Risk Calculator
    </Text>
  </HStack>
);
