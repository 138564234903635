import { Box } from "native-base";
import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { langConfig } from "../../../../../../../../data";
import { selectPatientSummaryItem } from "../../../../../../../../redux";
import { GraphViolin } from "../../../../../../../../ui";
import { convertCommaSeparatedNumberToDecimals } from "../../../../../../../../utils";

export const GraphViolinWithData = ({ name, values, yConfig, title }) => {
  const { value, valueSuffix } = useSelector(
    (state) => selectPatientSummaryItem(state, name),
    shallowEqual
  );
  const units = useSelector((state) => state.unit);
  const conversion = units[yConfig.unit]?.conversion || 1;

  const convertedYConfig = useMemo(
    () => ({
      ...yConfig,
      ticks: yConfig.ticks.map((t) => ({
        value: t,
        label: (t * conversion).toFixed(),
      })),
    }),
    [valueSuffix, value]
  );

  const convertedValue = useMemo(
    () => ({
      value: convertCommaSeparatedNumberToDecimals(value) / conversion,
      label: value,
    }),
    [valueSuffix, value]
  );

  return (
    <Box
      p={1}
      w={{
        base: `${100 / 2}%`,
        sm: `${100 / 3}%`,
        md: `${100 / 5}%`,
        lg: `${100 / 3}%`,
        xl: `${100 / 5}%`,
      }}
    >
      <Box w="full" maxW="220px" mx="auto">
        <GraphViolin
          title={langConfig.en[title] || title}
          patientValue={convertedValue}
          {...{ values }}
          yConfig={convertedYConfig}
        />
      </Box>
    </Box>
  );
};
