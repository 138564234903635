export * from "./getFormattedLabels";
export * from "./getFormattedEndpoints";
export * from "./getFormattedResults";
export * from "./getFormattedPatientSummaryItems";

export const rangeIsGoodForResult = (result = {}) => {
  const { best, worst } = result;
  if (!best || !worst) return false;
  if (best?.riskCat === worst?.riskCat) return true;
  if (Math.abs(best.riskScore - worst.riskScore) < 1) return true;
  if (best.riskScore === worst.riskScore) return true;
  return false;
};

export const isRange = (result = {}) => {
  const { best, worst } = result;
  if (worst?.riskScore - best?.riskScore > 0) return true;
  return false;
};
