import { useTheme } from "native-base";
import { G, Line } from "react-native-svg";
import { X_LIMIT } from "../../constants";
import { PatientPlotDot, PatientPlotToolTip } from "./components";

export const PatientPlot = ({
  patientScore,
  patientCategory,
  density,
  xAxis,
  yAxis,
  GRAPH_HEIGHT,
}) => {
  if (!patientScore) return null;
  const { colors } = useTheme();

  const valueIsUnderMin = patientScore < X_LIMIT[0];
  const valueIsOverMax = patientScore > X_LIMIT[1];

  const dotColor =
    valueIsUnderMin || valueIsOverMax ? colors.red[500] : colors.secondary.base;

  const value = valueIsUnderMin
    ? X_LIMIT[0]
    : valueIsOverMax
    ? X_LIMIT[1]
    : patientScore;

  const centerPoint = xAxis(value);

  return (
    <G x={centerPoint} y={0}>
      <Line
        stroke={"grey"}
        strokeWidth={2}
        y1={0}
        y2={GRAPH_HEIGHT}
        strokeDasharray="4"
      />
      <G y={yAxis(closestDensity(patientScore, density))}>
        <PatientPlotToolTip
          {...{ patientScore, patientCategory, xAxis, centerPoint }}
        />
        <PatientPlotDot {...{ dotColor }} />
      </G>
    </G>
  );
};

export const closestDensity = (num, arr) => {
  let curr = arr[0][1];
  let diff = Math.abs(num - arr[0][0]);
  for (let val = 0; val < arr.length; val++) {
    let newdiff = Math.abs(num - arr[val][0]);
    if (newdiff < diff) {
      diff = newdiff;
      curr = arr[val][1];
    }
  }
  return curr;
};
