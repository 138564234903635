import { Line } from "react-native-svg";
import { GRIDLINESTEP, X_LIMIT } from "../../constants";

export const XGridLines = ({ xAxis, GRAPH_HEIGHT }) => {
  return range(X_LIMIT[0], X_LIMIT[1], GRIDLINESTEP).map((item) => {
    return (
      <Line
        key={item}
        y1={GRAPH_HEIGHT}
        y2={0}
        x1={xAxis(item)}
        x2={xAxis(item)}
        stroke="#ecf0f2"
        strokeWidth={1}
      />
    );
  });
};

const range = (start, end, step = 1) => {
  let output = [];
  if (typeof end === "undefined") {
    end = start;
    start = 0;
  }
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output;
};
