import { Link, Stack, Text } from "native-base";
import { Card, LayoutPageMain } from "../../ui";

export const PagePrivacy = () => {
  return (
    <LayoutPageMain>
      <Card maxW={800} w="full" mx="auto" p={6}>
        <Stack space={6}>
          <Text fontSize="2xl" style={{ fontFamily: "Barlow_600SemiBold" }}>
            Privacy Policy
          </Text>
          <Stack space={6}>
            <Stack space={3}>
              <Text fontSize="md">
                MDS Foundation, Inc. built the IPSS-M Risk Calculator app as a
                Free app. This SERVICE is provided by MDS Foundation, Inc. at no
                cost and is intended for use as is.
              </Text>
              <Text fontSize="md">
                This page is used to inform visitors regarding our policies with
                the collection, use, and disclosure of Personal Information if
                anyone decided to use our Service.
              </Text>
              <Text fontSize="md">
                If you choose to use our Service, then you agree to the
                collection and use of information in relation to this policy.
                The Personal Information that we collect is used for providing
                and improving the Service. We will not use or share your
                information with anyone except as described in this Privacy
                Policy.
              </Text>
              <Text fontSize="md">
                The terms used in this Privacy Policy have the same meanings as
                in our Terms and Conditions, which are accessible at IPSS-M Risk
                Calculator unless otherwise defined in this Privacy Policy.
              </Text>
            </Stack>

            <Stack space={3}>
              <Text fontSize="xl" style={{ fontFamily: "Barlow_600SemiBold" }}>
                Information Collection and Use
              </Text>
              <Text fontSize="md">
                For a better experience, while using our Service, we may require
                you to provide us with certain personally identifiable
                information. The information that we request will be retained by
                us and used as described in this privacy policy.
              </Text>
              <Text fontSize="md">
                The app does use third-party services that may collect
                information used to identify you.
              </Text>
              <Text fontSize="md">
                Links to the privacy policy of third-party service providers
                used by the app:
              </Text>
              <Stack>
                <Link
                  _text={{ fontSize: "md", fontFamily: "Barlow_600SemiBold" }}
                  href="https://expo.dev/privacy"
                  isExternal
                >
                  Expo
                </Link>
                <Link
                  _text={{ fontSize: "md", fontFamily: "Barlow_600SemiBold" }}
                  href="https://segment.com/legal/privacy/"
                  isExternal
                >
                  Segment
                </Link>
                <Link
                  _text={{ fontSize: "md", fontFamily: "Barlow_600SemiBold" }}
                  href="https://mixpanel.com/legal/privacy-policy/"
                  isExternal
                >
                  Mixpanel
                </Link>
              </Stack>
            </Stack>

            <Stack space={3}>
              <Text fontSize="xl" style={{ fontFamily: "Barlow_600SemiBold" }}>
                Log Data
              </Text>
              <Text fontSize="md">
                We want to inform you that whenever you use our Service, in a
                case of an error in the app we collect data and information
                (through third-party products) on your phone called Log Data.
                This Log Data may include information such as your device
                Internet Protocol (“IP”) address, device name, operating system
                version, the configuration of the app when utilizing our
                Service, the time and date of your use of the Service, and other
                statistics.
              </Text>
            </Stack>

            <Stack space={3}>
              <Text fontSize="xl" style={{ fontFamily: "Barlow_600SemiBold" }}>
                Cookies
              </Text>
              <Text fontSize="md">
                Cookies are files with a small amount of data that are commonly
                used as anonymous unique identifiers. These are sent to your
                browser from the websites that you visit and are stored on your
                device's internal memory.
              </Text>
              <Text fontSize="md">
                This Service does not use these “cookies” explicitly. However,
                the app may use third-party code and libraries that use
                “cookies” to collect information and improve their services. You
                have the option to either accept or refuse these cookies and
                know when a cookie is being sent to your device. If you choose
                to refuse our cookies, you may not be able to use some portions
                of this Service.
              </Text>
            </Stack>

            <Stack space={3}>
              <Text fontSize="xl" style={{ fontFamily: "Barlow_600SemiBold" }}>
                Service Providers
              </Text>
              <Text fontSize="md">
                We may employ third-party companies and individuals due to the
                following reasons:
              </Text>
              <Stack>
                <Text fontSize="md">To facilitate our Service;</Text>
                <Text fontSize="md">To provide the Service on our behalf;</Text>
                <Text fontSize="md">
                  To perform Service-related services; or
                </Text>
                <Text fontSize="md">
                  To assist us in analyzing how our Service is used.
                </Text>
              </Stack>
              <Text fontSize="md">
                We want to inform users of this Service that these third parties
                have access to their Personal Information. The reason is to
                perform the tasks assigned to them on our behalf. However, they
                are obligated not to disclose or use the information for any
                other purpose.
              </Text>
            </Stack>

            <Stack space={3}>
              <Text fontSize="xl" style={{ fontFamily: "Barlow_600SemiBold" }}>
                Security
              </Text>
              <Text fontSize="md">
                We value your trust in providing us your Personal Information,
                thus we are striving to use commercially acceptable means of
                protecting it. But remember that no method of transmission over
                the internet, or method of electronic storage is 100% secure and
                reliable, and we cannot guarantee its absolute security.
              </Text>
            </Stack>

            <Stack space={3}>
              <Text fontSize="xl" style={{ fontFamily: "Barlow_600SemiBold" }}>
                Links to Other Sites
              </Text>
              <Text fontSize="md">
                This Service may contain links to other sites. If you click on a
                third-party link, you will be directed to that site. Note that
                these external sites are not operated by us. Therefore, we
                strongly advise you to review the Privacy Policy of these
                websites. We have no control over and assume no responsibility
                for the content, privacy policies, or practices of any
                third-party sites or services.
              </Text>
            </Stack>

            <Stack space={3}>
              <Text fontSize="xl" style={{ fontFamily: "Barlow_600SemiBold" }}>
                Links to Other Sites
              </Text>
              <Text fontSize="md">
                We may update our Privacy Policy from time to time. Thus, you
                are advised to review this page periodically for any changes. We
                will notify you of any changes by posting the new Privacy Policy
                on this page.
              </Text>
              <Text fontSize="md">
                This policy is effective as of 2023-02-07
              </Text>
            </Stack>

            <Stack space={3}>
              <Text fontSize="xl" style={{ fontFamily: "Barlow_600SemiBold" }}>
                Contact Us
              </Text>
              <Text fontSize="md">
                If you have any questions or suggestions about our Privacy
                Policy, do not hesitate to contact us at
              </Text>
              <Link
                _text={{ fontSize: "md", fontFamily: "Barlow_600SemiBold" }}
                href="mailto:patientliaison@mds-foundation.org."
                isExternal
              >
                patientliaison@mds-foundation.org.
              </Link>
            </Stack>

            {/*            
            <Link
              href="https://www.mds-foundation.org/mds-iwg-pm/"
              isExternal
              _text={{ fontSize: "md", fontFamily: "Barlow_600SemiBold" }}
            >
              https://www.mds-foundation.org/mds-iwg-pm/
            </Link> */}
          </Stack>
        </Stack>
      </Card>
    </LayoutPageMain>
  );
};
