import {
  getFormattedAMLTransformationEndpoint,
  getFormattedLeukemiaFreeEndpoint,
  getFormattedOverallSurvivalEndpoint,
} from "../getFunctions";

export const createHtmlEndpoints = (result) => {
  const intro = `<div><h2 style="margin:0; margin-bottom:1rem; font-size:14px;" >Endpoints</h2>`;
  const boxIntro = `<div style="border:solid 1px; border-radius:0.2rem; padding:1rem;">`;
  const boxOutro = `</div>`;
  const outro = `</div>`;

  const html = [
    intro,
    boxIntro,
    createEndpoint(getFormattedLeukemiaFreeEndpoint(result)),
    createEndpoint(getFormattedOverallSurvivalEndpoint(result)),
    createEndpoint(getFormattedAMLTransformationEndpoint(result)),
    boxOutro,
    outro,
  ];

  return html.join("");
};

const createEndpoint = (endpoint) => {
  const intro = `<p style="margin:0; margin-bottom:0.5rem; font-size:10px;">`;
  const label = `<strong>${endpoint.label}:</strong> `;
  const outro = `</p>`;
  const value = endpoint.error
    ? `<span style="color:red;">${endpoint.error}</span>`
    : `<span>${endpoint.value} (${endpoint.valueSuffix})</span>`;
  const html = [intro, label, value, outro];
  return html.join("");
};
