import { Link, Stack, Text } from "native-base";
import { Card, ImageWithRatio, LayoutPageMain } from "../../ui";

export const PageIwgm = () => {
  return (
    <LayoutPageMain>
      <Card maxW={800} w="full" mx="auto" p={6}>
        <Stack space={6}>
          <Text fontSize="2xl" style={{ fontFamily: "Barlow_600SemiBold" }}>
            International Working Group for the Prognosis of MDS (IWG-PM)
          </Text>
          <Stack space={6}>
            <Text fontSize="md">
              The International Working Group for Prognosis in MDS (IWG-PM)
              consists of a group of international investigators aligned through
              the MDS Foundation whose focus is aimed at defining the clinical
              and biologic features of MDS thus providing the foundation for
              understanding the nature and potential for progression of this
              spectrum of disorders. To this end the group has generated
              programs leading to seminal projects and publications
              characterizing and classifying the disease. Current group
              investigations include determining the impact of mutational
              features that further delineate disease status and potential
              therapeutic targets providing novel treatment approaches for MDS.
            </Text>
            <Text fontSize="md">
              The IWG-PM under the aegis of the MDS Foundation, Inc. has
              developed two prognostic tools, the IPSS-M and IPSS-R Calculators,
              to determine a patient’s risk of progressing to Acute Myeloid
              Leukemia (AML). The IPSS-M is the newest MDS prognosis calculator
              that combines genomic profiling with hematologic and cytogenetic
              parameters, improving the risk stratification of patients with
              MDS. This is a valuable tool for clinical decision-making,
              offering the prospect of tailoring diagnosis and therapeutic
              interventions to each patient’s molecular profile.
            </Text>
            <Text fontSize="md">
              More information on the IWG-PM and their current projects can be
              seen here:
            </Text>
            <Link
              href="https://www.mds-foundation.org/mds-iwg-pm/"
              isExternal
              _text={{ fontSize: "md", fontFamily: "Barlow_600SemiBold" }}
            >
              https://www.mds-foundation.org/mds-iwg-pm/
            </Link>
            <ImageWithRatio
              source={require("./assets/group.jpg")}
              alt="IWGM"
              ratio={1.8}
            />
          </Stack>
        </Stack>
      </Card>
    </LayoutPageMain>
  );
};
