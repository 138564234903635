import { configureStore } from "@reduxjs/toolkit";
import {
  ipssmSlice,
  formSlice,
  patientSummarySlice,
  printSlice,
  unitSlice,
} from "./slices";

export const store = configureStore({
  reducer: {
    formState: formSlice.reducer,
    ipssm: ipssmSlice.reducer,
    patientSummary: patientSummarySlice.reducer,
    print: printSlice.reducer,
    unit: unitSlice.reducer,
  },
});
