import { useSelector } from "react-redux";
import { df_restrict } from "../../../../../../../../../../data";
import { GraphRiskStratification } from "../../../../../../../../../../ui";

const data = df_restrict
  .map((d) => +d["risk.score"])
  .filter((d) => d && d !== "NA")
  .sort((a, b) => (a > b ? 1 : -1))
  .filter((_, i) => i % 2 === 0);

export const RiskStratificationGraphGraph = ({ legend }) => {
  const { result } = useSelector((state) => state.ipssm);
  return (
    <GraphRiskStratification
      {...{ data, legend }}
      patientScore={result?.means?.riskScore}
      patientScoreRange={[result?.best?.riskScore, result?.worst?.riskScore]}
      patientCategory={result?.means?.riskCat}
    />
  );
};
