import { Box } from "native-base";
import Svg, { G, Polygon } from "react-native-svg";

export const LogoMdsBadge = (props) => {
  const colorOne = "#cbe7e7";
  const colorTwo = "#8cc";

  return (
    <Box {...props}>
      <Svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 99.55 66.61">
        <G id="c">
          <G>
            <G>
              <Polygon
                className="cls-2"
                fill={colorTwo}
                points="15.73 0 0 66.61 20.32 66.61 36.06 0 15.73 0"
              />
              <Polygon
                className="cls-2"
                fill={colorTwo}
                points="39.61 0 23.88 66.61 44.2 66.61 59.94 0 39.61 0"
              />
              <Polygon
                className="cls-2"
                fill={colorTwo}
                points="63.5 0 47.76 66.61 68.08 66.61 83.82 0 63.5 0"
              />
            </G>
            <G>
              <Polygon
                className="cls-1"
                fill={colorOne}
                points="63.5 0 79.23 66.61 99.55 66.61 83.82 0 63.5 0"
              />
              <Polygon
                className="cls-1"
                fill={colorOne}
                points="39.61 0 55.35 66.61 75.67 66.61 59.94 0 39.61 0"
              />
              <Polygon
                className="cls-1"
                fill={colorOne}
                points="15.73 0 31.47 66.61 51.79 66.61 36.06 0 15.73 0"
              />
            </G>
          </G>
        </G>
      </Svg>
    </Box>
  );
};
