import { Stack, Text } from "native-base";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { formSchemaStackThree } from "../../../../data";
import { gdlOptions, selectItemsByCategory } from "../../../../redux";
import { FormSectionHeading } from "../../../1_atoms";
import { FormInputNumberWithLabels } from "../FormInputNumberWithLabels/FormInputNumberWithLabels";
import { FormInputNumberWithUnitChange } from "../FormInputNumberWithUnitChange/FormInputNumberWithUnitChange";
import { FormRadioButtons } from "../FormRadioButtons/FormRadioButtons";
import { FormRadioTabs } from "../FormRadioTabs/FormRadioTabs";
import { changeUnit } from "../../../../redux";

export const FormItemSelector = ({ type, ...props }) => {
  const TYPES = {
    inputNumber: FormInputNumberWithLabels,
    radioTabs: FormRadioTabs,
    radioButtons: FormRadioButtons,
    tp53VafInput: TP53VAFInput,
    hemoglobinInput: HemoglobinInput,
  };
  const DefaultComp = () => <Text>Incorrect type</Text>;
  const Comp = TYPES[type] || DefaultComp;
  return <Comp {...props} />;
};

const TP53VAFInput = ({ onChange, ...props }) => {
  const TP53mut = useSelector((state) =>
    selectItemsByCategory(state, "TP53mut")
  );

  useEffect(() => {
    let value = props.value;
    if (TP53mut?.value === "0") {
      value = formSchemaStackThree.TP53maxvaf.value;
    }
    onChange(value, { TP53mut });
  }, [JSON.stringify(TP53mut?.value)]);

  if (TP53mut?.value < 1 || !TP53mut?.value) return null;

  return (
    <Stack mt={10} space={3}>
      <FormSectionHeading title="* Maximum TP53 VAF" />
      <FormInputNumberWithLabels
        {...props}
        onChange={(v) => onChange(v, { TP53mut })}
      />
    </Stack>
  );
};

const HemoglobinInput = ({ onChange, unitOptions, value, ...props }) => {
  const { gdl } = useSelector((state) => state.unit);
  const dispatch = useDispatch();
  const handleUnitChange = (v) => {
    onChange(v, { validation: unitOptions[gdl.name].validation });
  };

  return (
    <FormInputNumberWithUnitChange
      {...props}
      {...unitOptions[gdl.name]}
      onChange={handleUnitChange}
      handleUnitChange={(option) => {
        dispatch(changeUnit({ name: "gdl", chosen: option }));
      }}
      unitOptions={gdlOptions}
      unit={gdl}
      value={value}
    />
  );
};
