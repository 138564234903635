import { AlertDanger } from "../../Alerts";

function listToString(names) {
  if (names.length === 1) {
    return names[0];
  } else if (names.length === 2) {
    return names[0] + " and " + names[1];
  } else {
    const last = names.pop();
    return names.join(", ") + ", and " + last;
  }
}

export const CalculatorButtonBarError = ({ isError }) => {
  const errorNames = isError.filter((e) => e.error).map((e) => e.name);
  return (
    <AlertDanger isOpen={errorNames.length}>
      {errorNames.length
        ? `Errors in ${listToString(errorNames)}`
        : "No errors"}
    </AlertDanger>
  );
};
