import { Stack } from "native-base";
import { GraphLegendItem } from "../../../../../../../../../../ui";
import { mGroupCategories } from "../../../../../groupSettings";

const data = mGroupCategories.map((cat) => ({
  label: cat.label,
  color: cat.group.toLowerCase(),
}));

export const GraphIpssmLegend = () => (
  <Stack
    direction={{ base: "row" }}
    space={{ base: 4 }}
    py={4}
    borderTopWidth={1}
    borderBottomWidth={1}
    borderTopColor="gray.500"
    borderBottomColor="gray.500"
    justifyContent="center"
  >
    <LegendColumn>
      {data?.slice(0, 3)?.map((item) => (
        <GraphLegendItem key={item.label} {...item} />
      ))}
    </LegendColumn>
    <LegendColumn>
      {data?.slice(3, 6)?.map((item) => (
        <GraphLegendItem key={item.label} {...item} />
      ))}
    </LegendColumn>
  </Stack>
);

const LegendColumn = ({ children }) => (
  <Stack space={{ base: 2, xl: 4 }} direction={{ base: "column", md: "row" }}>
    {children}
  </Stack>
);
