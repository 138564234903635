import { Provider } from "react-redux";
import React, { useState } from "react";
import { NativeBaseProvider, Text, View } from "native-base";
import { NavigationContainer } from "@react-navigation/native";
import { theme } from "./src/ui";
import {
  useFonts,
  Barlow_400Regular,
  Barlow_600SemiBold,
  Barlow_700Bold,
} from "@expo-google-fonts/barlow";
import { BarlowCondensed_500Medium } from "@expo-google-fonts/barlow-condensed";
import { UbuntuMono_700Bold } from "@expo-google-fonts/ubuntu-mono";
import { store } from "./src/redux/store";
import { StackNavigatorMain } from "./src/features/StackNavigatorMain/StackNavigatorMain";
import { analyticsClient } from "./src/lib";
import { StatusBar } from "expo-status-bar";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { getActiveRouteName } from "./src/utils";

export default function App() {
  const [routeName, setRouteName] = useState("unknown");

  let [fontsLoaded] = useFonts({
    Barlow_400Regular,
    Barlow_600SemiBold,
    Barlow_700Bold,
    BarlowCondensed_500Medium,
    UbuntuMono_700Bold,
  });

  if (!fontsLoaded) {
    return null;
  }

  return (
    <GestureHandlerRootView style={{ flex: 1 }}>
      <Provider store={store}>
        <StatusBar style="dark" />
        <NativeBaseProvider theme={theme}>
          <NavigationContainer
            onStateChange={(state) => {
              const newRouteName = getActiveRouteName(state);
              if (routeName !== newRouteName) {
                analyticsClient.screen(newRouteName);
                setRouteName(newRouteName);
              }
            }}
            documentTitle={{
              formatter: () => `IPSS-M Risk Calculator`,
            }}
          >
            <StackNavigatorMain />
          </NavigationContainer>
        </NativeBaseProvider>
      </Provider>
    </GestureHandlerRootView>
  );
}
