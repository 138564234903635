import { Box, HStack, Link, ScrollView, Stack, Text, View } from "native-base";
import { TableRiskStratDesktop } from "./components";
import { tableRiskStratData } from "./data";

export const TableRiskStrat = ({ activeCategory }) => {
  return (
    <View flex={1}>
      <ScrollView
        flex={1}
        contentContainerStyle={{ flexGrow: 1 }}
        horizontal={true}
      >
        <Box px={4} py={2} w="full" flex={1}>
          <TableRiskStratDesktop
            {...tableRiskStratData}
            {...{ activeCategory }}
          />
        </Box>
      </ScrollView>
      <View pt={2} pb={4} px={4}>
        <Footer />
      </View>
    </View>
  );
};

const Footer = () => (
  <Stack space={2} maxW={700}>
    <Text fontSize="xs" lineHeight="sm">
      *Hazard ratio for the risk of AML-t or death. The low category is used as
      the reference level.
    </Text>
    <Text fontSize="xs" lineHeight="sm">
      Bernard E, Tuechler H, Greenberg PL, et al, The Molecular International
      Prognosis Scoring System (IPSS-M) for risk stratification in
      myelodysplastic syndromes. New Eng J Med Evidence, 1(7).{" "}
      <Link
        href="https://evidence.nejm.org/doi/full/10.1056/EVIDoa2200008"
        isExternal
        _text={{
          fontWeight: "700",
          fontSize: "xs",
          lineHeight: "sm",
        }}
        mt={-0.5}
        _web={{
          mb: -2,
        }}
      >
        doi:10.1056/evidoa2200008
      </Link>. Study supported by the MDS Foundation.
    </Text>
  </Stack>
);
