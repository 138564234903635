import { cutBreak, round } from "../utils";

const defaultConfig = {
  values: {},
  options: { rounding: true, digits: 2 },
};

/**
 * Compute IPSS-R risk score and risk categories
 * @param {number} HB - hemoglobin, in gram per deciliter
 * @param {number} ANC - absolute neutrophile count, in giga per liter
 * @param {number} PLT - platelet, in giga per liter
 * @param {number} BM_BLAST - bone marrow blasts, in %
 * @param {number} CYTOVEC - cytogenetic category in numerical form
 * @param {number} [AGE] - Age, in years
 *
 * @return {Object} dictionary of ipssr and ipssr-age adjusted, score and category.
 */
export const calculateIpssR = (
  config = {
    options: { rounding, digits },
    values: { HB, ANC, PLT, BM_BLAST, CYTOVEC, AGE },
  }
) => {
  const merged = {
    ...defaultConfig,
    options: { ...defaultConfig.options, ...config?.options },
    values: { ...defaultConfig.values, ...config?.values },
  };

  const { values, options } = merged;
  const { rounding, digits } = options;

  let { HB, ANC, PLT, BM_BLAST, CYTOVEC, AGE } = values;

  [HB, ANC, PLT, BM_BLAST, AGE, CYTOVEC] = [HB, ANC, PLT, BM_BLAST, AGE, CYTOVEC].map(Number);

  // build category and score
  const HBri = cutBreak(
    HB,
    [-Infinity, 8, 10, Infinity],
    {
      0: 1.5,
      1: 1,
      2: 0,
    },
    false
  );
  const ANCri = cutBreak(
    ANC,
    [-Infinity, 0.8, Infinity],
    { 0: 0.5, 1: 0 },
    false
  );
  const PLTri = cutBreak(
    PLT,
    [-Infinity, 50, 100, Infinity],
    {
      0: 1,
      1: 0.5,
      2: 0,
    },
    false
  );
  const BM_BLASTri = cutBreak(
    BM_BLAST,
    [-Infinity, 2, 4.99, 10, Infinity],
    {
      0: 0,
      1: 1,
      2: 2,
      3: 3,
    },
    true
  );

  // build raw score
  let ipssrRaw = HBri + ANCri + PLTri + BM_BLASTri + CYTOVEC;
  if (rounding) {
    ipssrRaw = round(ipssrRaw, digits);
  }

  // build categories
  const ipssrgBreaks = [-Infinity, 1.5, 3, 4.5, 6, Infinity];
  const ipssrCat = ["VERY-LOW", "LOW", "INT", "HIGH", "VERY-HIGH"];
  const ipssr = cutBreak(ipssrRaw, ipssrgBreaks, ipssrCat);

  let ipssraRaw = null;
  let ipssra = null;
  if (![null, undefined].includes(AGE)) {
    // if age is present build score with age interaction
    const ageAdjust = (AGE - 70) * (0.05 - ipssrRaw * 0.005);
    ipssraRaw = ipssrRaw + ageAdjust;

    if (rounding) {
      ipssraRaw = round(ipssraRaw, digits);
    }

    // build categories
    ipssra = cutBreak(ipssraRaw, ipssrgBreaks, ipssrCat);
  }

  return {
    IPSSR_SCORE: ipssrRaw,
    IPSSR: ipssr,
    IPSSRA_SCORE: ipssraRaw,
    IPSSRA: ipssra,
  };
};
