import { processFontFamily } from "expo-font";
import { G, Line, Text } from "react-native-svg";

export const GraphBottomAxis = ({ axis, ticks, height, width, title }) => (
  <G y={height}>
    <Line stroke="grey" strokeWidth={1} x1={0} x2={width} />
    <Text
      y={40}
      textAnchor="middle"
      x={width / 2}
      fontFamily={processFontFamily("Barlow_700Bold")}
      fontSize={14}
      fill="grey"
    >
      {title}
    </Text>
    {ticks?.map((tick) => (
        <G key={tick} x={axis(tick)}>
          <Text
            fill="grey"
            dominantBaseline="middle"
            textAnchor="middle"
            fontFamily={processFontFamily("Barlow_700Bold")}
            fontSize={12}
            y={14}
            x={0}
          >
            {tick}
          </Text>
          <Line stroke="grey" strokeWidth={1} y1={0} y2={6} />
        </G>
      ))}
  </G>
);
