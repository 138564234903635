import { processFontFamily } from "expo-font";
import { G, Rect, Text } from "react-native-svg";
import { CATEGORY_CUTOFFS, X_LIMIT } from "../../constants";

export const CategoryLabels = ({ xAxis, legend }) => {
  return (
    <G>
      {legend?.map((item, i, arr) => {
        const first = i === 0 ? X_LIMIT[0] : CATEGORY_CUTOFFS[i - 1];
        const second = i >= arr.length - 1 ? X_LIMIT[1] : CATEGORY_CUTOFFS[i];
        const width = xAxis(X_LIMIT[0] - (first - second)) - 10;
        const xPos = xAxis(first) + 5;
        return (
          <G y="5" x={xPos} key={i}>
            <Rect
              x="0"
              y="0"
              width={width}
              height="20"
              fill={item.colors.base}
              rx="3"
            />
            <Text
              y="15"
              x={width / 2}
              textAnchor="middle"
              fontFamily={processFontFamily("Barlow_700Bold")}
              fontSize={14}
              fill="#ffffff"
            >
              {item.abbreviation}
            </Text>
          </G>
        );
      })}
    </G>
  );
};
