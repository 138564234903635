import { Button, Text } from "native-base";
import { IconMenu } from "../../../1_atoms";

export const NavigationMenuButton = ({onPress}) => (
  <Button
    background="none"
    _text={{}}
    onPress={onPress}
    leftIcon={<IconMenu size={8} color="gray.1900" />}
  >
    <Text
      color="gray.1900"
      style={{ fontFamily: "Barlow_600SemiBold" }}
      fontSize="md"
      display={{ base: "none", md: "flex" }}
    >
      Menu
    </Text>
  </Button>
);
