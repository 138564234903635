import { Box, Link, Stack, Text } from "native-base";
import { Card, ImageWithRatio, LayoutPageMain } from "../../ui";

export const PageMdsFoundation = () => (
  <LayoutPageMain>
    <Card p={6}>
      <Stack space={6}>
        <Text fontSize="2xl" style={{ fontFamily: "Barlow_600SemiBold" }}>
          MDS Foundation
        </Text>
        <Box maxW="400">
          <ImageWithRatio
            source={require("./assets/mdsfoundation.png")}
            alt="MDS foundation logo"
            ratio={5.9}
          />
        </Box>
        <Stack space={2}>
          <Text fontSize="xl" style={{ fontFamily: "Barlow_600SemiBold" }}>
            About the MDS Foundation
          </Text>
          <Text fontSize="md">
            The Myelodysplastic Syndromes (MDS) Foundation is a global
            non-profit advocacy organization that for over 25 years has
            supported patients and their families as well as healthcare
            providers in the fields of MDS and its related diseases.
          </Text>
        </Stack>
        <Stack space={2}>
          <Text fontSize="xl" style={{ fontFamily: "Barlow_600SemiBold" }}>
            Our Vision
          </Text>
          <Text fontSize="md">
            Every MDS patient will benefit from our initiatives and research as
            early as possible.
          </Text>
        </Stack>
        <Stack space={2}>
          <Text fontSize="xl" style={{ fontFamily: "Barlow_600SemiBold" }}>
            Our Mission
          </Text>
          <Text fontSize="md">
            MDS Foundation supports and educates patients, their communities,
            and healthcare providers, and contributes to innovative research in
            the fields of MDS and its related continuum of diseases to better
            diagnose, control and ultimately cure these diseases.
          </Text>
        </Stack>
        <Text fontSize="md">
          Visit{" "}
          <Link href="https://www.mds-foundation.org/" isExternal>
            https://www.mds-foundation.org/
          </Link>{" "}
          to find out more.
        </Text>
      </Stack>
    </Card>
  </LayoutPageMain>
);
