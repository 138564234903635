import { DrawerContentScrollView } from "@react-navigation/drawer";
import { NavigationDrawerButton } from "./ui";

export const NavigationDrawer = ({ state, navigation }) => (
  <DrawerContentScrollView>
    {state?.routes?.map((route) => (
      <NavigationDrawerButton
        key={route.name}
        onPress={() => navigation.jumpTo(route.name)}
      >
        {route.name}
      </NavigationDrawerButton>
    ))}
  </DrawerContentScrollView>
);
