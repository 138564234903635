import { createSlice } from "@reduxjs/toolkit";

export const printSlice = createSlice({
  name: "printSlice",
  initialState: {
    isPrint: false,
  },
  reducers: {
    setIsPrint: (_, action) => {
      return { isPrint: action.payload };
    },
  },
});

export const { setIsPrint } = printSlice.actions;
