import { View } from "native-base";
import { GraphIpssr } from "../GraphIpssr/GraphIpssr";
import { graphsIpssr } from "../../ipssrGraphData";
import { GraphIpssrLegend } from "../GraphIpssrLegend/GraphIpssrLegend";
import { TabsCarousel } from "../../../../../../../../../../ui/2_molecules/Tabs/TabsCarousel/TabsCarousel";

const routes = graphsIpssr.map((graph) => {
  return {
    key: graph.title,
    title: graph.title,
    component: () => (
      <View h="full" flex={1} px={6}>
        <GraphIpssr {...graph} />
      </View>
    ),
  };
});

export const ByIpssrMobile = () => (
  <View flex={1}>
    <View flex={1} py={4}>
      <TabsCarousel {...{ routes }} />
    </View>
    <GraphIpssrLegend />
  </View>
);
