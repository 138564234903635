import { ButtonDefault, IconReload } from "../../../1_atoms";

export const CalculatorButtonReset = ({ handleResetClick }) => (
  <ButtonDefault
    size="sm"
    flex={1}
    leftIcon={<IconReload color="primary.base" size={5} solid />}
    onPress={handleResetClick}
  >
    Reset Values
  </ButtonDefault>
);
