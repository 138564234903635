import { createSlice, createSelector } from "@reduxjs/toolkit";
import { formSchema } from "../../data";

const defaultValues = {
  ...Object.fromEntries(
    Object.entries(formSchema).map(([key, { value, label, unit }]) => [
      key,
      { value, label, unit },
    ])
  ),
};

export const formSlice = createSlice({
  name: "formState",
  initialState: defaultValues,
  reducers: {
    formStateReplaceItem: (state, action) => {
      return {
        ...state,
        [action.payload.name]: {
          ...action.payload.props,
        },
      };
    },
    formStateUpdateItem: (state, action) => {
      return {
        ...state,
        [action.payload.name]: {
          ...state[action.payload.name],
          ...action.payload.props,
        },
      };
    },
    formStateResetValues: () => {
      return defaultValues;
    },
  },
});

export const {
  formStateReplaceItem,
  formStateResetValues,
  formStateUpdateItem,
} = formSlice.actions;

export const selectItemsByCategory = createSelector(
  [
    // Usual first input - extract value from `state`
    (state) => state.formState,
    // Take the second arg, `category`, and forward to the output selector
    (_, category) => category,
  ],
  // Output selector gets (`items, category)` as args
  (formState, category) => {
    return formState[category];
  }
);

export const selectMultipleItemsByCategory = createSelector(
  [
    // Usual first input - extract value from `state`
    (state) => state.formState,
    // Take the second arg, `category`, and forward to the output selector
    (_, category) => category,
  ],
  // Output selector gets (`items, category)` as args
  (formState, category) => {
    if (!category?.length) return null;
    let items = {};
    category.forEach((element) => {
      items[element] = formState[element];
    });
    return items;
  }
);
