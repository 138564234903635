import { View } from "native-base";

export const TableCell = ({
  children,
  even,
  active,
  category,
  first,
  topLine,
  ...props
}) => (
  <View
    borderRightColor="gray.400"
    borderRightWidth={1}
    borderLeftColor={first && "gray.400"}
    borderLeftWidth={first && 1}
    borderBottomColor="gray.400"
    borderBottomWidth={1}
    borderTopColor={topLine && "gray.400"}
    borderTopWidth={topLine && 1}
    justifyContent="center"
    flexShrink={0}
    flexGrow={1}
    bg={
      active
        ? even
          ? `${category}.200`
          : `${category}.100`
        : even
        ? "gray.200"
        : "white"
    }
    px={2}
    py={{ base: 1, sm: 2 }}
    {...props}
  >
    {children}
  </View>
);
