import { HStack, Text } from "native-base";
import { memo } from "react";
import { BadgePrimary } from "../../../1_atoms";
import {
  ValueDisplayLabel,
  ValueDisplayValue,
  VideoDisplayWrapper,
  ValueDisplayStackValueBadge,
} from "./ui";

export const ValueDisplay = memo(
  ({
    label,
    value,
    size = "md",
    badgeLabel,
    badgeColorScheme,
    valueSuffix,
    error,
  }) => {
    return (
      <VideoDisplayWrapper>
        <ValueDisplayLabel {...{ label }} />
        {error ? (
          <Text
            fontSize={20}
            color="danger.700"
            style={{ fontFamily: "Barlow_600SemiBold" }}
          >
            {error}
          </Text>
        ) : (
          <ValueDisplayStackValueBadge {...{ size }}>
            <HStack flexShrink={1}>
              <ValueDisplayValue {...{ value, size, valueSuffix }} />
            </HStack>
            {badgeLabel && (
              <BadgePrimary
                label={badgeLabel}
                colorScheme={badgeColorScheme}
                {...{ size }}
              />
            )}
          </ValueDisplayStackValueBadge>
        )}
      </VideoDisplayWrapper>
    );
  }
);
