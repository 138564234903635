import { View, useBreakpointValue } from "native-base";

import {
  CalculatorAccordion,
  CalculatorFormButtonBar,
  CalculatorFormStackHeader,
  CalculatorFormStackOne,
  CalculatorFormStackThree,
  CalculatorFormStackTwo,
} from "./components";
import {
  formSchemaStackOne,
  formSchemaStackThree,
  formSchemaStackTwo,
} from "../../data";
import { useState } from "react";

const items = [
  {
    id: 1,
    data: [{ id: 1, Component: CalculatorFormStackOne }],
    Header: ({ setCollapsible, ...props }) => (
      <CalculatorFormStackHeader
        title="Clinical Data"
        formSchemaItems={formSchemaStackOne}
        onPress={() => setCollapsible(1)}
        {...props}
      />
    ),
  },
  {
    id: 2,
    data: [{ id: 2, Component: CalculatorFormStackTwo }],
    Header: ({ setCollapsible, ...props }) => (
      <CalculatorFormStackHeader
        title="Cytogenetics"
        formSchemaItems={formSchemaStackTwo}
        onPress={() => setCollapsible(2)}
        {...props}
      />
    ),
  },
  {
    id: 3,
    data: [{ id: 3, Component: CalculatorFormStackThree }],
    Header: ({ setCollapsible, ...props }) => (
      <CalculatorFormStackHeader
        title="Molecular Data"
        formSchemaItems={formSchemaStackThree}
        onPress={() => setCollapsible(3)}
        {...props}
      />
    ),
  },
];

export const CalculatorForm = ({ navigation }) => {
  const breakpoint = useBreakpointValue({ lg: "lg" });
  const [accordionIndex, setAccordionIndex] = useState(1);
  return (
    <View bg="graysecondary.100" flex={1}>
      <CalculatorAccordion
        accordionIndex={accordionIndex}
        items={items}
        setCollapsible={(e) =>
          e !== accordionIndex ? setAccordionIndex(e) : setAccordionIndex()
        }
      />
      <CalculatorFormButtonBar
        onCalculateClick={() => {
          if (breakpoint !== "lg") {
            setAccordionIndex();
          }
        }}
        {...{ navigation }}
      />
    </View>
  );
};
