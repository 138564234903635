import PropTypes from "prop-types";
import { Input } from "native-base";
import { convertCommaSeparatedNumberToDecimals } from "../../../../utils";

export const FormInputNumber = ({
  placeholder,
  onChange = () => {},
  value,
  error,
  ...props
}) => {
  return (
    <Input
      fontSize="18px"
      type="number"
      keyboardType="decimal-pad"
      returnKeyType="done"
      _invalid={{
        _stack: {
          style: {
            outlineWidth: "0",
          },
        },
      }}
      borderColor={"gray.800"}
      textAlign="center"
      style={{ fontFamily: "Barlow_600SemiBold" }}
      h="40px"
      overflow="hidden"
      bgColor="white"
      onChangeText={(v) => {
        if (isNumeric(v) || v === "") onChange(v.replace(" ", ""));
      }}
      value={isNumeric(value) ? value : ""}
      {...{ placeholder }}
      {...props}
    />
  );
};

function isNumeric(str) {
  if (str === 0) return true;
  if (!str) return false;
  const n = convertCommaSeparatedNumberToDecimals(str);
  return !isNaN(n) && !isNaN(parseFloat(n));
}

FormInputNumber.propTypes = {
  placeholder: PropTypes.string,
  onChangeText: PropTypes.func,
};
