import { Text } from "native-base";
import { ButtonBarReportWrapper, ButtonReportDownload } from "./ui";

export const ButtonBarReport = ({ handleDownloadReportClick }) => {
  return (
    <ButtonBarReportWrapper>
      <ButtonReportDownload {...{ handleDownloadReportClick }} />
    </ButtonBarReportWrapper>
  );
};
