import { Box, HStack, Stack, Text } from "native-base";
import { FormInputNumber } from "../../../1_atoms";

export const FormInputNumberWithLabels = ({
  labelLeft,
  labelLeftWidth = "80px",
  labelRightWidth = "80px",
  labelRight,
  error,
  ...props
}) => (
  <Stack space={1}>
    <HStack alignItems="center" space={4}>
      <Text
        fontSize={15}
        fontFamily="condensed"
        fontWeight={500}
        width={labelLeftWidth}
        flexShrink={0}
      >
        {labelLeft}
      </Text>
      <Box flex={1}>
        <FormInputNumber {...props} />
      </Box>
      <Text
        fontSize={15}
        fontFamily="condensed"
        fontWeight={500}
        width={labelRightWidth}
        flexShrink={0}
      >
        {labelRight}
      </Text>
    </HStack>
    <Text
      flexShrink="1"
      fontFamily="body"
      style={{ fontFamily: "Barlow_600SemiBold" }}
      color="danger.700"
      lineHeight="sm"
      fontSize="sm"
    >
      {error?.message}
    </Text>
  </Stack>
);
