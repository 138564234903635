import { isRequired } from "../../utils";
import { FORM_TYPES } from "./formTypes";

export const formSchemaStackTwo = {
  del5q: {
    name: "del5q",
    label: "del(5q)",
    type: FORM_TYPES.radioTabs,
    options: [
      { label: "No", value: "0" },
      { label: "Yes", value: "1" },
    ],
    value: "0",
    validation: [isRequired("del(5q) is required")],
  },
  del7_7q: {
    name: "del7_7q",
    label: "-7/del(7q)",
    type: FORM_TYPES.radioTabs,
    options: [
      { label: "No", value: "0" },
      { label: "Yes", value: "1" },
    ],
    value: "0",
    validation: [isRequired("-7/del(7q) is required")],
  },
  del17_17p: {
    name: "del17_17p",
    label: "-17/del(17p)",
    type: FORM_TYPES.radioTabs,
    options: [
      { label: "No", value: "0" },
      { label: "Yes", value: "1" },
    ],
    value: "0",
    validation: [isRequired("-17/del(17p) is required")],
  },
  complex: {
    name: "complex",
    label: "Complex Karyoptype",
    type: FORM_TYPES.radioTabs,
    options: [
      { label: "No", value: "0" },
      { label: "Yes", value: "1" },
    ],
    value: "0",
    validation: [isRequired("Complex Karyoptype is required")],
  },
  CYTO_IPSSR: {
    name: "CYTO_IPSSR",
    label: "Cytogenetics Category",
    i18nKey: "cytogeneticsCategory",
    type: FORM_TYPES.radioButtons,
    options: [
      {
        label: "Very Good",
        i18nKey: "verygood",
        value: "0",
        description: "-Y, del(11q).",
        isDisabled: ({ del5q, del7_7q, del17_17p, complex }) => {
          if (
            del5q?.value === "1" ||
            del7_7q?.value === "1" ||
            del17_17p?.value === "1" ||
            complex?.value === "1"
          )
            return true;
          return false;
        },
      },
      {
        label: "Good",
        i18nKey: "good",
        value: "1",
        description:
          "Normal, del(5q), del(12p), del(20q), double including del(5q).",
        isDisabled: ({ del7_7q, del17_17p, complex }) => {
          if (
            del7_7q?.value === "1" ||
            del17_17p?.value === "1" ||
            complex?.value === "1"
          )
            return true;
          return false;
        },
      },
      {
        label: "Intermediate",
        value: "2",
        description:
          "del(7q), +8, +19, i(17q), any other single or double independent clones.",
        isDisabled: ({ complex }) => {
          if (complex?.value === "1") return true;
          return false;
        },
      },
      {
        label: "Poor",
        value: "3",
        description:
          "-7, inv(3)/t(3q)/del(3q), double including -7/del(7q), Complex: 3 abnormalities.",
        isDisabled: () => {
          return false;
        },
      },
      {
        label: "Very Poor",
        value: "4",
        description: "Complex; > 3 abnormalities",
        isDisabled: () => {
          return false;
        },
      },
    ],
    validation: [isRequired("Cytogenetics Category is required")],
  },
};
