import {
  amlFreeSurvivalIpssr,
  amlTransformationIpssr,
  overallSurvivalIpssr,
} from "../../../../../../../../data";
import { rangeArray } from "../../../../../../../../utils";
import { getAcronym } from "../../../../../../../../utils/getFunctions/getFormattedLabels";
import { rGroupCategories } from "../../../groupSettings";

const graphIpssrOverall = {
  title: "Overall Survival",
  data: rGroupCategories.map((cat) => ({
    label: cat.label,
    color: getAcronym(cat.group),
    abbreviation: cat.group,
    points: overallSurvivalIpssr
      .filter((d) => d.strata === cat.group)
      .map((d) =>
        [d["time"], d["surv"], d["n.censor"], d["lower"], d["upper"]].map(
          Number
        )
      ),
  })),
  yConfig: {
    limit: [0, 1],
    step: 0.1,
    axisRange: [0, 1],
    ticks: rangeArray(0, 1, 0.2).map((x) => x.toFixed(1)),
  },
  xConfig: {
    limit: [0, 6],
    ticks: rangeArray(0, 6, 1),
  },
};

const graphIpssrLeukemiaFree = {
  title: "Leukemia-Free Survival",
  data: rGroupCategories.map((cat) => ({
    label: cat.label,
    color: getAcronym(cat.group),
    abbreviation: cat.group,
    points: amlFreeSurvivalIpssr
      .filter((d) => d.strata === cat.group)
      .map((d) =>
        [d["time"], d["surv"], d["n.censor"], d["lower"], d["upper"]].map(
          Number
        )
      ),
  })),
  yConfig: {
    limit: [0, 1],
    ticks: rangeArray(0, 1, 0.2).map((x) => x.toFixed(1)),
  },
  xConfig: {
    limit: [0, 6],
    ticks: rangeArray(0, 6, 1),
  },
};

const graphIpssrTransformation = {
  title: "AML-Transformation",
  data: rGroupCategories.map((cat) => ({
    label: cat.label,
    color: getAcronym(cat.group),
    abbreviation: cat.group,
    points: amlTransformationIpssr
      .filter((d) => d.strata === cat.group)
      .map((d) =>
        [d["time"], d["est"], d["n.censor"], d["lower"], d["upper"]].map(Number)
      ),
  })),
  yConfig: {
    limit: [0, 0.5],
    ticks: rangeArray(0, 0.5, 0.1).map((x) => x.toFixed(1)),
  },
  xConfig: {
    limit: [0, 6],
    ticks: rangeArray(0, 6, 1),
  },
};

export const graphsIpssr = [
  graphIpssrOverall,
  graphIpssrLeukemiaFree,
  graphIpssrTransformation,
];
