import { mGroupSettings } from "../../data/graph/groupSettings";


export const getFormattedLeukemiaFreeEndpoint = (result) => {
  const mGroup = mGroupSettings[result?.means?.riskCat];
  return {
    label: "Leukemia-Free Survival (IPSS-M)",
    valueSuffix: "median",
    value: `${mGroup?.lfs?.median} years`,
    error: !result && "Not calculated",
    rangeMessage: `${mGroup?.lfs?.iqRange[0]}-${mGroup?.lfs?.iqRange[1]} years, 25%-75% range`
  };
};

export const getFormattedOverallSurvivalEndpoint = (result) => {
  const mGroup = mGroupSettings[result?.means?.riskCat];
  return {
    label: "Overall Survival (IPSS-M)",
    valueSuffix: "median",
    value: `${mGroup?.os?.median} years`,
    error: !result && "Not calculated",
    rangeMessage: `${mGroup?.os?.iqRange[0]}-${mGroup?.os?.iqRange[1]} years, 25%-75% range`
  };
};

export const getFormattedAMLTransformationEndpoint = (result) => {
  const mGroup = mGroupSettings[result?.means?.riskCat];
  return {
    label: "AML Transformation (IPSS-M)",
    valueSuffix: "by 1 year",
    value: `${mGroup?.amlT?.by1Year}%`,
    error: !result && "Not calculated",
    rangeMessage: `${mGroup?.amlT?.by4Years}% by 4 years`
  };
};
