import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { DrawerNavigatorMain } from "../DrawerNavigatorMain/DrawerNavigatorMain";
import { ModalCalculatorForm } from "../ModalCalculatorForm/ModalCalculatorForm";

export const StackNavigatorMain = () => {
  const Stack = createNativeStackNavigator();

  return (
    <Stack.Navigator screenOptions={{ headerShown: false }}>
      <Stack.Screen name="app" component={DrawerNavigatorMain} />
      <Stack.Screen name="formModal" component={ModalCalculatorForm} />
    </Stack.Navigator>
  );
};
