import { area, curveBasis, curveStepAfter, line } from "d3";
import { useTheme } from "native-base";
import { G, Path } from "react-native-svg";

export const LineData = ({ data, xAxis, yAxis, patientCategory }) => {
  const { colors } = useTheme();
  return data.map((item) => {
    const color = patientCategory
      ? patientCategory === item.abbreviation
        ? colors[item.color].base
        : colors.gray[400]
      : colors[item.color].base;
    return (
      <G key={item.label}>
        <Path
          opacity={0.2}
          d={drawArea(item.points, yAxis, xAxis)}
          fill={color}
        />
        <Path
          d={drawLine(item.points, yAxis, xAxis)}
          stroke={color}
          strokeWidth={2}
          fill="none"
        />
      </G>
    );
  });
};

const drawArea = (data, yAxis, xAxis) => {
  const path = area()
    .x((d) => xAxis(d[0]))
    .y0((d) => yAxis(d[3]))
    .y1((d) => yAxis(d[4]))
    .curve(curveBasis);

  return path(data);
};

const drawLine = (data, yAxis, xAxis) => {
  const path = line()
    .x((d) => xAxis(d[0]))
    .y((d) => yAxis(d[1]))
    .curve(curveStepAfter);

  return path(data);
};
