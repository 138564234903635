import { area, curveBasis } from "d3";
import { useTheme } from "native-base";
import { memo } from "react";
import { Path } from "react-native-svg";

export const ViolinDensity = memo(({ density, yAxis, xAxis }) => {
  const mainDensity = drawDensityAreaPath(density, yAxis, xAxis);
  const { colors } = useTheme();
  return <Path fill={colors.graysecondary[300]} d={mainDensity} />;
});

const drawDensityAreaPath = (density, yAxis, xAxis) => {
  const densityAreaPath = area()
    .x0((d) => xAxis(-d[1]))
    .x1((d) => xAxis(d[1]))
    .y((d) => yAxis(d[0]))
    .curve(curveBasis);

  return densityAreaPath(density);
};
