import { Alert, Collapse, HStack, Text } from "native-base";
import { Platform } from "react-native";
import { IconError } from "../../../1_atoms";

export function AlertDanger({ isOpen, children }) {
  return (
    <Collapse isOpen={isOpen}>
      <Alert
      px={1}
        rounded="none"
        status="error"
        variant="solid"
        bg={
          Platform.OS !== "web"
            ? isOpen
              ? "danger.700"
              : "white"
            : "danger.700"
        }
      >
        <HStack flexShrink={1} space={1} px={2} alignItems="center">
          <IconError color="white" size={5} />
          <Text fontSize="sm" lineHeight={18} style={{ fontFamily: "Barlow_600SemiBold" }} color="white">
            {children}
          </Text>
        </HStack>
      </Alert>
    </Collapse>
  );
}
