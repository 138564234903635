import { ScrollView, Stack, useBreakpointValue, View } from "native-base";
import {
  ViewCalculatorWrapper,
  ViewCalculatorMainContent,
  ViewCalculatorSideContent,
} from "./ui";
import {
  CalculatorEndpoints,
  CalculatorLaunchButton,
  CalculatorMainGraphs,
  CalculatorPatientSummary,
  CalculatorRangeWarning,
  CalculatorReportButtonBar,
  CalculatorStratificationResults,
} from "./components/";
import { CalculatorForm } from "../../features/CalculatorForm/CalculatorForm";

export const PageCalculator = () => {
  const breakpoint = useBreakpointValue({ lg: "lg" });
  return (
    <ViewCalculatorWrapper>
      {breakpoint === "lg" && (
        <ViewCalculatorSideContent>
          <CalculatorForm />
        </ViewCalculatorSideContent>
      )}
      <View flex={1}>
        <CalculatorRangeWarning />
        <ScrollView style={{ flex: 1 }}>
          <ViewCalculatorMainContent>
            <Stack space={5}>
              {breakpoint !== "lg" && <CalculatorLaunchButton />}
              <CalculatorPatientSummary />
              <CalculatorStratificationResults />
              <CalculatorEndpoints />
              <CalculatorMainGraphs />
              <CalculatorReportButtonBar />
            </Stack>
          </ViewCalculatorMainContent>
        </ScrollView>
      </View>
    </ViewCalculatorWrapper>
  );
};
