import { Button } from "native-base";
import { ButtonPrimary, IconSync } from "../../../1_atoms";

export const CalculatorButtonCalculate = ({
  handleCalculateClick,
  isDirty,
  isLiveUpdating,
}) => (
  <ButtonPrimary
    size="md"
    leftIcon={<IconSync size={5} solid />}
    onPress={handleCalculateClick}
    disabled={!isDirty || isLiveUpdating}
  >
    {isDirty ? "Calculate Risk" : "Calculated"}
  </ButtonPrimary>
);
