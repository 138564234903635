import { Platform } from "react-native";
import { ButtonDefault, IconDownload, IconShare } from "../../../../1_atoms";

export const ButtonReportDownload = ({ handleDownloadReportClick }) => {
  const Icon = Platform.OS === "web" ? IconDownload : IconShare;
  return (
    <ButtonDefault
      leftIcon={<Icon color="primary.base" size={5} solid />}
      onPress={handleDownloadReportClick}
    >
      {Platform.OS === "web" ? "Download PDF" : "Share PDF"}
    </ButtonDefault>
  );
};
