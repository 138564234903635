import { Box } from "native-base";

export const LegendKey = ({ color, children }) => (
  <Box
    bg={`${color}.base`}
    h={{ base: 4, sm: 4 }}
    w={{ base: 4, sm: 4 }}
    borderRadius={2}
    d="flex"
    alignItems="center"
    justifyContent="center"
  >
    {children}
  </Box>
);
