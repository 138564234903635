import {
  isMin,
  isRequired,
  isMax,
  isTp53maxvafCustomRequired,
} from "../../utils";
import { formSchemaMutations } from "./formSchemaMutations";
import { FORM_TYPES } from "./formTypes";

export const formSchemaStackThree = {
  TP53mut: {
    name: "TP53mut",
    label: "Mutation Count",
    labelSummary: "TP53 Mutation Count",
    altLabel: "TP53",
    type: FORM_TYPES.radioTabs,
    options: [
      { label: "0", value: "0" },
      { label: "1", value: "1" },
      { label: "2+", value: "2 or more" },
    ],
    validation: [isRequired("Mutation Count is required")],
    // value: "0",
  },
  TP53maxvaf: {
    name: "TP53maxvaf",
    label: "TP53 Maximum VAF",
    labelLeft: "Percentage",
    labelRight: "[0-100%]",
    type: FORM_TYPES.tp53VafInput,
    validation: [
      isTp53maxvafCustomRequired(),
      isMin(0, `Percentage must be between 0 and 100`),
      isMax(100, `Percentage must be between 0 and 100`),
    ],
  },
  TP53loh: {
    name: "TP53loh",
    label: "TP53 LOH",
    labelSummary: "TP53 locus LOH",
    type: FORM_TYPES.radioTabs,
    options: [
      { value: "0", label: "No" },
      { value: "1", label: "Yes" },
      { value: "NA", label: "N/A" },
    ],
    value: "0",
    validation: [isRequired("LoH at TP53 locus is required")],
  },
  MLL_PTD: {
    name: "MLL_PTD",
    label: "MLL PTD",
    type: FORM_TYPES.radioTabs,
    options: [
      { label: "No", value: "0" },
      { label: "Yes", value: "1" },
      { label: "Not Assessed", value: "NA" },
    ],
    validation: [isRequired("MLL PTD is required")],
    value: "0",
  },
  FLT3all: {
    name: "FLT3all",
    label: "FLT3 ITD or TKD",
    type: FORM_TYPES.radioTabs,
    options: [
      { label: "No", value: "0" },
      { label: "Yes", value: "1" },
      { label: "Not Assessed", value: "NA" },
    ],
    validation: [isRequired("FLT3 ITD or TKD is required")],
    value: "0",
  },
  ...formSchemaMutations,
};
