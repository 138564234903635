import { Stack } from "native-base";
import { GraphLegendItem } from "../../../../../../../../../../ui";
import { getAcronym } from "../../../../../../../../../../utils/getFunctions/getFormattedLabels";
import { rGroupCategories } from "../../../../../groupSettings";

const data = rGroupCategories.map((cat) => ({
  label: cat.label,
  color: getAcronym(cat.group),
}));

export const GraphIpssrLegend = () => (
  <Stack
    direction={{ base: "row" }}
    space={{ base: 4 }}
    py={4}
    borderTopWidth={1}
    borderBottomWidth={1}
    borderTopColor="gray.500"
    borderBottomColor="gray.500"
    justifyContent="center"
  >
    <LegendColumn>
      {data?.slice(0, 3)?.map((item) => (
        <GraphLegendItem key={item.label} {...item} />
      ))}
    </LegendColumn>
    <LegendColumn>
      {data?.slice(3, 6)?.map((item) => (
        <GraphLegendItem key={item.label} {...item} />
      ))}
    </LegendColumn>
  </Stack>
);

const LegendColumn = ({ children }) => (
  <Stack space={{ base: 2, xl: 4 }} direction={{ base: "column", md: "row" }}>
    {children}
  </Stack>
);
