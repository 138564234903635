import { scaleLinear } from "d3";
import { Box } from "native-base";
import { useMemo, useState } from "react";
import Svg, { G } from "react-native-svg";
import { GraphBottomAxis, GraphLeftYAxis } from "../../../2_molecules/Graph";
import { LineData, LineTitle } from "./components";

const MARGIN_TOP = 35;
const MARGIN_BOTTOM = 50;
const MARGIN_LEFT = 25;
const MARGIN_RIGHT = 5;

export const GraphLine = ({
  yConfig,
  xConfig,
  data,
  title,
  patientCategory,
}) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  const GRAPH_WIDTH = containerWidth - MARGIN_LEFT - MARGIN_RIGHT;
  const GRAPH_HEIGHT = containerHeight - MARGIN_TOP - MARGIN_BOTTOM;

  const yAxis = useMemo(
    () => scaleLinear().domain(yConfig?.limit).range([GRAPH_HEIGHT, 0]),
    [GRAPH_HEIGHT]
  );

  const xAxis = useMemo(
    () => scaleLinear().domain(xConfig?.limit).range([0, GRAPH_WIDTH]),
    [GRAPH_WIDTH]
  );

  return (
    <Box
      h="100%"
      onLayout={(e) => {
        setContainerHeight(e.nativeEvent.layout?.height);
        setContainerWidth(e.nativeEvent.layout?.width);
      }}
    >
      {GRAPH_HEIGHT && GRAPH_WIDTH && (
        <Svg
          height={GRAPH_HEIGHT + MARGIN_TOP + MARGIN_BOTTOM}
          width={GRAPH_WIDTH + MARGIN_LEFT + MARGIN_RIGHT}
        >
          <G y={MARGIN_TOP} x={MARGIN_LEFT}>
            <LineTitle {...{ title, GRAPH_WIDTH }} />
            <GraphLeftYAxis axis={yAxis} height={GRAPH_HEIGHT} {...yConfig} />
            <GraphBottomAxis
              axis={xAxis}
              height={GRAPH_HEIGHT}
              width={GRAPH_WIDTH}
              title="Years"
              {...xConfig}
            />
            <LineData
              {...{ data, patientCategory }}
              yAxis={yAxis}
              xAxis={xAxis}
            />
          </G>
        </Svg>
      )}
    </Box>
  );
};
