import { Platform, SafeAreaView, StatusBar } from "react-native";
import { CalculatorForm } from "../../features/CalculatorForm/CalculatorForm";
import { ButtonCloseCalculator } from "../../ui";
import { StatusBar as Bar } from "expo-status-bar";

export const ModalCalculatorForm = ({ navigation }) => (
  <SafeAreaView
    style={{
      flex: 1,
      backgroundColor: "#00529b",
      paddingTop: Platform.OS === "android" ? StatusBar.currentHeight : 0,
    }}
  >
    <Bar style="light" />
    <ButtonCloseCalculator onPress={() => navigation.goBack()} />
    <CalculatorForm {...{ navigation }} />
  </SafeAreaView>
);
