import { processFontFamily } from "expo-font";
import { useTheme } from "native-base";
import { G, Rect, Text } from "react-native-svg";
import { getAcronym } from "../../../../../../../../utils/getFunctions/getFormattedLabels";
import { MARGIN_LEFT, MARGIN_RIGHT, X_LIMIT } from "../../../../constants";

export const PatientPlotToolTip = ({
  patientScore = "0",
  patientCategory = "N/A",
  xAxis,
  centerPoint,
}) => {
  const { colors } = useTheme();

  const patientCategoryTextWidth = patientCategory.length * 7.7;
  const patientScoreTextWidth = patientScore.toFixed(2).toString().length * 8.1;
  const boxWidth = patientCategoryTextWidth + patientScoreTextWidth + 35;

  const offset = centerPoint + boxWidth / 2 - xAxis(X_LIMIT[1]);

  const boxOffset = (boxWidth / 2) * -1;
  const isOnLeftSide = centerPoint <= boxWidth / 2 - MARGIN_LEFT;
  const isOnRightSide = offset - MARGIN_RIGHT > 0;

  const actualOffset = isOnLeftSide
    ? boxOffset + (boxWidth / 2 - centerPoint) - (MARGIN_LEFT - 2)
    : isOnRightSide
    ? boxOffset - offset + MARGIN_RIGHT - 2
    : boxOffset;

  return (
    <G x={actualOffset} y="-80">
      <Rect
        rx="3"
        stroke="gray"
        fill="white"
        width={boxWidth}
        height="60"
        x="0"
        y="0"
      />
      <Text
        fill="#171717"
        fontSize={12}
        y="20"
        x="10"
        fontFamily={processFontFamily("Barlow_700Bold")}
      >
        Patient Score
      </Text>
      <G y="40" x="10">
        <Text
          fill="#171717"
          fontSize={16}
          y="6"
          fontFamily={processFontFamily("UbuntuMono_700Bold")}
        >
          {patientScore.toFixed(2)}
        </Text>
        <G y="0" x={patientScoreTextWidth + 10}>
          <Rect
            rx="3"
            x="-5"
            y="-10"
            width={patientCategoryTextWidth + 10}
            height="20"
            fill={colors[getAcronym(patientCategory)].base}
          />
          <Text
            fill="#fff"
            fontSize={15}
            y="4.5"
            x="0"
            fontFamily={processFontFamily("UbuntuMono_700Bold")}
            id="categoryTextSVG"
          >
            {patientCategory.toUpperCase()}
          </Text>
        </G>
      </G>
    </G>
  );
};
