import { Box } from "native-base";

export const CalculatorButtonBarWrapper = ({ children }) => (
  <Box
    bg="white"
    px={5}
    py={3}
    borderTopWidth={1}
    borderTopColor="gray.400"
  >
    {children}
  </Box>
);
