export const CATEGORY_CUTOFFS = [-1.5, -0.5, 0, 0.5, 1.5];
export const SCORE_TICKS = [-2, -1, 0, 1, 2, 3, 4];
export const Y_TICKS = [0, 0.1, 0.2, 0.3, 0.4, 0.5];
export const X_LIMIT = [-3.5, 4.2];
export const Y_LIMIT = [0, 0.5];
export const GRIDLINESTEP = 0.5;
export const YGRIDSTEP = 0.05;
export const MARGIN_LEFT = 53;
export const MARGIN_RIGHT = 16;
export const MARGIN_BOTTOM = 50;
export const MARGIN_TOP = 70;
