import { useDispatch } from "react-redux";
import { ipssmUpdateValues, ipssmResetValues } from "../redux";
import {
  analyticsClient,
  calculateIpssM,
  calculateIpssR,
  processInputs,
} from "../lib";
import { usePatientSummary } from "./usePatientSummary";
import { convertCommaSeparatedNumberToDecimals } from "../utils";

export const useCalculator = () => {
  const { updatePatientSummary, resetPatientSummary } = usePatientSummary();
  const dispatch = useDispatch();

  const calculateValues = (formState, units) => {
    let submitted = {};

    Object.keys(formState).forEach((key) => {
      if (!formState[key].value) return;
      const conversion = units[formState[key]?.unit]?.conversion;
      const convertedToDecimals = convertCommaSeparatedNumberToDecimals(
        formState[key].value
      );
      if (conversion) return (submitted[key] = convertedToDecimals / conversion);
      return (submitted[key] = convertedToDecimals);
    });

    const processedInputs = processInputs(submitted);
    const ipssR = calculateIpssR({ values: processedInputs });
    const ipssM = calculateIpssM({ values: processedInputs });
    const result = {
      ...ipssR,
      ...ipssM,
    };

    // Track submitted values
    analyticsClient.track("Computed Risk", processedInputs);

    // Track IPPSM
    if (ipssM?.means && ipssM?.best && ipssM?.worst) {
      const mResult = {
        mScore: ipssM?.means?.riskScore,
        mCategory: ipssM?.means?.riskCat,
      };

      const mResultBest = {
        mScore: ipssM?.best?.riskScore,
        mCategory: ipssM?.best?.riskCat,
      };

      const mResultWorst = {
        mScore: ipssM?.worst?.riskScore,
        mCategory: ipssM?.worst?.riskCat,
      };
      analyticsClient.track("Computed IPSS-M", {
        processed: processedInputs,
        mResult,
        mResultBest,
        mResultWorst,
      });
    }

    // Track IPSSR
    if (ipssR?.IPSSR_SCORE) {
      analyticsClient.track("Computed IPSS-R", {
        processed: processedInputs,
        rResult: ipssR,
      });
    }

    updatePatientSummary(formState, units);

    dispatch(
      ipssmUpdateValues({
        result,
        submitted,
      })
    );
  };

  const reset = () => {
    resetPatientSummary();
    dispatch(ipssmResetValues());
  };

  return { reset, calculateValues };
};
