import { Stack, Link, Text } from "native-base";

export const RiskStratificationGraphText = () => (
  <Stack space={2}>
    <Text fontSize="xs" lineHeight="sm">
      *Hazard ratio for risk of AML-t or death from the average patient.
    </Text>
    <Text fontSize="xs" lineHeight="sm">
      Bernard E, Tuechler H, Greenberg PL, et al, The Molecular International
      Prognosis Scoring System (IPSS-M) for risk stratification in
      myelodysplastic syndromes. New Eng J Med Evidence, 1(7).{" "}
      <Link
        href="https://evidence.nejm.org/doi/full/10.1056/EVIDoa2200008"
        isExternal
        _text={{
          fontWeight: "700",
          fontSize: "xs",
          lineHeight: "sm",
        }}
        mt={-0.5}
        _web={{
          mb: -2,
        }}
      >
        doi:10.1056/evidoa2200008
      </Link>. Study supported by the MDS Foundation.
    </Text>
  </Stack>
);
