import { useSelector } from "react-redux";
import { createPrintReport } from "../utils";
import { usePatientSummary } from "./usePatientSummary";
import { jsPDF } from "jspdf";
import { useTheme } from "native-base";

export const usePrintReport = () => {
  const { patientSummary } = usePatientSummary();
  const { result } = useSelector((state) => state.ipssm);
  const { colors } = useTheme();

  const downloadPdf = () => {
    const doc = new jsPDF("p", "pt", "a4");
    doc.html(createPrintReport(patientSummary, result, colors), {
      callback: function (doc) {
        doc.save("ipssm-risk-report.pdf");
        // window.open(doc.output("bloburl")); //
      },
      width: 555,
      windowWidth: 555,
      x: 20,
      y: 20,
    });
  };

  return { downloadPdf };
};
