import { Box } from "native-base";
import { TouchableOpacity } from "react-native";
import { AccordionHeader } from "../../../../../ui";
import { CalculatorFormErrorLabelList } from "./components";

export const CalculatorFormStackHeader = ({
  isExpanded,
  formSchemaItems = {},
  title,
  onPress,
}) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <Box borderBottomColor="gray.400" borderBottomWidth={1}>
        <AccordionHeader
          titleChildren={
            <CalculatorFormErrorLabelList
              itemsToCheck={Object.keys(formSchemaItems).map(
                (k) => formSchemaItems[k]
              )}
            />
          }
          {...{ isExpanded, title }}
        />
      </Box>
    </TouchableOpacity>
  );
};
