import { useCallback, useEffect, useState } from "react";
import { CalculatorButtonBar } from "../../../../ui";
import _debounce from "lodash/debounce";
import { useCalculatorForm } from "../../../../hooks/useCalculatorForm";
import { useCalculator } from "../../../../hooks";
import { useSelector } from "react-redux";
import {
  formSchemaStackOne,
  formSchemaStackThree,
  formSchemaStackTwo,
} from "../../../../data";
import { analyticsClient } from "../../../../lib";

export const CalculatorFormButtonBar = ({
  navigation,
  onCalculateClick = () => {},
}) => {
  const units = useSelector((state) => state.unit);
  const calculator = useCalculator();
  const form = useCalculatorForm();
  const [autoUpdate, setAutoUpdate] = useState();
  const [isDirty, setIsDirty] = useState(true);

  const isErrorInStackOne = Object.keys(formSchemaStackOne)
    .map((k) => form.formState[k])
    .filter((k) => k.error);

  const isErrorInStackTwo = Object.keys(formSchemaStackTwo)
    .map((k) => form.formState[k])
    .filter((k) => k.error);

  const isErrorInStackThree = Object.keys(formSchemaStackThree)
    .map((k) => form.formState[k])
    .filter((k) => k.error);

  const errors = [
    { name: "Clinical Data", error: isErrorInStackOne.length },
    { name: "Cytogenetics", error: isErrorInStackTwo.length },
    { name: "Molecular Data", error: isErrorInStackThree.length },
  ];

  useEffect(() => {
    setIsDirty(true);
  }, [JSON.stringify(form.formState)]);

  useEffect(() => {
    if (autoUpdate) {
      debounceSubmit(form.formState, units);
    }
  }, [autoUpdate, JSON.stringify(form.formState)]);

  const debounceSubmit = useCallback(
    _debounce((formState, units) => {
      handleSubmit(formState, units);
    }, 100),
    []
  );

  const resetState = () => {
    setAutoUpdate(false);
    form.reset();
    calculator.reset();
  };

  const handleSubmit = (formState, units) => {
    let isValid = form.isValidated
      ? form.checkFormIsValid(formState)
      : form.updateValidation(formState);
    if (isValid) {
      navigation?.goBack();
      setIsDirty(false);
      calculator.calculateValues(formState, units);
    }
  };

  const toggleAutoUpdate = () => {
    setAutoUpdate(!autoUpdate);
    analyticsClient.track("Auto update toggled", {
      autoUpdate: !autoUpdate,
    });
  };

  return (
    <CalculatorButtonBar
      errors={errors}
      isLiveUpdating={autoUpdate}
      handleCalculateClick={() => {
        onCalculateClick();
        handleSubmit(form.formState, units);
      }}
      handleResetClick={resetState}
      handleIsLiveToggle={toggleAutoUpdate}
      {...{ isDirty }}
    />
  );
};
