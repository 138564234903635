import { useDispatch, useSelector } from "react-redux";
import { patientSummaryReset, patientSummaryUpdate } from "../redux";

export const usePatientSummary = () => {
  const dispatch = useDispatch();
  const patientSummary = useSelector((state) => state.patientSummary);

  const updatePatientSummary = (formState, units) => {
    dispatch(patientSummaryUpdate({ formState, units }));
  };

  const resetPatientSummary = () => dispatch(patientSummaryReset());

  return { patientSummary, updatePatientSummary, resetPatientSummary };
};
