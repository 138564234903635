import { Stack, useTheme, View } from "native-base";
import { mGroupCategories } from "../../../groupSettings";
import {
  RiskStratificationGraphGraph,
  RiskStratificationGraphLegend,
  RiskStratificationGraphText,
} from "./components";

const totalPatients = mGroupCategories.reduce(
  (acc, obj) => acc + obj.patients,
  0
);

const legend = mGroupCategories.map((cat) => ({
  label: cat.label,
  color: cat.group.toLowerCase(),
  abbreviation: cat.group,
  value: `${Math.round((cat.patients / totalPatients) * 100)}%`,
}));

export const RiskStratificationGraph = () => {
  const { colors } = useTheme();
  const l = legend.map((item) => {
    return {
      ...item,
      colors: colors[item.color],
    };
  });
  return (
    <Stack
      flex={1}
      direction={{ base: "column", xl: "row" }}
      space={{ base: 4 }}
      p={{ xl: 6 }}
      pt={{ base: 4, xl: 6 }}
    >
      <View px={{ base: 4, xl: 0 }} flex={1}>
        <RiskStratificationGraphGraph legend={l} />
      </View>
      <Stack w={{ xl: 240 }}>
        <RiskStratificationGraphLegend legend={l} />
        <View py={4} px={{ base: 4, xl: 0 }}>
          <RiskStratificationGraphText />
        </View>
      </Stack>
    </Stack>
  );
};
