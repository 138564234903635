import { Circle, G } from "react-native-svg";

export const PatientPlotDot = ({ dotColor }) => (
  <G>
    <Circle cx="0" cy="0" r="16" style={{ fill: "black" }} opacity={0.1} />
    <Circle
      cx="0"
      cy="0"
      r="10"
      stroke="white"
      strokeWidth={4}
      style={{ fill: dotColor }}
    />
  </G>
);
