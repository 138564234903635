import { Stack } from "native-base";
import { FormSectionBreakHeading, FormSectionHeading } from "../../../../../ui";
import { CalculatorFormStackWrapper } from "../ui";
import { formSchemaStackOne } from "../../../../../data";
import { CalculatorFormItem } from "../components/CalculatorFormItem/CalculatorFormItem";

const first = [
  formSchemaStackOne.BM_BLAST,
  formSchemaStackOne.HB,
  formSchemaStackOne.PLT,
];

const second = [formSchemaStackOne.ANC, formSchemaStackOne.AGE];

export const CalculatorFormStackOne = () => {
  return (
    <CalculatorFormStackWrapper>
      <Stack space={8}>
        <Stack space={5}>
          {first?.map((item) => (
            <Stack key={item.name} space={2}>
              <FormSectionHeading title={`*${item.label}`} />
              <CalculatorFormItem
                labelLeftWidth="90px"
                labelRightWidth="110px"
                {...item}
              />
            </Stack>
          ))}
        </Stack>
        
        <FormSectionBreakHeading title="Optional IPSS-R data" />

        <Stack space={5}>
          {second.map((item) => (
            <Stack key={item.name} space={2}>
              <FormSectionHeading title={`${item.label}`} />
              <CalculatorFormItem
                labelLeftWidth="90px"
                labelRightWidth="110px"
                {...item}
              />
            </Stack>
          ))}
        </Stack>
      </Stack>
    </CalculatorFormStackWrapper>
  );
};
