import { useTheme } from "native-base";
import { memo } from "react";
import { G, Line } from "react-native-svg";
import { closestDensity } from "../../../../../../utils";

export const ViolinQuantiles = memo(
  ({ q1, median, q3, xAxis, yAxis, density }) => {
    const { colors } = useTheme();
    const q1den = closestDensity(q1, density);
    const medianden = closestDensity(median, density);
    const q3den = closestDensity(q3, density);

    return (
      <G>
        <Line
          x1={xAxis(-q1den)}
          x2={xAxis(q1den)}
          y1={yAxis(q1)}
          y2={yAxis(q1)}
          stroke={colors.graysecondary[1200]}
          strokeDasharray="4"
        />
        <Line
          x1={xAxis(-medianden)}
          x2={xAxis(medianden)}
          y1={yAxis(median)}
          y2={yAxis(median)}
          stroke={colors.graysecondary[1200]}
        />
        <Line
          x1={xAxis(-q3den)}
          x2={xAxis(q3den)}
          y1={yAxis(q3)}
          y2={yAxis(q3)}
          stroke={colors.graysecondary[1200]}
          strokeDasharray="4"
        />
      </G>
    );
  }
);
