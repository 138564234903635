import { View } from "native-base";
import { TabsSecondary } from "../../../../../../ui";
import { RiskStratificationGraph, RiskStratificationTable } from "./components";

const routes = [
  {
    key: "riskgraph",
    title: "Graph",
    component: RiskStratificationGraph,
  },
  {
    key: "risktable",
    title: "Table",
    component: RiskStratificationTable,
  },
];

export const RiskStratification = () => (
  <View flex={1} pt={6}>
    <TabsSecondary {...{ routes }} />
  </View>
);
