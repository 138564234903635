import { Box, Stack } from "native-base";
import { TableCell, TableRow, TableText } from "./ui";

const HEADER_COLUMN_WIDTH = 160;
const COLUMN_WIDTH = 100;

export const TableRiskStratDesktop = ({
  veryLow,
  low,
  moderateLow,
  moderateHigh,
  high,
  veryHigh,
  patients,
  riskScore,
  hazardRatio,
  medianLfs,
  medianOs,
  amlT,
  deathWoAml,
  activeCategory,
}) => (
  <Stack w='full' flex={1}>
    <HeaderRow
      cells={[veryLow, low, moderateLow, moderateHigh, high, veryHigh]}
    />
    <Row {...patients} {...{ activeCategory }} even />
    <Row {...riskScore} {...{ activeCategory }} />
    <Row {...hazardRatio} {...{ activeCategory }} even />
    <Row {...medianLfs} {...{ activeCategory }} />
    <Row {...medianOs} {...{ activeCategory }} even />
    <Row {...amlT} {...{ activeCategory }} />
    <Row {...deathWoAml} {...{ activeCategory }} even />
  </Stack>
);

const HeaderRow = ({ cells }) => (
  <TableRow>
    <TableCell w={HEADER_COLUMN_WIDTH} flexGrow={0} textAlign='right' px={4} first topLine>
      <TableText fontSize="md">IPSS-M</TableText>
    </TableCell>
    {cells?.map(({ longName, color }) => (
      <TableCell key={longName} alignItems="center" w={COLUMN_WIDTH} topLine>
        <Stack space={1} alignItems="center">
          <TableText textAlign="center" fontSize="md">
            {longName}
          </TableText>
          <Box bg={`${color}.base`} h={4} w={4} borderRadius="xs" />
        </Stack>
      </TableCell>
    ))}
  </TableRow>
);

const Row = ({ labels, cells, even, activeCategory }) => (
  <TableRow>
    <TableCell w={HEADER_COLUMN_WIDTH} flexGrow={0} textAlign='right' px={4} {...{ even }} first>
      <TableText>{labels.join("\n")}</TableText>
    </TableCell>
    {cells?.map(({ values, category }, i) => (
      <TableCell
        key={i}
        w={COLUMN_WIDTH}
        {...{ category, even }}
        active={category === activeCategory}
      >
        <TableText textAlign="center">{values.join("\n")}</TableText>
      </TableCell>
    ))}
  </TableRow>
);
