import { useDispatch, useSelector } from "react-redux";
import { formStateUpdateItem, selectItemsByCategory } from "../redux";

export const useCalculatorFormItem = (name, validation) => {
  const dispatch = useDispatch();
  const formItem = useSelector((state) => selectItemsByCategory(state, name));

  const onChange = (values) => {
    const { value } = values;
    let errorStatus;

    // form now valdates on entry as per request from Jaunes :)
    // may need to delay with a debounce
    // if (formItem.submitted) {
      let error;
      for (let i = 0; i < validation?.length; i++) {
        const errorMessage = validation[i](values);
        if (errorMessage) {
          error = { ...errorMessage, name, label: formItem.label };
          break;
        }
      }
      errorStatus = {
        error,
        isInvalid: error ? true : false,
        isValid: !error ? true : false,
      };
    // }

    dispatch(
      formStateUpdateItem({
        name,
        props: { value, ...errorStatus },
      })
    );
  };

  return { onChange, formItem };
};
