import { Line } from "react-native-svg";
import { CATEGORY_CUTOFFS } from "../../constants";

export const CategoryGridLines = ({ xAxis, GRAPH_HEIGHT }) => {
  const color = "lightgrey";
  return CATEGORY_CUTOFFS.map((item, i) => {
    const x = xAxis(item);
    return (
      <Line
        key={i}
        stroke={color}
        strokeWidth={3}
        x1={x}
        x2={x}
        y1={0}
        y2={GRAPH_HEIGHT}
      />
    );
  });
};
