import { useSelector } from "react-redux";
import { FormErrorList } from "../../../../../../../ui";
import {
  getFormattedErrorLabelList,
  getInvalidFieldsAsArray,
} from "../../../../../../../utils/";

export const CalculatorFormErrorLabelList = ({ itemsToCheck }) => {
  const errors = getInvalidFieldsAsArray(
    useSelector((state) => state.formState),
    Object.keys(itemsToCheck).map((key) => itemsToCheck[key].name)
  );
  if (errors.length)
    return <FormErrorList message={getFormattedErrorLabelList(errors)} />;
  return null;
};
