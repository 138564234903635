import { HStack, Switch, Text } from "native-base";

export const CalculatorButtonBarToggleAutoUpdate = ({
  isLiveUpdating,
  handleIsLiveToggle,
}) => (
  <HStack alignItems="center" space={2}>
    <Text>Auto update</Text>
    <Switch
      size="md"
      offTrackColor="gray.200"
      onTrackColor="primary.base"
      onThumbColor="white"
      offThumbColor="white"
      _hover={{ offTrackColor: "gray.300", onTrackColor: "primary.1400" }}
      isChecked={isLiveUpdating}
      onToggle={handleIsLiveToggle}
    />
  </HStack>
);
