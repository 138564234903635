import { useSelector } from "react-redux";
import { AlertDanger } from "../../../../ui";
import { rangeIsGoodForResult } from "../../../../utils";

export const CalculatorRangeWarning = () => {
  const { result } = useSelector((state) => state?.ipssm);
  const ipssmRangeIsGood = rangeIsGoodForResult(result);

  return (
    <AlertDanger isOpen={result && !ipssmRangeIsGood}>
      IPSS-M results less confident due to missing mutation data
    </AlertDanger>
  );
};
