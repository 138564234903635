export const validateField = ({ values, name, label, validation }) => {
  let error;

  if (validation?.length) {
    for (let i = 0; i < validation.length; i++) {
      const validationError = validation[i](values);
      if (validationError) {
        error = { ...validationError, name, label };
        break;
      }
    }
  }

  return {
    error,
    isInvalid: error ? true : false,
    isValid: !error ? true : false,
  };
};

export const updateFormValidation = (formState, formSchema, updateItem) => {
  let formIsValid = true;

  Object.keys(formState).forEach((key) => {
    const formItem = formState[key];
    const formSchemaItem = formSchema[key];

    if (formItem.isInvalid) {
      formIsValid = false;
    }

    if (
      (formSchemaItem.validation &&
        formItem.isValid === undefined &&
        formItem.isInvalid === undefined) ||
      formSchemaItem.dependant
    ) {
      const validationProps = validateField({
        ...formSchemaItem,
        ...formItem,
        values: { value: formItem.value, ...formState },
      });

      if (validationProps.isInvalid) {
        formIsValid = false;
      }
      updateItem({
        name: key,
        props: {
          ...formItem,
          ...validationProps,
          submitted: true,
        },
      });
    }
  });

  return formIsValid;
};

export const getInvalidFieldsAsArray = (formState, itemsToCheck) => {
  return Object.keys(formState)
    .filter((key) => formState[key].error)
    .filter((key) => itemsToCheck.includes(key))
    .map((key) => formState[key].error);
};

export const getFormattedErrorLabelList = (errors) => {
  if (!errors.length) return undefined;
  let message = `Error${errors.length !== 1 ? "s" : ""} in `;
  for (let i = 0; i < errors.length; i++) {
    message += errors[i].label;
    if (i == errors.length - 2) message += " and ";
    if (i < errors.length - 2) message += ", ";
  }
  message += ".";
  return message;
};

export function convertCommaSeparatedNumberToDecimals(string) {
  if (!string || string === 0) return string;
  return string.replace(',', '.');
}
