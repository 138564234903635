export const genesMain = [
    "ASXL1",
    "CBL",
    "DNMT3A",
    "ETV6",
    "EZH2",
    "IDH2",
    "KRAS",
    "NPM1",
    "NRAS",
    "RUNX1",
    "SF3B1",
    "SRSF2",
    "U2AF1",
  ];
  