import PropTypes from "prop-types";
import { Text } from "native-base";

export const FormSectionHeading = ({ title, ...props }) => (
  <Text
    fontSize="15px"
    fontFamily="heading"
    style={{ fontFamily: "Barlow_600SemiBold" }}
    {...props}
  >
    {title}
  </Text>
);

FormSectionHeading.propTypes = {
  title: PropTypes.string.isRequired,
};
