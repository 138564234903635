import { max as d3Max, min as d3Min, quantile, scaleLinear } from "d3";
import { Box } from "native-base";
import { useMemo, useState } from "react";
import Svg, { G } from "react-native-svg";
import { generateDensity } from "../../../../utils";
import { GraphLeftYAxis } from "../../../2_molecules";
import {
  ViolinDensity,
  ViolinQuantiles,
  ViolinScore,
  ViolinTitle,
} from "./components";

const MARGIN_TOP = 50;
const MARGIN_BOTTOM = 20;
const MARGIN_LEFT = 35;
const MARGIN_RIGHT = 20;

export const GraphViolin = ({ values, yConfig, patientValue, title }) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const GRAPH_WIDTH = d3Min([containerWidth - MARGIN_LEFT - MARGIN_RIGHT, 100]);
  const GRAPH_HEIGHT = GRAPH_WIDTH / 0.8;

  const maxValueFromValues = d3Max(values);

  const q1 = quantile(values, 0.25);
  const median = quantile(values, 0.5);
  const q3 = quantile(values, 0.75);
  const iq = q3 - q1;
  const min = q1 - 1.5 * iq > 0 ? q1 - 1.5 * iq : 0;
  const max =
    q3 + 1.5 * iq < maxValueFromValues ? q3 + 1.5 * iq : maxValueFromValues;

  const yAxis = useMemo(
    () => scaleLinear().domain(yConfig?.limit).range([GRAPH_HEIGHT, 0]),
    [GRAPH_HEIGHT]
  );

  const density = useMemo(
    () => generateDensity(values, min, max, yAxis, 40),
    [yAxis]
  );

  const maxNum = d3Max(density.map((a) => a[1]));

  const xAxis = useMemo(
    () => scaleLinear().domain([-maxNum, maxNum]).range([0, GRAPH_WIDTH]),
    [GRAPH_WIDTH]
  );

  return (
    <Box onLayout={(e) => setContainerWidth(e.nativeEvent.layout?.width)}>
      {GRAPH_HEIGHT && GRAPH_WIDTH && (
        <Svg
          height={GRAPH_HEIGHT + MARGIN_TOP + MARGIN_BOTTOM}
          width={GRAPH_WIDTH + MARGIN_LEFT + MARGIN_RIGHT}
        >
          <G y={MARGIN_TOP} x={MARGIN_LEFT}>
            <ViolinTitle {...{ title, GRAPH_WIDTH }} />
            <G x="-5">
              <GraphLeftYAxis axis={yAxis} height={GRAPH_HEIGHT} {...yConfig} />
            </G>
            <ViolinDensity {...{ density, yAxis, xAxis }} />
            <ViolinQuantiles {...{ q1, median, q3, xAxis, yAxis, density }} />
            <ViolinScore
              {...{ patientValue, GRAPH_WIDTH, yAxis }}
              {...yConfig}
            />
          </G>
        </Svg>
      )}
    </Box>
  );
};
