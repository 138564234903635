import { useState } from "react";
import { Image } from "native-base";

export const ImageWithRatio = ({ source, ratio = 1, alt }) => {
  const [width, setWidth] = useState(0);
  return (
    <Image
      onLayout={({ nativeEvent }) => setWidth(nativeEvent?.layout?.width)}
      w="full"
      h={width / ratio}
      source={source}
      alt={alt}
    />
  );
};
