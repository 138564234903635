import { useSelector } from "react-redux";
import { GraphLine } from "../../../../../../../../../../ui";
import { getAcronym } from "../../../../../../../../../../utils/getFunctions/getFormattedLabels";

export const GraphIpssm = (props) => {
  const { result } = useSelector((state) => state.ipssm);

  return (
    <GraphLine
      patientCategory={getAcronym(result?.means?.riskCat)}
      {...props}
    />
  );
};
