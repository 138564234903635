import { isMax, isMin, isRequired } from "../../utils";
import { FORM_TYPES } from "./formTypes";

export const formSchemaStackOne = {
  BM_BLAST: {
    // value: "10",
    name: "BM_BLAST",
    label: "Bone Marrow Blasts",
    type: FORM_TYPES.inputNumber,
    unitLabel: "%",
    labelLeft: "Percentage",
    labelRight: "[0-30%]",
    validation: [
      isRequired(`Bone Marrow Blasts is required`),
      isMin(0, `Bone Marrow Blasts can't be under 0`),
      isMax(30, `Bone Marrow Blasts can't be over 30`),
    ],
  },
  HB: {
    // value: "10",
    name: "HB",
    label: "Hemoglobin",
    type: FORM_TYPES.hemoglobinInput,
    validation: [
      isRequired("Hemoglobin is required"),
      (props) => {
       for (let i = 0; i < props.validation.length; i++) {
          const errorMessage = props.validation[i](props);
          if (errorMessage) {
            return errorMessage;
          }
       }
      },
    ],
    unit: "gdl",
    unitOptions: {
      gdl: {
        labelLeft: "g/dL",
        labelRight: "[4-20 g/dL]",
        validation: [
          isMin(4, `Hemoglobin must be between 4 and 20`),
          isMax(20, `Hemoglobin must be between 4 and 20`),
        ],
      },
      mmol: {
        labelLeft: "mmol/L",
        labelRight: "[2.4-12.4 mmol/L]",
        validation: [
          isMin(2.4, `Hemoglobin must be between 2.4 and 12.4`),
          isMax(12.4, `Hemoglobin must be between 2.4 and 12.4`),
        ],
      },
    },
  },
  PLT: {
    // value: "10",
    name: "PLT",
    label: "Platelet Count",
    type: FORM_TYPES.inputNumber,
    labelLeft: "1e9/L",
    labelRight: "[0-2000 1e9/L]",
    validation: [
      isRequired("Platelet Count is required"),
      isMin(0, `Platelet Count must be between 0 and 2000`),
      isMax(2000, `Platelet Count must be between 0 and 2000`),
    ],
  },
  ANC: {
    // value: "10",
    name: "ANC",
    label: "Absolute Neutrophil Count",
    labelSummary: "Neutrophil Count",
    type: FORM_TYPES.inputNumber,
    labelLeft: "1e9/L",
    labelRight: "[0-15 1e9/L]",
    validation: [
      isMin(0, `Absolute Neutrophil Count must be between 0 and 15`),
      isMax(15, `Absolute Neutrophil Count must be between 0 and 15`),
    ],
  },
  AGE: {
    // value: "20",
    name: "AGE",
    label: "Age",
    type: FORM_TYPES.inputNumber,
    labelLeft: "Years",
    unitLabel: "years",
    labelRight: "[18-120 years]",
    validation: [
      isMin(18, `Age must be between 18 and 120`),
      isMax(120, `Age must be between 18 and 120`),
    ],
  },
};
