import { createHtmlEndpoints } from "./createHtmlEndpoints";
import { createHtmlRangeWarning } from "./createHtmlRangeWarning";
import { createPatientSummary } from "./createPatientSummary";
import { createResults } from "./createResults";

const currentDate = new Date();
const timestamp = currentDate.toLocaleString("en-US", {
  weekday: "long",
  month: "short",
  day: "numeric",
  year: "numeric",
  hour: "numeric",
  minute: "numeric",
  hour12: true,
});

export const createPrintReport = (patientSummary, result, colors) => {
  const htmlIntro = `<div style="font-family:system-ui, sans-serif;">`;
  const title = `<h1 style="font-size:18px; margin:0;">IPSS-M Risk Calculator Report</h1>`;
  const date = `<h2 style="font-size:14px; margin:0; margin-bottom:12px;">Generated on ${timestamp}</h2>`;
  const stackIntro = `<div style="display:flex; flex-direction:column; gap:2rem;">`;
  const stackOutro = `</div>`;
  const htmlOutro = `</div>`;

  const html = [
    htmlIntro,
    title,
    date,
    stackIntro,
    createHtmlRangeWarning(result),
    createPatientSummary(patientSummary),
    createResults(result, colors),
    createHtmlEndpoints(result),
    stackOutro,
    htmlOutro,
  ];

  return html.join("");
};
