import { Line } from "react-native-svg";
import { YGRIDSTEP, Y_LIMIT } from "../../constants";

export const YGridLines = ({ yAxis, graphWidth }) => {
  return range(Y_LIMIT[0], Y_LIMIT[1], YGRIDSTEP).map((item) => {
    return (
      <Line
        key={item}
        x1={0}
        x2={graphWidth}
        y1={yAxis(item)}
        y2={yAxis(item)}
        stroke="#ecf0f2"
        strokeWidth={1}
      />
    );
  });
};

const range = (start, end, step = 1) => {
  let output = [];
  if (typeof end === "undefined") {
    end = start;
    start = 0;
  }
  for (let i = start; i < end; i += step) {
    output.push(i);
  }
  return output;
};
