import { Box, HStack, Stack, Text } from "native-base";
import { IconChevronDown, IconChevronUp } from "../../../1_atoms";

export const AccordionHeader = ({
  title,
  titleChildren,
  isExpanded,
  children,
}) => {
  const Chevron = isExpanded ? IconChevronUp : IconChevronDown;
  return (
    <Box
      bg="white"
      py={3}
      px={3}
    >
      <HStack alignItems="center">
        <Chevron size={8} />
        <Stack flexShrink={1} justifyContent="center">
          <Text style={{ fontFamily: "Barlow_600SemiBold" }} textTransform="uppercase" fontSize="lg">
            {title}
          </Text>
          {titleChildren}
        </Stack>
      </HStack>
      <Box>{children}</Box>
    </Box>
  );
};
