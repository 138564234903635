import { ScrollView, View } from "native-base";

export const LayoutPageMain = ({ children }) => (
  <ScrollView bg="graysecondary.200">
    <View bg="graysecondary.200" flex={1} p={6}>
      <View flex={1} maxW={800} w="full" mx="auto">
        {children}
      </View>
    </View>
  </ScrollView>
);
