import { Accordion, Card } from "../../../../ui";
import {
  CalculatorGraphViolinStack,
  PatientSummaryOverview,
} from "./components";

export const CalculatorPatientSummary = () => {
  return (
    <Card>
      <Accordion title="Patient Summary" defaultIsExpanded={false}>
        <PatientSummaryOverview />
        <CalculatorGraphViolinStack />
      </Accordion>
    </Card>
  );
};
