import { Stack } from "native-base";
import { useEffect, useMemo } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { formSchemaStackTwo, langConfig } from "../../../../../data";
import { useCalculatorFormItem } from "../../../../../hooks";
import { selectMultipleItemsByCategory } from "../../../../../redux";
import { FormItemSelector, FormSectionHeading } from "../../../../../ui";
import { CalculatorFormStackWrapper } from "../ui/CalculatorFormStackWrapper";
import { CalculatorFormItem } from "../components/CalculatorFormItem/CalculatorFormItem";

export const CalculatorFormStackTwo = () => {
  const first = [
    formSchemaStackTwo.del5q,
    formSchemaStackTwo.del7_7q,
    formSchemaStackTwo.del17_17p,
    formSchemaStackTwo.complex,
  ];

  return (
    <CalculatorFormStackWrapper>
      <Stack space={8}>
        <Stack space={2}>
          <FormSectionHeading title="*Presence of" />
          {first.map((item) => (
            <Stack space={2} key={item.name}>
              <CalculatorFormItem labelWidth={90} {...item} />
            </Stack>
          ))}
        </Stack>
        <CytogeneticsCategory />
      </Stack>
    </CalculatorFormStackWrapper>
  );
};

const CytogeneticsCategory = () => {
  const dependencies = useSelector(
    (state) =>
      selectMultipleItemsByCategory(state, [
        formSchemaStackTwo.del5q.name,
        formSchemaStackTwo.del7_7q.name,
        formSchemaStackTwo.del17_17p.name,
        formSchemaStackTwo.complex.name,
      ]),
    shallowEqual
  );

  const form = useCalculatorFormItem(
    formSchemaStackTwo.CYTO_IPSSR.name,
    formSchemaStackTwo.CYTO_IPSSR.validation
  );

  const options = useMemo(
    () =>
      formSchemaStackTwo.CYTO_IPSSR.options?.map((item) => {
        return {
          ...item,
          disabled: item.isDisabled(dependencies),
        };
      }),
    [dependencies]
  );

  useEffect(() => {
    if (form.formItem.value) {
      if (
        options.filter((item) => item.value === form.formItem.value)[0].disabled
      ) {
        form.onChange({ value: "" });
      }
    }
  }, [dependencies]);

  return (
    <Stack space={2}>
      <FormSectionHeading
        title={`*${
          langConfig.en[formSchemaStackTwo.CYTO_IPSSR.i18nKey]?.message ||
          formSchemaStackTwo.CYTO_IPSSR.label
        }`}
      />
      <FormItemSelector
        {...formSchemaStackTwo.CYTO_IPSSR}
        {...form.formItem}
        onChange={(v) => form.onChange({ value: v })}
        options={options.map((item) => ({
          ...item,
          label: langConfig.en[item.i18nKey]?.message || item.label,
        }))}
      />
    </Stack>
  );
};
