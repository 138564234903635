import PropTypes from "prop-types";
import { Badge, Text } from "native-base";
import { Platform } from "react-native";

export const BadgePrimary = ({
  label,
  colorScheme = "primary",
  size = "md",
}) => {
  const textSizeVariants = {
    sm: { fontSize: 14 },
    md: { fontSize: 16 },
    lg: { fontSize: 22 },
  };

  const badgeColorVariants = {
    primary: { bgColor: "primary.base" },
    secondary: { bgColor: "secondary.base" },
    tertiary: { bgColor: "tertiary.base" },
    vl: { bgColor: "vl.base" },
    l: { bgColor: "l.base" },
    ml: { bgColor: "ml.base" },
    mh: { bgColor: "mh.base" },
    h: { bgColor: "h.base" },
    vh: { bgColor: "vh.base" },
    i: { bgColor: "i.base" },
  };

  return (
    <Badge borderRadius="sm" {...badgeColorVariants[colorScheme]} py="0">
      <Text
        textTransform="uppercase"
        color="white"
        fontFamily='Barlow_700Bold'
        letterSpacing="sm"
        mb={Platform.OS !== "web" ? "0.5" : "0"}
        {...textSizeVariants[size]}
      >
        {label}
      </Text>
    </Badge>
  );
};

BadgePrimary.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["sm", "md", "lg"]),
  colorScheme: PropTypes.oneOf([
    "primary",
    "secondary",
    "tertiary",
    "vl",
    "l",
    "ml",
    "mh",
    "h",
    "vh",
    'i'
  ]),
};
