import { useNavigation } from "@react-navigation/native";
import { useSelector } from "react-redux";
import { formSchema } from "../../../../data";
import { ButtonOpenCalculator } from "../../../../ui";
import {
  getFormattedErrorLabelList,
  getInvalidFieldsAsArray,
} from "../../../../utils";

export const CalculatorLaunchButton = () => {
  const navigation = useNavigation();
  const formState = useSelector((state) => state.formState);
  const errors = getInvalidFieldsAsArray(formState, Object.keys(formSchema).map((key) => formSchema[key].name));

  return (
    <ButtonOpenCalculator
      message={getFormattedErrorLabelList(errors)}
      onPress={() => navigation.navigate("formModal")}
    />
  );
};
