import betas from "./betaScores";
import { cutBreak, round } from "../utils";

const defaultConfig = {
  values: {},
  options: { rounding: true, digits: 2 },
};

/**
 * Compute IPSS-M risk score and risk categories
 * @param {Array.<number>} patientValues - list of observed values for one individual
 *
 * @return {Object} dictionary of ipssm: score, category and contribution of each var.
 */
export const calculateIpssM = (
  config = {
    options: { rounding, digits },
    values: { ...patientValues },
  }
) => {
  const merged = {
    ...defaultConfig,
    options: { ...defaultConfig.options, ...config?.options },
    values: { ...defaultConfig.values, ...config?.values },
  };

  const { values, options } = merged;
  const { rounding, digits } = options;

  const { ...patientValues } = values;

  // relative risk contribution of each variable. log2 is just a scaling factor
  const scores = {};
  const scenarios = ["means", "worst", "best"];

  scenarios.forEach((scenario) => {
    const contributions = {};

    betas.forEach((beta) => {
      // Impute if missing variable
      let value = patientValues[beta.name];
      if (value === "NA" || value === null) {
        value = beta[scenario];
      }
      if (beta.name === "Nres2") {
        value = patientValues.Nres2[scenario];
      }

      // Contribution Normalization
      contributions[beta.name] =
        ((value - beta.means) * beta.coeff) / Math.log(2);
    });

    // risk score
    let riskScore = Object.values(contributions).reduce((sum, x) => sum + x, 0);
    if (rounding) {
      riskScore = round(riskScore, digits);
    }

    // risk categories
    const ipssmCat = [
      "Very Low",
      "Low",
      "Moderate Low",
      "Moderate High",
      "High",
      "Very High",
    ];
    const cutpoints = [-1.5, -0.5, 0, 0.5, 1.5];
    const riskCat = cutBreak(
      riskScore,
      [-Infinity, ...cutpoints, Infinity],
      ipssmCat
    );

    scores[scenario] = {
      riskScore: riskScore,
      riskCat: riskCat,
      contributions: contributions,
    };
  });
  return scores;
};
