import { Box, HStack, Text } from "native-base";

export const LegendLabel = ({ label, value }) => (
  <HStack alignItems="center" space={2}>
    {label && (
      <Text fontFamily="body" style={{ fontFamily: "Barlow_600SemiBold" }} fontSize="sm">
        {label}
      </Text>
    )}
    {label && value && <Box w="0.5" h={4} bg="gray.800" />}
    {value && (
      <Text fontFamily="body" style={{ fontFamily: "Barlow_600SemiBold" }} fontSize="sm">
        {value}
      </Text>
    )}
  </HStack>
);
