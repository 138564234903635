import { genesMain, genesRes } from "../../data/genes";
import { FORM_TYPES } from "./formTypes";

let ms = {};
[...genesMain, ...genesRes].forEach((mutation) => {
  ms[mutation] = {
    name: mutation,
    label: mutation,
    type: FORM_TYPES.radioTabs,
    options: [
      { label: "Non-mutated", value: "0" },
      { label: "Mutated", value: "1" },
      { label: "Not Assessed", value: "NA" },
    ],
    value: "0",
    // validation: [isRequired(`${mutation} is required`)],
  };
});

export const formSchemaMutations = ms;
