import { Text } from "native-base";

export const FormRadioTabsLabel = ({ labelWidth, label }) => (
  <Text
    fontSize={15}
    fontFamily="condensed"
    fontWeight={500}
    letterSpacing="sm"
    lineHeight="xs"
    width={labelWidth}
  >
    {label}
  </Text>
);
