import Collapsible from "react-native-collapsible";
import { KeyboardAwareSectionList } from "react-native-keyboard-aware-scroll-view";

export const CalculatorAccordion = ({
  items,
  accordionIndex,
  setCollapsible,
}) => {
  return (
    <KeyboardAwareSectionList
      keyExtractor={(item) => item.id}
      sections={items}
      renderSectionHeader={({ section: { id, Header } }) => {
        return (
          <Header
            setCollapsible={setCollapsible}
            isExpanded={accordionIndex === id}
          />
        );
      }}
      renderItem={({ item: { Component, id } }) => {
        return (
          <Collapsible
            renderChildrenCollapsed={true}
            collapsed={id !== accordionIndex}
          >
            <Component />
          </Collapsible>
        );
      }}
    />
  );
};
