import { ButtonBase } from "../ButtonBase/ButtonBase";

export const ButtonPrimary = ({ disabled, ...props }) => (
  <ButtonBase
    bg="primary.base"
    _pressed={{ bg: "primary.1300" }}
    _hover={{ bg: "primary.1500" }}
    borderWidth={1}
    borderColor="primary.base"
    _text={{ color: "white" }}
    opacity={disabled ? 0.2 : 1}
    {...props}
  />
);
