import { HStack, Stack } from "native-base";
import { Accordion, Card, CardValueBox, ValueDisplay } from "../../../../ui";
import { useSelector } from "react-redux";
import { isRange, getFormattedResult } from "../../../../utils";

export const CalculatorStratificationResults = () => {
  const { result } = useSelector((state) => state.ipssm);

  // Show IPPS-R and IPPS-R-age adjusted results
  const { IPSSR_SCORE, IPSSR, IPSSRA_SCORE, IPSSRA, means, best, worst } =
    result || {};

  // Format results to match UI requirements
  const formattedResults = {
    means: {
      ...getFormattedResult(means?.riskScore, means?.riskCat),
      label: "IPSS-M Score",
      size: "lg",
    },
    best: {
      ...getFormattedResult(best?.riskScore, best?.riskCat),
      label: "Best",
      size: "sm",
    },
    worst: {
      ...getFormattedResult(worst?.riskScore, worst?.riskCat),
      label: "Worst",
      size: "sm",
    },
    ipssr: {
      ...getFormattedResult(IPSSR_SCORE, IPSSR),
      label: "IPSS-R Score",
      size: "md",
    },
    ipssra: {
      ...getFormattedResult(IPSSRA_SCORE, IPSSRA),
      label: "IPSS-R Score (Age-adjusted)",
      size: "md",
    },
  };

  return (
    <Card>
      <Accordion
        title="Stratification Results"
        defaultIsExpanded={isNaN(result?.means?.riskScore) ? false : true}
      >
        <Stack mt="-1px" ml="-1px" flexWrap="wrap" flexDir="row">
          <CardValueBox flexBasis={{ base: "100%", xl: "40%" }}>
            <Stack space={2}>
              <ValueDisplay {...formattedResults.means} />
              {isRange(result) && result && (
                <HStack space={4}>
                  <ValueDisplay {...formattedResults.best} />
                  <ValueDisplay {...formattedResults.worst} />
                </HStack>
              )}
            </Stack>
          </CardValueBox>
          <CardValueBox flexBasis={{ base: "100%", md: "30%" }}>
            <ValueDisplay {...formattedResults.ipssr} />
          </CardValueBox>
          <CardValueBox flexBasis={{ base: "100%", md: "30%" }}>
            <ValueDisplay {...formattedResults.ipssra} />
          </CardValueBox>
        </Stack>
      </Accordion>
    </Card>
  );
};
