import { getAcronym, getCategoryLabel } from "./getFormattedLabels";

export const getFormattedResult = (score, category) => {
  return {
    error: isNaN(score) && "Not calculated",
    value: score?.toFixed(2),
    badgeLabel: getCategoryLabel(category),
    badgeColorScheme: getAcronym(category),
  };
};
