import { formSchema, genesMain, genesRes } from "../../data";

export const getFormattedPatientSummaryItem = ({ formState, units, key }) => {
  const formSchemaItem = formSchema[key];
  const formStateItem = formState[key];
  return {
    value: formStateItem?.value,
    error: !formStateItem?.value ? "Missing value" : null,
    valueSuffix:
      units[formSchemaItem?.unit]?.suffix ||
      formSchemaItem?.unitLabel ||
      formSchemaItem?.labelLeft,
  };
};

export const getFormattedRadioPatientSummaryItem = ({ formState, key }) => {
  const formSchemaItem = formSchema[key];
  const formStateItem = formState[key];
  return {
    value: formSchemaItem.options?.filter(
      (option) => option.value === formStateItem.value
    )[0]?.label,
    error: formStateItem.value ? null : "Missing value",
  };
};

export const getFormattedTp53lohPatientSummaryItem = ({ formState }) => {
  const del = formState.del17_17p.value;
  const vaf = formState.TP53maxvaf.value;
  const TP53loh = formSchema.TP53loh;
  const TP53lohSubmitted = formState.TP53loh.value;

  const getInferredValue = () => {
    if (del === 1 || del === "1") return "Yes";
    if (vaf > 55) return "Yes";
    return TP53loh.options?.filter(
      (option) => option.value === TP53lohSubmitted
    )[0]?.label;
  };

  const getInferredSuffix = () => {
    if (del === 1 || (del === "1" && vaf > 55))
      return "(Inferred from del 17/17p and TP53 VAF)";
    if (del === 1 || del === "1") return "(Inferred from del 17/17p)";
    if (vaf > 55) return "(Inferred from TP53 VAF)";
  };

  return {
    value: getInferredValue(),
    error: formState.TP53loh.value ? null : "Missing value",
    valueSuffix: getInferredSuffix(),
  };
};

export const getFormattedMaxVafPatientSummaryItem = ({ formState }) => {
  const maxVafRequired =
    formState.TP53mut.value === "1" || formState.TP53mut.value === "2 or more";
  return {
    value: maxVafRequired ? formState.TP53maxvaf.value : "N/A",
    error:
      maxVafRequired && !formState.TP53maxvaf.value ? "Missing value" : null,
    valueSuffix: maxVafRequired ? "%" : null,
  };
};

export const getFormattedMissingGenesPatientSummaryItem = ({ formState }) => {
  const missingGenesList = [
    formSchema.MLL_PTD.name,
    formSchema.FLT3all.name,
    ...genesMain,
    ...genesRes,
  ]
    .filter((item) => formState[item].value === "NA")
    .map((item) => {
      return formSchema[item]?.altLabel || formSchema[item]?.label;
    })
    ?.join(", ");

  return {
    value: missingGenesList || 0,
    error: !formState?.HB?.value ? "Missing value" : null,
    size: missingGenesList ? "sm" : "md",
  };
};

export const getFormattedMutatedGenesPatientSummaryItem = ({ formState }) => {
  const mutatedGenesList = [
    formSchema.TP53mut.name,
    formSchema.MLL_PTD.name,
    formSchema.FLT3all.name,
    ...genesMain,
    ...genesRes,
  ]
    .filter(
      (item) =>
        formState[item].value === "1" || formState[item].value === "2 or more"
    )
    .map((item) => {
      return formSchema[item]?.altLabel || formSchema[item]?.label;
    })
    ?.join(", ");

  return {
    value: mutatedGenesList || 0,
    error: !formState?.HB?.value ? "Missing value" : null,
    size: mutatedGenesList ? "sm" : "md",
  };
};
