import { ButtonBase } from "../ButtonBase/ButtonBase";

export const ButtonDefault = (props) => (
  <ButtonBase
    bg="white"
    _pressed={{ bg: "gray.300" }}
    _hover={{ bg: "gray.200" }}
    borderWidth={1}
    borderColor="gray.600"
    _text={{ color: "gray.1800" }}
    {...props}
  />
);
