import { Button } from "native-base";

export const ButtonBase = ({ children, size = "md", _text, ...props }) => {
  const sizeVariants = {
    sm: { h: "34px" },
    md: { h: "40px" },
    lg: { h: "42px" },
  };
  const sizeTextVariants = {
    sm: { fontSize: 13, lineHeight: 13 },
    md: { fontSize: 15, lineHeight: 15 },
    lg: { fontSize: 16, lineHeight: 16 },
  };
  return (
    <Button
      _text={{
        display: "flex",
        alignItems: "center",
        fontFamily: "Barlow_600SemiBold",
        ..._text,
        ...sizeTextVariants[size],
      }}
      {...sizeVariants[size]}
      {...props}
    >
      {children}
    </Button>
  );
};
