import Svg, { G } from "react-native-svg";
import { useState, useMemo } from "react";
import { Box } from "native-base";
import {
  CategoryDensityPlots,
  CategoryGridLines,
  CategoryLabels,
  LinearXAxis,
  LinearYAxis,
  PatientPlot,
  ScoreRange,
  XGridLines,
  YGridLines,
} from "./components";
import {
  MARGIN_BOTTOM,
  MARGIN_LEFT,
  MARGIN_RIGHT,
  MARGIN_TOP,
  X_LIMIT,
  Y_LIMIT,
} from "./constants";
import { scaleLinear } from "d3";
import { generateDensity } from "../../../../utils";

export const GraphRiskStratification = ({
  data,
  legend,
  patientScore,
  patientCategory,
  patientScoreRange,
}) => {
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  const graphWidth = containerWidth - MARGIN_LEFT - MARGIN_RIGHT;
  const GRAPH_HEIGHT = containerHeight - MARGIN_TOP - MARGIN_BOTTOM;

  const max = Math.max(...data.map((point) => point));
  const min = Math.min(...data.map((point) => point));

  const xAxis = useMemo(
    () => scaleLinear().domain(X_LIMIT).range([0, graphWidth]),
    [graphWidth, X_LIMIT]
  );

  const yAxis = useMemo(
    () => scaleLinear().domain(Y_LIMIT).range([GRAPH_HEIGHT, 0]),
    [Y_LIMIT, GRAPH_HEIGHT]
  );

  const density = useMemo(
    () => generateDensity(data, min, max, xAxis),
    [xAxis]
  );

  return (
    <Box
      h="100%"
      onLayout={(e) => {
        setContainerHeight(e.nativeEvent.layout?.height);
        setContainerWidth(e.nativeEvent.layout?.width);
      }}
    >
      {GRAPH_HEIGHT && graphWidth && (
        <Svg height={containerHeight} width={containerWidth}>
          <G x={MARGIN_LEFT} y={MARGIN_TOP}>
            <XGridLines {...{ xAxis, GRAPH_HEIGHT }} />
            <YGridLines {...{ yAxis, graphWidth }} />
            <LinearXAxis {...{ xAxis, graphWidth, GRAPH_HEIGHT }} />
            <LinearYAxis {...{ yAxis, graphWidth, GRAPH_HEIGHT }} />
            <CategoryDensityPlots {...{ density, yAxis, xAxis, legend }} />
            <CategoryGridLines {...{ xAxis, GRAPH_HEIGHT }} />
            <ScoreRange {...{ patientScoreRange, xAxis, GRAPH_HEIGHT }} />
            <PatientPlot
              {...{
                patientScore,
                patientCategory,
                density,
                xAxis,
                yAxis,
                GRAPH_HEIGHT,
              }}
            />
            {containerWidth >= 540 && (
              <CategoryLabels {...{ xAxis, yAxis, legend }} />
            )}
          </G>
        </Svg>
      )}
    </Box>
  );
};
