// IPSS-M Group Settings
const mGroupCategories = [
    {
      group: 'VL',
      label: 'Very Low',
      patients: 381,
      os: {
        median: 10.6,
        iqRange: [5.1, 17.4],
      },
      lfs: {
        median: 9.7,
        iqRange: [5.0, 17.4],
      },
      amlT: {
        by1Year: 0.0,
        by2Years: 1.2,
        by4Years: 2.8,
      },
      deathWoAML: {
        by1Year: 2.2,
        by2Years: 7.0,
        by4Years: 15.9,
      },
      xTextPos: -2,
      cutoff: [-Infinity, -1.5],
      order: 0,
    },
    {
      group: 'L',
      label: 'Low',
      patients: 889,
      os: {
        median: 6.0,
        iqRange: [3.0, 12.8],
      },
      lfs: {
        median: 5.9,
        iqRange: [2.6, 12.0],
      },
      amlT: {
        by1Year: 1.7,
        by2Years: 3.4,
        by4Years: 5.1,
      },
      deathWoAML: {
        by1Year: 8.5,
        by2Years: 16.2,
        by4Years: 29.5,
      },
      xTextPos: -1,
      cutoff: [-1.5, -0.5],
      order: 1,
    },
    {
      group: 'ML',
      label: 'Moderate Low',
      patients: 302,
      os: {
        median: 4.6,
        iqRange: [2.0, 7.4],
      },
      lfs: {
        median: 4.5,
        iqRange: [1.6, 6.9],
      },
      amlT: {
        by1Year: 4.9,
        by2Years: 8.8,
        by4Years: 11.4,
      },
      xTextPos: -0.25,
      cutoff: [-0.5, 0],
      order: 2,
    },
    {
      group: 'MH',
      label: 'Moderate High',
      patients: 291,
      os: {
        median: 2.8,
        iqRange: [1.2, 5.5],
      },
      lfs: {
        median: 2.3,
        iqRange: [0.91, 4.7],
      },
      amlT: {
        by1Year: 9.5,
        by2Years: 14.0,
        by4Years: 18.9,
      },
      deathWoAML: {
        by1Year: 18.0,
        by2Years: 31.1,
        by4Years: 51.1,
      },
      xTextPos: 0.25,
      cutoff: [0, 0.5],
      order: 3,
    },
    {
      group: 'H',
      label: 'High',
      patients: 379,
      os: {
        median: 1.7,
        iqRange: [1.0, 3.4],
      },
      lfs: {
        median: 1.5,
        iqRange: [0.8, 2.8],
      },
      amlT: {
        by1Year: 14.3,
        by2Years: 21.2,
        by4Years: 29.2,
      },
      deathWoAML: {
        by1Year: 19.3,
        by2Years: 39.8,
        by4Years: 54.2,
      },
      xTextPos: 1,
      cutoff: [0.5, 1.5],
      order: 4,
    },
    {
      group: 'VH',
      label: 'Very High',
      patients: 469,
      os: {
        median: 1.0,
        iqRange: [0.5, 1.8],
      },
      lfs: {
        median: 0.76,
        iqRange: [0.33, 1.5],
      },
      amlT: {
        by1Year: 28.2,
        by2Years: 38.6,
        by4Years: 42.8,
      },
      deathWoAML: {
        by1Year: 30.6,
        by2Years: 45.6,
        by4Years: 51.3,
      },
      xTextPos: 2,
      cutoff: [1.5, Infinity],
      order: 5,
    },
  ]
  
  
  export const mGroupSettings = Object.fromEntries(
    mGroupCategories.map((i) => [i.label, i])
  )
  
  // IPSS-R Group Settings
  const rGroupCategories = [
    {
      group: 'VERY-LOW',
      label: 'Very Low',
      order: 0,
    },
    {
      group: 'LOW',
      label: 'Low',
      order: 1,
    },
    {
      group: 'INT',
      label: 'Intermediate',
      order: 2,
    },
    {
      group: 'HIGH',
      label: 'High',
      order: 3,
    },
    {
      group: 'VERY-HIGH',
      label: 'Very High',
      order: 4,
    },
  ]
  
  export const rGroupSettings = Object.fromEntries(
    rGroupCategories.map((i) => [i.label, i])
  )
  
  // Export default Categories for IPPS-M and IPSS-R
  export { mGroupCategories, rGroupCategories }
  