import { Box, HStack, Pressable, Text } from "native-base";
import { useState } from "react";
import { useWindowDimensions } from "react-native";
import { TabView, SceneMap } from "react-native-tab-view";

export const TabsSecondary = ({ routes }) => {
  const layout = useWindowDimensions();

  const [index, setIndex] = useState(0);

  const scene = routes?.reduce(function (acc, cur, i) {
    acc[cur.key] = cur.component;
    return acc;
  }, {});

  const renderTabBar = (props) => {
    return (
      <HStack justifyContent="center" space={3}>
        {props.navigationState.routes.map((route, i) => {
          return (
            <Pressable
              onPress={() => {
                setIndex(i);
              }}
              key={i}
            >
              <Box
                rounded="sm"
                alignItems="center"
                px="4"
                py="1"
                cursor="pointer"
                bg={index === i ? "primary.base" : "white"}
              >
                <Text
                  style={{ fontFamily: "Barlow_600SemiBold" }}
                  fontSize="md"
                  color={index !== i ? "primary.base" : "white"}
                >
                  {route.title}
                </Text>
              </Box>
            </Pressable>
          );
        })}
      </HStack>
    );
  };

  return (
    <TabView
      swipeEnabled={false}
      navigationState={{ index, routes }}
      renderScene={SceneMap(scene)}
      renderTabBar={renderTabBar}
      onIndexChange={setIndex}
      initialLayout={{ width: layout.width }}
    />
  );
};
