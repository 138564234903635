import { Box } from "native-base";

export const CardValueBox = ({ children, ...props }) => (
  <Box
    flex={1}
    justifyContent="center"
    px={6}
    marginRight="-1px"
    marginBottom="-1px"
    borderWidth={1}
    borderColor="gray.400"
    py={{ base: 4, md: 5 }}
    {...props}
  >
    {children}
  </Box>
);
